import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportMenu from '../../../constants/menu/report'
import { authService, settingRecipientService } from '../../../services'
import { auth, formatter, log, validator } from '../../../util'

// UI
import { Button, List, Panel, Page, Pager } from '../../../components'
import { Permissions } from '../../../constants'
import { apiHostname } from '../../../config'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'
import Upload from 'antd/lib/upload'

import './styles.css'

const { Item: FormItem } = Form

const longFormItemLayout = {
  labelCol: { sm: 7, md: 7, lg: 7 },
  wrapperCol: { sm: 15, md: 15, lg: 15 }
}

class ReportRecipientSetting extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      loadingForm: false,
      recipients: {}
    }
  }

  componentDidMount () {
    this.fetchRecipientSetting()
  }

  render () {
    const { form } = this.props
    const { recipients, loading, loadingForm } = this.state
    const { getFieldDecorator } = form

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Spin spinning={loadingForm} blur>
            <Page.Header title={`Report Recipient Setting`}>
              { this.hasAccess(Permissions.REPORT.RECIPIENT_SETTING.UPDATE)
                ? <div className='btn' onClick={() => this.handleSave()}>
                  Save
                </div>
                : null }
            </Page.Header>

            <div className='setting-list'>
              <Skeleton loading={loading} active>
                <Form>
                  <Panel title='Email Report Recipients'>
                    <FormItem label='Participant Service Booking Report' {...longFormItemLayout}>
                      {getFieldDecorator('sb_report', {
                        initialValue: recipients.sb_report,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label='Participant To Receive Invoices Report' {...longFormItemLayout}>
                      {getFieldDecorator('to_receive_report', {
                        initialValue: recipients.to_receive_report,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Panel>
                  <Panel title='Report Request Recipients'>
                    <FormItem label='Service Booking Validation' {...longFormItemLayout}>
                      {getFieldDecorator('req_sb_compare', {
                        initialValue: recipients.req_sb_compare,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label='Invoice Rcv Amt Import' {...longFormItemLayout}>
                      {getFieldDecorator('req_inv_rct_amt', {
                        initialValue: recipients.req_inv_rct_amt,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Panel>
                  <Panel title='Profile Export Recipients'>
                    <FormItem label='General Contacts List Weekly Update' {...longFormItemLayout}>
                      {getFieldDecorator('setting_general_contact_export', {
                        initialValue: recipients.setting_general_contact_export,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Panel>
                  <Panel title='Email Alert Recipients'>
                    <FormItem label='Participant Invoice Approve Alert' {...longFormItemLayout}>
                      {getFieldDecorator('invoice_approve_alert', {
                        initialValue: recipients.invoice_approve_alert,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label='Participant Invoice Reject Alert' {...longFormItemLayout}>
                      {getFieldDecorator('invoice_reject_alert', {
                        initialValue: recipients.invoice_reject_alert,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label='Participant Link Request Alert' {...longFormItemLayout}>
                      {getFieldDecorator('link_request_alert', {
                        initialValue: recipients.link_request_alert,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label='Participant Unlink Request Alert' {...longFormItemLayout}>
                      {getFieldDecorator('unlink_request_alert', {
                        initialValue: recipients.unlink_request_alert,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Panel>
                </Form>
              </Skeleton>
            </div>
          </Spin>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchRecipientSetting = async () => {
    this.setState({ loading: true })

    if (this.hasAccess(Permissions.REPORT.RECIPIENT_SETTING.LIST)) {
      const list = await settingRecipientService.getAllRecipients()

      if (list) {
        this.setState({ recipients: list, loading: false, loadingForm: false })
      } else {
        notify.error('Unable to get Recipient Setting', 'Please try again later.')
      }
    }
  }

  validateEmailMultiple = (rule, value, callback, isRequired = false) => {
    const MSG_VALID_EMAIL = 'Please provide a valid email'
    const MSG_ENTER_EMAIL = 'Please enter email'

    if (value === null || value === undefined || value === '' || value.trim() === '') {
      if (isRequired) {
        callback(new Error(MSG_ENTER_EMAIL))
      } else {
        callback()
      }
    } else if (value && value.length > 0 ) {
      if (!validator.isEmailMultiple(value)) {
        callback(new Error(MSG_VALID_EMAIL))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  handleSave = async () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { loading, loadingForm, recipients } = this.state

        if (loading || loadingForm) return

        this.setState({ loadingForm: true })

        try {
          const r = await settingRecipientService.updateAllRecipients(values)

          if (r && r.id) {
            notify.success('Update Recipient Setting successfully', 'Recipient Setting is updated.')

            log.updateReportRecipientSetting(r.id, recipients, values, undefined, [
              { key: 'sb_report', label: 'Email of Participant Service Booking Report' },
              { key: 'req_sb_compare', label: 'Email of Report Request - Service Booking Validation' },
              { key: 'req_inv_rct_amt', label: 'Email of Report Request - Invoice Rcv Amt Import' },
              { key: 'to_receive_report', label: 'Email of To Receive Invoices Report' }
            ])

            this.fetchRecipientSetting()
          } else {
            notify.error('Unable to update Recipient Setting', 'Please try again later.')
          }
        } catch (e) {
          notify.error('Unable to update Recipient Setting', 'Please try again later.')

        }

        this.setState({ loadingForm: false })
      }
    })
  }
}

export default Form.create()(ReportRecipientSetting)
