import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cloneDeep, debounce, isEqual } from 'lodash'
import moment from 'moment-timezone'

import {
  clientInvoiceService,
  clientService,
  commService,
  commonService,
  creditClientService,
  fileService,
  invoiceService,
  providerService,
  settingFileService,
  settingGSTRateService,
  settingGeneralService,
  settingReasonService
} from '../../../services'
import { CommType, ExportType, InvoiceAuthType, InvoiceSaveType, InvoiceType, Permissions } from '../../../constants'
import { auth, exportFile, formatter, log, validator } from '../../../util'
import { fetchingInvoices } from '../../../states/actions/invoice'

// UI
import { Button, CustomIdentifierList, Checkbox, DateTimePicker, List, Loading, Page, Panel, ControlLabel, SideModal } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'
import InvoiceActivity from '../ActivityLog'
import InvoiceFile from '../File'
import InvoiceComm from '../Comm'
import AddClientModal from '../AddClientModal'
import AddProviderModal from '../AddProviderModal'
import AddFileModal from '../AddFileModal'

import Alert from 'antd/lib/alert'
import Badge from 'antd/lib/badge'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Progress from 'antd/lib/progress'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

const { Item: FormItem } = Form
const { TextArea } = Input
const { info, confirm, warning, error } = Modal
const { Group: RadioGroup, Button: RadioButton } = Radio
const Option = Select.Option
const TabPane = Tabs.TabPane

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 5, path: '/comm' },
  { tabId: 4, path: '/custom-identifier' },
  { tabId: 3, path: '/files' },
  { tabId: 2, path: '/logs' }
]

const dateFormat = 'DD/MM/YYYY'
const dateFormat2 = 'YYYY-MM-DD'
const dateFormat3 = 'DD/MM/YYYY hh:mm:ss A'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'
const urlRedirect = '/invoices'

const formItemLayout = {
  labelCol: { sm: 9, md: 9, lg: 9 },
  wrapperCol: { sm: 10, md: 10, lg: 10 }
}
const shortFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}
const longFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}
const threeeFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 14 },
  wrapperCol: { sm: 14, md: 14, lg: 8 }
}

const DefaultInvoiceItem = {
  inv_date: undefined,
  inv_end_date: undefined,
  gst_code: undefined,
  cat_id: undefined,
  cat_item_id: undefined,
  cat_item_identifier: '',
  unit: undefined,
  inv_rate: undefined,
  max_rate: undefined,
  budget_id: '',
  cats: [],
  catItems: [],
  subtotal: 0,
  received_subtotal: 0,
  comment: undefined,
  is_amt_over_budget: undefined
}

const SelectClientMsg = 'Please select Participant.'
const SelectProviderMsg = 'Please select Provider.'
const SelectInvoiceMsg = 'Please add at least one invoice item.'
const ClientNoBankInfoMsg = 'Participant does not have any bank info.'
const ClientNoRmtEmailMsg = 'Participant\'s Remittance email is not configured.'
const MaxRateMismatchMsg = 'The invoiced rate is higher than maximum rate assigned.'
const OverBudgetMsg = 'The invoiced amount exceeds budget.'
const OverBudgetByTotalMsg = 'Total Inv Amt exceeds budget.'
const SBMismatchMsg = 'The service start date and end date do not fall within a same service booking.'
const InvDuplicatedMsg = 'Duplicate record found.'
const InvDuplicatedABNMissingMsg = 'Please key in ABN value before validate invoice number.'
const InvDuplicatedProviderMissingMsg = 'Please select provider before validate invoice number.'
const TotalAmountMismatchMsg = 'Total Invoiced Amount and Expected Invoiced Amount are mismatched.'
const TotalAmountMismatchMsg2 = 'different from Total Inv Amt.'
const RcvAmountEarlyCloseMsg = 'The received amount and amount to pay are not matched. Confirm with your received amount again.'
const RcvAmountOverPayMsg = 'Current Receive Amount exceeds To Receive Amount'
const ItemsSelectErrMsg = 'No item is selected.'
const SBOverDaysMsg = 'This Service Booking has ended.'
const SBOver0daysMsg = 'The Service Booking linked with this invoice item has ended.'
const SBOver0daysMultiMsg = 'One of the Service Booking linked with this invoice item has ended.'
const SBOver60daysMsg = 'The Service Booking linked with this invoice item has passed 60 days of its end date.'
const SBOver60daysMultiMsg = 'One of the Service Booking linked with this invoice item has passed 60 days of its end date.'
const SBOver90daysMsg = 'The Service Booking linked with this invoice item has passed 90 days of its end date. The claim may not be successful even it is submitted.'
const SBOver90daysMultiMsg = 'One of the Service Booking linked with this invoice item has passed 90 days of its end date. The claim may not be successful even it is submitted.'

// const AUTH_PAY_REMINDER_NOT_REQUIRED = 0
// const AUTH_PAY_REMINDER_REQUIRED = 1
// const AUTH_PAY_REMINDER_ALERT = 2
// const AUTH_PAY_NO_EMAIL_CONFIGURED = 3
// const AUTH_PAY_UPDATED_DETAILS = 4

const INV_AUTH_ACTION_SENDNOW = 'sendnow'
// const INV_AUTH_ACTION_LATER = 'later'
const INV_AUTH_ACTION_SKIP = 'skip'

export class InvoicePage extends Component {
  constructor(props) {
    super(props)
    const { match, location } = this.props
    const { key = undefined } = location
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      itemOri: { is_sdb_invoice: false, subtotal: 0, received_subtotal: 0 },
      item: { is_sdb_invoice: false, subtotal: 0, received_subtotal: 0 },
      invAuthModalInfo: {},
      invType: InvoiceType.INV_TYPE_PM,
      invoiceItems: [],
      invoiceItemsOri: [],
      invoiceHistory: [],
      invoiceReceivingHistory: [],
      invSubtotalMsg: '',
      isAllowABNExempted: false,
      isAllowSaveProcess: false,
      isCreditApplyAll: false,
      isEnableEmailSend: false,
      isInvItemsNotLoaded: false,
      isInvNoDuplicated: undefined,
      commCount: 0,
      commSentCount: 0,
      clientBudget: [],
      clientCurrentBudget: {},
      clientCurrentBudgetList: [],
      clientList: [],
      clientInfo: {},
      clientMsg: '',
      clientCredit: [],
      clientCurrentCredit: [],
      clientSelectedCredit: {},
      clientCreditMaxAmt: 0.0,
      clientCurrentCreditInfo: {},
      clientCurrentAppliedCredit: {},
      clientCreditEditMaxAmt: 0.0,
      categoriesList: [],
      subCategoriesList: [],
      fileList: [],
      fileInfo: {},
      invoiceMsg: '',
      invoiceReasonList: [],
      providerList: [],
      providerInfo: {},
      providerMsg: '',
      rcvModalInfo: {},
      rcvModalMsg: '',
      creditModalInfo: {},
      creditModalMsg: '',
      statusList: [],
      settingGstList: [],
      loading: false,
      loadingSave: false,
      loadingHidden: false, // a hidden loading flag used for invoice type radio button group. to prevent invoice type select being selected when flag is true
      loadingItems: false,
      loadingCheckInvoiceNo: false,
      loadingClient: false,
      loadingClientBudget: false,
      loadingCatItems: false,
      loadingRctDetail: false,
      loadingCreditDetail: false,
      loadingUpdateRct: false,
      loadingUpdateCredit: false,
      loadingUpdateComm: false,
      loadingSelectProda: false,
      loadingSelectAbaRmt: false,
      shouldActive: false,
      showSave: false,
      showEdit: true,
      showInvAuthModal: false,
      showInvDeleteModal: false,
      showPrivateAlert: false,
      showPrivateAlertProvider :false,
      showClientModal: false,
      showProviderModal: false,
      showAddFileModal: false,
      showRcvAmtModal: false,
      showCreditAmtModal: false,
      showCreditEditModal: false,
      showCreditAmtAddModal: false,
      showProdaModal: false,
      showAbaRmtModal: false,
      abaRmtModalType: '',
      abaRmtSelectList: [],
      abaRmtSelectAll: false,
      abaRmtSelectErrMsg: '',
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      pageKey: key,
    }

    this.debounceValidateInvoiceNumber = debounce(this.debounceValidateInvoiceNumber, 1000)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, location } = nextProps
    const { params = {} } = match

    // must check whether prev state key and locaction key are both undefined, and skip if it is
    if (prevState.pageKey === undefined && location.key === undefined) {

    } else if (prevState.pageKey !== location.key) {
      // not only check the page key but also need to check current path params. if within the same url path (navigate between tabs), do not reload the page.
      if (validator.isNumberText(params.id) && params.type !== undefined) {

      } else {
        if (window) window.location.reload()
      }
    }

    return { ...prevState, pageKey: location.key }
  }

  componentDidMount() {
    this.fetchInvGSTCodes()
    this.fetchInvStatusList()
    if (this.isEdit()) {
      this.fetchDataListEdit()
      this.fetchInvoice()
    } else {
      this.fetchDataList()
    }
  }

  render() {
    const { history } = this.props
    const {
      commCount,
      currentTab,
      clientInfo,
      clientList,
      providerInfo,
      providerList,
      isAllowSaveProcess,
      isInvItemsNotLoaded,
      item,
      invType,
      loading,
      loadingSave,
      loadingItems,
      showSave,
      showEdit,
      showClientModal,
      showProviderModal,
      statusList
    } = this.state
    const invoiceId = this.getId()
    const isInfoTab = currentTab === '1'
    const isItem = item && item.id

    const isExportAvailable = item && item.id && statusList.find(e => {
      if (e.reference === '008' || e.reference === '009') {
        return item.status === e.value
      }

      return false
    })

    const isRcvAvailable = statusList.find(e => {
      if (e.reference >= '006' && e.reference <= '009') {
        return item.status === e.value
      }

      return false
    })

    const isDrafted = item && item.id && statusList.find(e => {
      if (e.reference === '001') {
        return item.status === e.value
      }

      return false
    })

    const isRejected = item && item.id && statusList.find(e => {
      if (e.reference === '010') {
        return item.status === e.value
      }

      return false
    })

    const isProcessed = item && item.id && statusList.find(e => {
      if (e.reference === '002') {
        return item.status === e.value
      }

      return false
    })

    const isAllowReject = item && item.id && statusList.find(e => {
      if (e.reference >= '001' && e.reference < '009') {
        return item.status === e.value
      }

      return false
    })

    const isCancelled = item && item.id && statusList.find(e => {
      if (e.reference === '000') {
        return item.status === e.value
      }

      return false
    })

    const title = invType ? invType.name : 'Invoice'
    const isGoingToSaveAndProcess = (!this.isEdit() || (isDrafted || isRejected)) // this flag is true if it is about to save and process (either a new invoice or the inv is drafted or rejected and going to have next step)

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={
            this.isEdit()
              ? `${title}${item.invoice_number ? ` - ${item.invoice_number}` : ''}`
              : `New ${title}`
          }>
            {!loadingItems && this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.UPDATE) && item && item.id && item.is_enable_send_inv_auth_comm === true && !loading && !loadingSave && isInfoTab
              ? (
                <Button className='btn' key='comm' ghost type='primary' feedback={loading} onClick={() => this.showAuthorisedAlert(clientInfo, item.id)}> {'Send Inv Authorisation Email'}</Button>
              )
              : null}

            {!loadingItems && this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.DELETE) && showSave && item && item.id && item.is_allow_edit && invType !== InvoiceType.INV_TYPE_STD && !loading && !loadingSave && isInfoTab
              ? (
                <Button className='btn' key='delete' ghost type='primary' feedback={loading} onClick={() => this.handleDeleteModal(true)}> {'Delete'}</Button>
              )
              : null}

            {!loadingItems && this.isEdit() && this.hasAccess(Permissions.INVOICE.MGMT.LIST) && !loading && !loadingSave && isRcvAvailable && item.proda_export_id && isInfoTab
              ? (
                <Button className='btn' key='proda' ghost type='primary' feedback={loading} onClick={() => this.handleProdaModal(true)}> {'Get Payment Request'}</Button>
              )
              : null
            }

            {!loadingItems && this.isEdit() && this.hasAccess(Permissions.INVOICE.MGMT.LIST) && !loading && !loadingSave && isExportAvailable && validator.isNotEmptyArray(item.rcv_history_list) && isInfoTab
              ? (
                <Button className='btn' key='aba' ghost type='primary' feedback={loading} onClick={() => this.handleAbaRmtModal(true, ExportType.TYPE_ABA)}> {'Get ABA'}</Button>
              )
              : null
            }

            {!loadingItems && this.isEdit() && this.hasAccess(Permissions.INVOICE.MGMT.LIST) && !loading && !loadingSave && isExportAvailable && validator.isNotEmptyArray(item.rcv_history_list) && isInfoTab
              ? (
                <Button className='btn' key='rmt' ghost type='primary' feedback={loading} onClick={() => this.handleAbaRmtModal(true, ExportType.TYPE_RMT)}> {'Get Remittance'}</Button>
              )
              : null
            }

            {!loadingItems && !isInvItemsNotLoaded && showEdit && this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.UPDATE) && !loading && !loadingSave && isInfoTab
              ? (
                <div className='btn' onClick={this.handleEditButton}>
                  Edit
                </div>)
              : null}

            {((showSave && this.isEdit() && isItem && isRejected)) && this.hasAccess(Permissions.INVOICE.INFO.UPDATE)
              && !loading && !loadingSave && isInfoTab
              ? (
                <Button className='btn' key='ok-cancel' ghost feedback={loading} onClick={() => this.handleSave(InvoiceSaveType.INV_SAVETYPE_CANCELLED)}> {'Cancel'}</Button>
              )
              : null}

            {((showSave && this.isEdit() && isItem && isAllowReject)) && this.hasAccess(Permissions.INVOICE.INFO.UPDATE)
              && !loading && !loadingSave && isInfoTab
              ? (
                <Button className='btn' key='ok-reject' type='alert' feedback={loading} onClick={() => this.handleSave(InvoiceSaveType.INV_SAVETYPE_REJECTED)}> {'Reject'}</Button>
              )
              : null}

            {((!this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.CREATE)) ||
              (showSave && this.isEdit() && isItem && !isCancelled)) && this.hasAccess(Permissions.INVOICE.INFO.UPDATE)
              && !loading && !loadingSave && isInfoTab
              && (isGoingToSaveAndProcess && isAllowSaveProcess || !isGoingToSaveAndProcess) // if it is going to save and process, isAllowSaveProcess must be true to enable "Save & process" button. or else, always allow Save button
              ? (
                <Button className='btn' key='ok-save' type='primary' feedback={loading} onClick={() => this.handleSave(InvoiceSaveType.INV_SAVETYPE_SAVE)}> {isGoingToSaveAndProcess ? 'Save & Process' : 'Save'}</Button>
              )
              : null}

            {((!this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.CREATE)) ||
              (showSave && this.isEdit() && isItem && (isDrafted || isProcessed || isRejected))) && this.hasAccess(Permissions.INVOICE.INFO.UPDATE)
              && !loading && !loadingSave && isInfoTab
              ? (
                <Button className='btn' key='ok-draft' type='alert' feedback={loading} onClick={() => this.handleSave(InvoiceSaveType.INV_SAVETYPE_DRAFTED)}> {this.isEdit() && (isProcessed || isRejected) ? 'Revert to Draft' : 'Save as Draft'}</Button>
              )
              : null}
            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='invoice-panel'>
            <div className='invoice-panel-body'>
              <Skeleton loading={loading} active>
                <Spin spinning={loadingSave}>
                  <Tabs
                    defaultActiveKey={currentTab}
                    onChange={this.handleTabChange}
                  >
                    <TabPane tab='Invoice Details' key='1'>
                      {this.infoTab()}
                    </TabPane>
                    {this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.READ)
                      ? <TabPane tab='Custom Identifier' key='4'>
                        <CustomIdentifierList key={`iidftab${currentTab}`} genreId={invoiceId} genre={'invoice'} history={this.props.history} permission={Permissions.INVOICE.INFO.UPDATE} />
                      </TabPane>
                      : null}
                    {/**TODO: prod handling */}
                    {this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.LIST)
                      ? <TabPane tab={<div>Communication <Badge count={commCount} /></div>} key='5'>
                        <InvoiceComm key={`icmrtab${currentTab}`} invoiceId={invoiceId} genre={'invoice'} history={this.props.history} permission={Permissions.INVOICE.INFO.UPDATE} />
                      </TabPane>
                      : null}
                    {this.isEdit() && this.hasAccess(Permissions.INVOICE.FILES.LIST)
                      ? <TabPane tab='Files' key='3'>
                        <InvoiceFile key={`fstab${currentTab}`} invoiceId={invoiceId} invoiceInfo={item} history={this.props.history} />
                      </TabPane>
                      : null}
                    {this.isEdit()
                      ? <TabPane tab='Activity Log' key='2'>
                        <InvoiceActivity key={`ictab${currentTab}`} invoiceId={invoiceId} history={this.props.history} />
                      </TabPane>
                      : null}
                  </Tabs>
                </Spin>
              </Skeleton>
            </div>
          </div>

          <AddClientModal
            clientId={clientInfo ? clientInfo.id : ''}
            clientList={clientList}
            visible={showClientModal}
            onClose={() => this.startSelectClientModal(false)}
            onUpdate={(clientId) => this.handleChangeClient(clientId)}
          />
          <AddProviderModal
            providerId={providerInfo ? providerInfo.id : ''}
            providerList={providerList}
            visible={showProviderModal}
            onClose={() => this.startSelectProviderModal(false)}
            onUpdate={(provider) => this.handleProviderChange(provider)}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const {
      clientInfo,
      clientMsg,
      clientCurrentBudget,
      clientCurrentBudgetList,
      clientCurrentCredit,
      clientSelectedCredit,
      clientCurrentCreditInfo,
      clientCurrentAppliedCredit,
      clientCreditMaxAmt,
      clientCreditEditMaxAmt,
      categoriesList,
      subCategoriesList,
      fileList,
      fileInfo,
      invType,
      invoiceMsg,
      invoiceItems,
      invoiceReasonList,
      invSubtotalMsg,
      isInvNoDuplicated,
      isAllowABNExempted,
      isCreditApplyAll,
      providerInfo,
      providerMsg,
      rcvModalInfo,
      rcvModalMsg,
      creditModalInfo,
      loading,
      loadingHidden,
      loadingItems,
      loadingCheckInvoiceNo,
      loadingClient,
      loadingClientBudget,
      loadingCatItems,
      loadingRctDetail,
      loadingCreditDetail,
      loadingUpdateComm,
      loadingUpdateRct,
      loadingUpdateCredit,
      loadingSelectProda,
      loadingSelectAbaRmt,
      item,
      invAuthModalInfo,
      showInvAuthModal,
      showInvDeleteModal,
      showPrivateAlert,
      showPrivateAlertProvider,
      showAddFileModal,
      showRcvAmtModal,
      showCreditAmtModal,
      showCreditAmtAddModal,
      showCreditEditModal,
      showProdaModal,
      showAbaRmtModal,
      abaRmtModalType,
      abaRmtSelectList,
      abaRmtSelectAll,
      abaRmtSelectErrMsg,
      statusList,
      settingGstList
    } = this.state
    const isEdit = this.isEdit()

    const invAuthComm = item.inv_auth_comm_info || null
    const invoiceDate = isEdit ? !!item.invoice_date : getFieldValue('invoice_date')
    const invoiceNumber = isEdit ? !!item.invoice_number : getFieldValue('invoice_number')
    const targetedSubtotal = form.getFieldValue('targeted_subtotal') !== null ? form.getFieldValue('targeted_subtotal') : item.targeted_subtotal || null

    const isInvoiceDateSelected = invoiceDate && clientInfo && clientInfo.id && invoiceNumber && isInvNoDuplicated === false
    const statusItem = statusList.find(e => e.value === item.status)
    // console.log('status item', statusList, item, statusItem)
    const statusStyle = statusItem ? { backgroundColor: `${statusItem.color}` } : {}


    const isItemDisabled = statusList.find(e => {
      if ((e.reference >= '005' && e.reference <= '009') || (e.reference === '000')) {
        return item.status === e.value
      }

      return false
    })

    const isDrafted = statusList.find(e => {
      if (e.reference === '001') {
        return item.status === e.value
      }

      return false
    })

    const isClosedInv = statusList.find(e => {
      if (e.reference === '009') {
        return item.status === e.value
      }

      return false
    })

    const isAbleApplyCredit = statusList.find(e => {
      if (e.reference >= '001' && e.reference < '009') {
        return item.status === e.value
      }

      return false
    })

    const isToReceiveAmount = statusList.find(e => {
      if (e.reference >= '006' && e.reference < '009') {
        return item.status === e.value
      }

      return false
    })

    const isToRcv = statusList.find(e => {
      if (e.reference === '006') {
        return item.status === e.value
      }

      return false
    })

    const isCancelled = statusList.find(e => {
      if (e.reference === '000') {
        return item.status === e.value
      }

      return false
    })

    const isOngoing = statusList.find(e => {
      if (e.reference >= '002' && e.reference <= '009') {
        return item.status === e.value
      }

      return false
    })

    const rcvColumns = [
      {
        title: 'Received On',
        width: 5,
        render: ({ received_date }) => formatter.toShortDate(received_date)
      },
      {
        title: 'Amount',
        width: 5,
        render: ({ amount }) => <div>{formatter.toPrice(amount)}</div>
      },
      {
        title: 'Rcv Notes',
        width: 6,
        render: ({ comment }) => <div dangerouslySetInnerHTML={{
          __html: `<div>${comment ? comment.replace('\n', '<br />') : ''}</div>`
        }} />
      },
      {
        title: 'Updated At',
        width: 8,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      },
    ]

    const creditColumns = [
      {
        title: 'Received On',
        width: 5,
        render: ({ apply_date }) => formatter.toShortDate(apply_date)
      },
      {
        title: 'Apply Amount',
        width: 5,
        render: ({ amount }) => <div>{formatter.toPrice(amount)}</div>
      },
      {
        title: 'Credit Apply Notes',
        width: 6,
        render: ({ comment }) => <div>{comment}</div>
      },
      {
        title: 'Updated At',
        width: 7,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      },
      {
        title: '',
        width: 1,
        render: (item) => isAbleApplyCredit
          ? <div style={{ cursor: 'pointer' }} onClick={() => this.handleCreditAmtEditModal(true, item)}>
            <Tooltip mouseLeaveDelay={0} title='Edit Applied Credit'>
              <Icon type='form' />
            </Tooltip>
          </div>
          : null
      },
    ]

    const abaRmtColumns = [
      {
        title: 'Svc Date',
        width: 3,
        render: ({ inv_date }) => formatter.toShortDate(inv_date)
      },
      {
        title: 'Item',
        width: 9,
        render: ({ cat_name, cat_item_name }) => <div>{cat_name} - {cat_item_name}</div>
      },
      {
        title: 'Payment Date',
        width: 3,
        render: ({ payment_date }) => formatter.toShortDate(payment_date)
      },
      {
        title: 'Received Payment',
        width: 8,
        render: ({ amount, received_date, is_credit_applied }) => <div>{is_credit_applied ? `Credited` : `Received`} {formatter.toPrice(amount)} on {formatter.toShortDate(received_date)}</div>
      },
      {
        title: '',
        width: 1,
        render: ({ id, export_id }) => {
          const isChecked = abaRmtSelectList.find(f => f.id === id) || false
          return (
            <Checkbox
              checked={isChecked}
              onClick={(e) => this.updateAbaRmtModalIdsSelect(e, id, export_id)}
            />
          )
        }
      },
    ]

    const fileColumns = [
      {
        title: 'Main Category',
        width: 4,
        render: ({ main_cat_id }) => {
          const mainCat = categoriesList.find(e => e.id === main_cat_id)

          return <div>{mainCat ? mainCat.name : ''}</div>
        }
      },
      {
        title: 'Sub Category',
        width: 4,
        render: ({ sub_cat_id }) => {
          const subCat = subCategoriesList.find(e => e.cat_sub_id === sub_cat_id)

          return <div>{subCat ? subCat.cat_sub_name : ''}</div>
        }
      },
      {
        title: 'Label',
        width: 6,
        render: ({ label, fileName }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{fileName ? `[${formatter.toStandardFileName(fileName)}]` : ''}</div>
            </div>
          )
        }
      },
      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },
      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },
      {
        title: 'Inv Auth Attach?',
        width: 1,
        render: ({ is_attach_mail_comm }) => <div style={{ color: is_attach_mail_comm ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: 'Enabled',
        width: 1,
        render: ({ active }) => <div style={{ color: active ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit Details'>
            <div onClick={() => this.handleAddFileModal(true, item)} style={{ marginRight: 15 }}>
              <Icon type='form' />
            </div>
          </Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Delete File'>
            <Popconfirm
              title={`Are you sure you want to delete ${item.label}?`}
              onConfirm={() => this.handleDeleteFile(item)}
              okText='Yes'
              cancelText='No'
            >
              <Icon type='delete' style={{ marginTop: '2px', marginRight: 15 }} />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    let lastCreditBudgetId = ''
    const isRcvAmtClosed = rcvModalInfo.is_closed || parseFloat(rcvModalInfo.to_pay_subtotal) <= 0
    const isCreditClosed = creditModalInfo.is_closed || parseFloat(creditModalInfo.to_pay_subtotal) <= 0
    const isSBOverPeriod = clientCurrentBudget && clientCurrentBudget.budget_id ? formatter.toPeriodStatus(clientCurrentBudget.period_end_date).isDue : false

    const isProviderABNNotExempted = providerInfo && providerInfo.id && providerInfo.is_abn_allowed_empty === true && providerInfo.abn_exempted_reason === 'N/A'
    const isItemHasOverBudget = !!(invoiceItems.find(e => e.is_amt_over_budget !== undefined && e.is_amt_over_budget === true)) || false

    return (
      <Form>
        <Loading loading={loading} blur>

          {/** Show Inv Auth Status */}
          {item && item.id && invAuthComm && invAuthComm.id
            ? <div style={{ marginTop: '5px' }}>
              <Alert message={
                <div dangerouslySetInnerHTML={{
                  __html: `<span style="font-weight: bold;">${!invAuthComm.is_processed_or_sent ? (invAuthComm.scheduled_at ? 'Invoice Authorisation is scheduled.' : 'Invoice Authorisation is yet to schedule.') : invAuthComm.is_failed ? 'Invoice Authorisation is unable to send.' : 'Invoice Authorisation is sent successfully.'}</span><br />${!invAuthComm.is_processed_or_sent ? (invAuthComm.scheduled_at ? `The mail is scheduled to be sent at ${formatter.toDate(invAuthComm.scheduled_at, dateFormat3)}.` : `Please go to Communication tab to schedule the email sending.`) : invAuthComm.is_failed ? `The mail is unable to be sent. Reason: ${invAuthComm.failed_reason}` : `The mail is sent successfully at ${formatter.toDate(invAuthComm.processed_or_sent_at, dateFormat3)}.`}`
                }} />
              } type={!invAuthComm.is_processed_or_sent ? 'warning' : invAuthComm.is_failed ? 'error' : 'success'} showIcon /><br />
            </div>
            : null}

          {/** inv type select */}
          <Row className='header-row'>
            <Col lg={12} className='invoice-type-select' style={{ paddingTop: '12px', paddingLeft: '8pt' }}>
              <div style={{ paddingLeft: '6pt' }}>
                <ControlLabel>Select Invoice Type</ControlLabel>
              </div>
              <RadioGroup value={invType} disabled={(isEdit && !isDrafted || loadingHidden) || invType === InvoiceType.INV_TYPE_STD} onChange={(e) => this.handleInvTypeChange(e, isDrafted)}>
                {isEdit ? <RadioButton disabled value={InvoiceType.INV_TYPE_STD}>{InvoiceType.INV_TYPE_STD.name}</RadioButton> : null}
                <RadioButton className='checked' value={InvoiceType.INV_TYPE_PM}>{InvoiceType.INV_TYPE_PM.name}</RadioButton>
                <RadioButton value={InvoiceType.INV_TYPE_RMB}>{InvoiceType.INV_TYPE_RMB.name}</RadioButton>
              </RadioGroup>
            </Col>
            <Col lg={12}>
              {this.isEdit()
                ? <div className='invoice-status-row'>
                  <div className={'invoice-status'} style={statusStyle}>
                    {statusItem ? statusItem.name : formatter.capitalize(item.status_name)}
                  </div>
                </div>
                : null}
            </Col>
          </Row>

          {/** header participant and biller row */}
          <Row gutter={16} style={{ marginTop: '20px' }}>
            <Col lg={12}>
              <Panel type='custom' className='section-panel'>
                {loading || loadingClient
                  ? <Skeleton paragraph={{ rows: 1 }} />
                  : !isEdit
                    ? <Col>
                      {clientInfo && clientInfo.id
                        ? <div>
                          <div className='flex-space-between'>
                            <div>
                              <span className='client-name'><a href={`/participants/${clientInfo.ref_id}/info`} target='_blank'>{clientInfo.first_name} {clientInfo.last_name}</a></span>
                              <span className='client-accref'>{clientInfo.ndis_number}</span>
                            </div>
                            <span
                              style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                              onClick={() => this.startSelectClientModal(true)}><Icon type='edit' style={{ color: '#333', fontSize: '18px' }} /></span>
                          </div>
                          <div className='flex-left detail-row'>
                            <Icon type='calendar' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                            <div className='client-detail'>
                              {clientInfo.dob
                                ? `${formatter.toShortDate(clientInfo.dob)} (${formatter.toYearCount(clientInfo.dob)} years old)`
                                : 'N/A'}
                            </div>
                          </div>
                          <div className='flex-left detail-row'>
                            <Icon type='dollar' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                            <div className='client-detail'>{`INV AUTH: ${formatter.toYesNo(clientInfo.pm_is_auth_req)} ${clientInfo.pm_auth_amount ? `(Max ${formatter.toPrice(clientInfo.pm_auth_amount)})` : `(No Limit)`}${clientInfo.pm_auth_email ? `; ${clientInfo.pm_auth_email}` : ''}`}</div>
                          </div>
                          <div className='flex-left detail-row'>
                            <Icon type='bank' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                            <div className='client-detail'> {
                              clientInfo.pm_bsb || clientInfo.pm_bank_acc_no
                                ? `BSB: ${clientInfo.pm_bsb || 'N/A'}, ACC: ${clientInfo.pm_bank_acc_no || 'N/A'}, ${clientInfo.pm_rmt_email ? `RMT Email Available.` : 'No RMT Email Configured.'}`
                                : 'N/A'
                            }
                            </div>
                          </div>
                        </div>
                        : <div>
                          <div className='flex-space-between'>
                            <div className={'client-name-alert'}>Click on Edit button to select participant</div>
                            <span
                              style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                              onClick={() => this.startSelectClientModal(true)}><Icon type='edit' style={{ color: 'red', fontSize: '18px' }} /></span>
                          </div>

                        </div>}
                    </Col>
                    : <Col>
                      {clientInfo && clientInfo.id
                        ? <div>
                          <div className='flex-space-between'>
                            <div>
                              <span className='client-name'><a href={`/participants/${clientInfo.ref_id}/info`} target='_blank'>{clientInfo.first_name} {clientInfo.last_name}</a></span>
                              <span className='client-accref'>{clientInfo.ndis_number}</span>
                            </div>
                          </div>
                          <div className='flex-left detail-row'>
                            <Icon type='calendar' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                            <div className='client-detail'>
                              {clientInfo.dob
                                ? `${formatter.toShortDate(clientInfo.dob)} (${formatter.toYearCount(clientInfo.dob)} years old)`
                                : 'N/A'}
                            </div>
                          </div>
                          <div className='flex-left detail-row'>
                            <Icon type='dollar' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                            <div className='client-detail'>{`INV AUTH: ${formatter.toYesNo(clientInfo.pm_is_auth_req)} ${clientInfo.pm_auth_amount ? `(Max ${formatter.toPrice(clientInfo.pm_auth_amount)})` : `(No Limit)`}${clientInfo.pm_auth_email ? `; ${clientInfo.pm_auth_email}` : ''}`}</div>
                          </div>
                          <div className='flex-left detail-row'>
                            <Icon type='bank' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                            <div className='client-detail'> {
                              clientInfo.pm_bsb || clientInfo.pm_bank_acc_no
                                ? `BSB: ${clientInfo.pm_bsb || 'N/A'}, ACC: ${clientInfo.pm_bank_acc_no || 'N/A'}`
                                : 'N/A'
                            }
                            </div>
                          </div>
                        </div>
                        : <div className={'client-name-alert '}>
                          No participant info available.
                        </div>}
                    </Col>}
              </Panel>
              {clientMsg
                ? <div className='client-name-message-inv'>{clientMsg}</div>
                : null}
            </Col>
            <Col lg={12}>
              {invType.value !== InvoiceType.INV_TYPE_RMB.value
                ? <Panel type='custom' className='section-panel'>
                  {loading
                    ? <Skeleton paragraph={{ rows: 1 }} />
                    : !isEdit
                      ? <Col>
                        {providerInfo && providerInfo.id
                          ? <div>
                            <div className='flex-space-between'>
                              <div>
                                <span className='client-name'><a href={`/providers/${providerInfo.ref_id}/info`} target='_blank'>{providerInfo.fullname}</a></span>
                                {providerInfo.abn ? <span className='client-accref'>{formatter.formatABN(providerInfo.abn)}</span> : null}
                              </div>
                              <span
                                style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => this.startSelectProviderModal(true)}><Icon type='edit' style={{ color: '#333', fontSize: '18px' }} /></span>
                            </div>
                            <div className='flex-left detail-row'>
                              <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                              {providerInfo.unit_building ? <div className='client-detail'>{providerInfo.unit_building}</div> : null}
                              {providerInfo.address ? <div className='client-detail'>{providerInfo.address}</div> : null}
                            </div>
                            <div className='flex-left detail-row'>
                              <Icon type='mail' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                              <div className='client-detail'>{providerInfo.email || 'N/A'}</div>
                            </div>
                            <div className='flex-left detail-row'>
                              <Icon type='bank' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                              <div className='client-detail'> {
                                providerInfo.pm_bsb || providerInfo.pm_bank_acc_no
                                  ? `BSB: ${providerInfo.pm_bsb || 'N/A'}, ACC: ${providerInfo.pm_bank_acc_no || 'N/A'}`
                                  : 'N/A'
                              }
                              </div>
                            </div>
                          </div>
                          : <div>
                            <div className='flex-space-between'>
                              <div className={'client-name-alert'}>Click on Edit button to select provider</div>
                              <span
                                style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => this.startSelectProviderModal(true)}><Icon type='edit' style={{ color: 'red', fontSize: '18px' }} /></span>
                            </div>
                          </div>}
                      </Col>
                      : <Col>
                        {providerInfo && providerInfo.id
                          ? <div>
                            <div className='flex-space-between'>
                              <div>
                                <span className='client-name'><a href={`/providers/${providerInfo.ref_id}/info`} target='_blank'>{providerInfo.fullname}</a></span>
                                {providerInfo.abn ? <span className='client-accref'>{formatter.formatABN(providerInfo.abn)}</span> : null}
                              </div>
                            </div>
                            <div className='flex-left detail-row'>
                              <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                              {providerInfo.unit_building ? <div className='client-detail'>{providerInfo.unit_building}</div> : null}
                              {providerInfo.address ? <div className='client-detail'>{providerInfo.address}</div> : null}
                            </div>
                            <div className='flex-left detail-row'>
                              <Icon type='mail' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                              <div className='client-detail'>{providerInfo.email || 'N/A'}</div>
                            </div>
                            <div className='flex-left detail-row'>
                              <Icon type='bank' theme='twoTone' twoToneColor='#ed6d1e' style={{ marginRight: '5px' }} />
                              <div className='client-detail'> {
                                providerInfo.pm_bsb || providerInfo.pm_bank_acc_no
                                  ? `BSB: ${providerInfo.pm_bsb || 'N/A'}, ACC: ${providerInfo.pm_bank_acc_no || 'N/A'}`
                                  : 'N/A'
                              }
                              </div>
                            </div>
                          </div>
                          : isDrafted
                            ? <div className='flex-space-between'>
                              <div className={'client-name-alert'}>Click on Edit button to select provider</div>
                              <span
                                style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => this.startSelectProviderModal(true)}><Icon type='edit' style={{ color: 'red', fontSize: '18px' }} /></span>
                            </div>
                            : <div className={'client-name-alert '}>
                              No provider info available.
                            </div>}
                      </Col>}
                </Panel>
                : null}
              {providerMsg
                ? <div className='client-name-message-inv'>{providerMsg}</div>
                : null}
            </Col>
          </Row>

          {/** extra provider ABN info */}
          {(this.isEdit() && item.is_invoice_required_abn) || (!this.isEdit() && (isProviderABNNotExempted || invType === InvoiceType.INV_TYPE_RMB))
            ? <div style={{ marginTop: '20px' }}>
              <Panel
                title='Provider Info'
              >
                {!isProviderABNNotExempted && invType === InvoiceType.INV_TYPE_RMB
                  ? <Row>
                    <Col lg={16}>
                      <FormItem {...longFormItemLayout} label='Provider Name'>
                        {getFieldDecorator('invoice_provider_name', {
                          initialValue: item.invoice_provider_name || '',
                          rules: [
                            { required: !isClosedInv, message: 'Please enter Provider Name' }
                          ]
                        })(
                          <Input disabled={isClosedInv} />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  : null}
                <Row>
                  <Col lg={12}>
                    <FormItem {...shortFormItemLayout} label={isProviderABNNotExempted && invType === InvoiceType.INV_TYPE_PM ? 'Invoice ABN' : 'Provider ABN'} extra='Enter ABN without spacing'>
                      {getFieldDecorator('invoice_abn', {
                        initialValue: item.invoice_abn || '',
                        rules: !isAllowABNExempted
                          ? [
                            { required: true, message: ' ' },
                            { validator: this.validateABN }
                          ]
                          : undefined
                      })(
                        <Input
                          disabled={isAllowABNExempted || isClosedInv}
                          onChange={e => this.validateInvoiceProviderABN(e)}
                          onPaste={this.handlePasteABN}
                        />
                      )}
                    </FormItem>
                  </Col>
                  {invType === InvoiceType.INV_TYPE_PM
                    ? <Col lg={12}>
                      <FormItem {...shortFormItemLayout} label='ATO Excluded Supply?'>
                        {getFieldDecorator('is_invoice_abn_exempted', {
                          initialValue: item.is_invoice_abn_exempted || false,
                          valuePropName: 'checked'
                        })(
                          <Switch
                            onChange={this.toggleAllowABNExempted}
                            checkedChildren='Yes'
                            unCheckedChildren='No'
                          />
                        )}
                      </FormItem>
                    </Col>
                    : null}
                </Row>
              </Panel>
            </div>
            : null}

          {/** Show Private Alert */}
          {showPrivateAlert && clientInfo.private_alert
            ? <div style={{ marginTop: '20px' }}>
              <Alert message={
                <div dangerouslySetInnerHTML={{
                  __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />${this.messageHTMLReplace(clientInfo.private_alert)}`
                }} />
              } type='warning' showIcon /><br />
            </div>
            : null}
          {showPrivateAlertProvider
            ? <div style={{ marginTop: '20px' }}>
              <Alert message={
                <div dangerouslySetInnerHTML={{
                  __html: `<span style="font-weight: bold;">Provider: ${providerInfo.fullname}</span><br />${this.messageHTMLReplace(providerInfo.private_alert)}`
                }} />
              } type='warning' showIcon /><br />
            </div>
            : null}

          {/** invoice date / invoice number row */}
          {clientInfo && clientInfo.id && ((invType !== InvoiceType.INV_TYPE_RMB && providerInfo && providerInfo.id) || invType === InvoiceType.INV_TYPE_RMB)
            ? <div style={{ marginTop: '20px' }}>
              <Panel
                title='Invoice Detail'
              >
                <Row gutter={16}>
                  <Col lg={12}>
                    <Row>
                      <FormItem {...shortFormItemLayout} label='Invoice Date'>
                        {getFieldDecorator('invoice_date', {
                          initialValue: isEdit && item.invoice_date ? moment(item.invoice_date) : null,
                          rules: [
                            { required: true, message: 'Please enter invoice date.' }
                          ]
                        })(
                          // <DateTimePicker onChange={this.handleInvoiceDateChange} showTime={false} disabled={isItemDisabled} />
                          <DatePicker
                            defaultPickerValue={moment(new Date())}
                            format={dateFormat}
                            onChange={(date) => this.handleInvoiceDateChange(date, !isEdit)}
                            disabled={isItemDisabled}
                          />
                        )}
                      </FormItem>
                    </Row>
                    <Row>
                      <FormItem {...shortFormItemLayout} label='Invoice Number'>
                        {getFieldDecorator('invoice_number', {
                          initialValue: isEdit && item.invoice_number ? item.invoice_number : null,
                          rules: [
                            { required: true, message: 'Please enter invoice number.' },
                            { validator: this.validateInvoiceNumberEntry }
                          ]
                        })(
                          <Input
                            onChange={(e) => this.validateInvoiceNumber(e)}
                            addonAfter={loadingCheckInvoiceNo ? <Icon type={'loading'} className='wd-loading-icon' /> : <span style={{ marginRight: '13px' }} />}
                            disabled={!!item.is_sdb_invoice || isCancelled}
                          />
                        )}
                      </FormItem>
                    </Row>
                    <Row>
                      <FormItem {...shortFormItemLayout} label='Expected Invoiced Amount'>
                        {getFieldDecorator('targeted_subtotal', {
                          initialValue: isEdit && item.targeted_subtotal ? formatter.toPrice(item.targeted_subtotal, '') : null,
                          // initialValue: isEdit && item.targeted_subtotal ? formatter.toBigNumber(item.targeted_subtotal).toFormat(2) : null,
                          rules: [
                            { required: true, message: ' ' },
                            { validator: this.validateTargetedInvAmount }
                          ]
                        })(
                          <Input
                            addonBefore='$'
                            onChange={(e) => this.onTargetedInvAmountChange(e)}
                            onPaste={this.handlePasteAmt('targeted_subtotal')}
                            disabled={isItemDisabled}
                          />
                        )}
                      </FormItem>
                    </Row>
                    {isEdit && isOngoing
                      ? <Row>
                        <FormItem {...shortFormItemLayout} label='Process Date'>
                          {getFieldDecorator('processed_at', {
                            initialValue: item.processed_at ? moment(item.processed_at).format(dateFormat3) : null
                          })(
                            <Input
                              disabled={true}
                            />
                          )}
                        </FormItem>
                      </Row>
                      : null}
                  </Col>
                  <Col lg={12}>
                    <FormItem {...shortFormItemLayout} label='Remittance Comment'>
                      {getFieldDecorator('invoice_comment', {
                        initialValue: isEdit && item.invoice_comment ? item.invoice_comment : null
                      })(
                        <TextArea cols={2} disabled={isCancelled} />
                      )}
                    </FormItem>
                    <FormItem {...shortFormItemLayout} label='Private Notes'>
                      {getFieldDecorator('invoice_private_comment', {
                        initialValue: isEdit && item.invoice_private_comment ? item.invoice_private_comment : null
                      })(
                        <TextArea cols={2} disabled={isCancelled} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Panel>
            </div>
            : null}

          {/** files (for new invoices) and invoice items */}
          {isInvoiceDateSelected
            ? <div style={{ marginTop: '20px' }}>
              {/** invoice file upload section */}
              {isInvoiceDateSelected && !this.isEdit()
                ? <Panel
                  title='Files'
                  subtitle={(this.hasAccess(Permissions.INVOICE.FILES.CREATE)
                    ? <Button className='btn' key='ok' type='primary' feedback={loading} onClick={() => this.handleAddFileModal(true)}> {'Add File'}</Button>
                    : null)}
                >
                  <List cols={fileColumns} rows={fileList} />
                </Panel>
                : null}

              {/** invoice items section */}
              <Skeleton loading={loadingItems} active>
                <Panel
                  title='Items'
                  subtitle={validator.isNotEmptyArray(clientCurrentBudgetList) && clientInfo && clientInfo.id
                    ? <div className='btn' onClick={() => this.fetchClientBudget()}>
                      {validator.isNotEmptyArray(clientCurrentBudgetList) && validator.isNotEmptyArray(clientCurrentCredit)
                        ? 'Refresh SB & Credits'
                        : 'Refresh SB'}
                    </div>
                    : null}
                >
                  {validator.isNotEmptyArray(invoiceItems)
                    ? (validator.isNotEmptyArray(clientCurrentBudgetList)
                      ? clientCurrentBudgetList.map(e => (
                        <Spin spinning={loadingClientBudget}>
                          <div className='plan-section'>
                            <div className='plan-header'><span>{`${e.booking_number} (${formatter.toShortDate(e.period_start_date)} - ${formatter.toShortDate(e.period_end_date)})`}</span>{e.is_over_period ? <span className='plan-header-sub'>{SBOverDaysMsg}</span> : null}</div>
                            {validator.isNotEmptyArray(e.categories) && e.categories.map((c, index) => {
                              // const percent = parseFloat((((c.remaining_value || 0) / (c.budget_value || 0)) * 100).toFixed(2))
                              // const status = percent > 95 ? 'success' : percent < 30 ? 'exception' : ''
                              // const isExcursiveDiff = parseFloat(c.remaining_forecast_value) !== parseFloat(c.remaining_forecast_value_excl)
                              const percent = formatter.toBigNumber(c.remaining_value || 0).div(c.budget_value || 0).times(100).toFixed(2)
                              const status = formatter.toBigNumber(percent).isGreaterThan(95) ? 'success' : formatter.toBigNumber(percent).isLessThan(30) ? 'exception' : ''
                              const isExcursiveDiff = !formatter.toBigNumber(c.remaining_forecast_value || 0).isEqualTo(c.remaining_forecast_value_excl || 0)

                              return (
                                <Row className='plan-row' key={`pcr${index}`}>
                                  <Col lg={8}>
                                    {validator.isNotEmptyArray(c.cat_items)
                                      ? <div>
                                        <div className='title'>{c.cat_proda_name}</div>
                                        <div className='description'>{c.cat_items[0].cat_item_name}</div>
                                      </div>
                                      : <div>
                                        <div className='title'>{c.cat_proda_name}</div>
                                      </div>}
                                  </Col>
                                  <Col lg={7}>
                                    <Row>
                                      {/* <span className={`current-val ${percent < 30 ? 'exception' : ''} ${isExcursiveDiff ? 'excursive' : ''}`}> */}
                                      <span className={`current-val ${formatter.toBigNumber(percent).isLessThan(30) ? 'exception' : ''} ${isExcursiveDiff ? 'excursive' : ''}`}>
                                        <span style={{ fontStyle: 'italic', cursor: isExcursiveDiff ? 'pointer' : 'default' }}>
                                          {isExcursiveDiff
                                            ? <Tooltip mouseLeaveDelay={0} title={<div>
                                              <div>Forecast exclude current invoice:</div>
                                              <div>{formatter.toPrice(c.remaining_forecast_value_excl)}</div>
                                            </div>}>
                                              ({formatter.toPrice(c.remaining_forecast_value)})
                                            </Tooltip>
                                            : `(${formatter.toPrice(c.remaining_forecast_value)})`}
                                          &nbsp;
                                        </span>
                                        {formatter.toPrice(c.remaining_value)}
                                      </span>&nbsp;/&nbsp;
                                      <span className='budget-val'>{formatter.toPrice(c.budget_value || 0.00)}</span>
                                    </Row>
                                  </Col>
                                  <Col lg={8}>
                                    <Progress percent={formatter.toBigNumber(percent).toNumber()} status={status} strokeColor={'#19938d'} />
                                  </Col>
                                </Row>
                              )
                            })}
                          </div>
                        </Spin>
                      ))
                      : <div className='plan-section'>
                        <div className='plan-title'>{'No active budget available on invoice service dates selected.'}</div>
                      </div>
                    )
                    : null}

                  { /** credit section if available */}
                  {validator.isNotEmptyArray(clientCurrentCredit) && !isClosedInv
                    ? <Spin spinning={loadingClientBudget}>
                      <div className='plan-section credit'>
                        <div className='plan-subheader'>Available credits and able for apply.</div>
                        {clientCurrentCredit.map((e, index) => {
                          const isEstimatedExist = e.estimated_remaining_amount !== undefined
                          // const percent = formatter.toPriceFloat((e.remaining_amount / e.amount) * 100)
                          // const estimatedPercent = isEstimatedExist ? formatter.toPriceFloat((parseFloat(e.estimated_remaining_amount) / e.amount) * 100) : 100
                          const percent = formatter.toBigNumber(e.remaining_amount).div(e.amount).times(100).toFixed(2)
                          const estimatedPercent = formatter.toBigNumber(isEstimatedExist ? formatter.toBigNumber(e.estimated_remaining_amount).div(e.amount).times(100) : 100).toFixed(2)
                          const isNewBudget = e.budget_id !== lastCreditBudgetId
                          lastCreditBudgetId = e.budget_id

                          // only show estimated remaining credit value when inv is created because for edit mode, credit will be applied directly.
                          return (
                            <div key={`ccrc${index}`}>
                              {isNewBudget
                                ? <div className='plan-header'>{e.booking_number} ({formatter.toShortDate(e.period_start_date)} - {formatter.toShortDate(e.period_end_date)})</div>
                                : null}
                              <Row className='plan-row'>
                                {/* <Col lg={6}>
                                <div className='title'>{e.booking_number} ({formatter.toShortDate(e.period_start_date)} - {formatter.toShortDate(e.period_end_date)})</div>
                              </Col> */}
                                <Col lg={5}>
                                  <div className='title'>{e.report_group_name}</div>
                                </Col>
                                <Col lg={7}>
                                  <div className='title'>{e.cat_name}</div>
                                </Col>
                                <Col lg={5}>
                                  <Row>
                                    {isEstimatedExist && !this.isEdit()
                                      ? <span className={`current-val ${formatter.toBigNumber(estimatedPercent).isLessThan(30) ? 'exception' : ''}`}>
                                        {`(${formatter.toPrice(e.estimated_remaining_amount)})`}&nbsp;
                                      </span>
                                      : null}
                                    <span className={`current-val ${formatter.toBigNumber(percent).isLessThan(30) ? 'exception' : ''}`}>{formatter.toPrice(e.remaining_amount)}</span> / <span className='budget-val'>{formatter.toPrice(e.amount || 0.00)}</span>
                                  </Row>
                                </Col>
                                <Col lg={7}>
                                </Col>
                              </Row>
                            </div>
                          )
                        })}
                      </div>
                    </Spin>
                    : null}

                  { /** item rows section */}
                  {validator.isNotEmptyArray(invoiceItems)
                    ? (((!this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.CREATE) || (this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.READ))))
                      ? invoiceItems.map((itm, index) => {
                        const {
                          key,
                          id: itemId,
                          budget_id: budgetId,
                          cat_id: catId,
                          cat_id_mix: catIdMix,
                          cat_item_id: catItemId,
                          cats = [],
                          catItems = [],
                          comment,
                          gst_code: gstCode,
                          gst_list: gstList = [],
                          max_rate: maxRate,
                          inv_date: invDate,
                          inv_end_date: invEndDate,
                          inv_rate: invRate,
                          received_subtotal: rcvSubtotal = 0,
                          unit,
                          subtotal,
                          is_closed: isClosed,
                          is_delete: isDelete,
                          is_amt_over_budget: isAmtOverBudget,
                          is_sb_mismatch: isSBMismatch,
                          is_sb_period: isSBPeriod,
                          is_sb_period1: isSBPeriod1,
                          is_sb_period2: isSBPeriod2,
                          is_sb_periods: isSBPeriods,
                          is_sb_periods1: isSBPeriods1,
                          is_sb_periods2: isSBPeriods2,
                          applied_credit: appliedCredit = {}
                        } = itm

                        const inputInvDate = isEdit ? (!!invDate || !!getFieldValue(`inv_date${index}`)) : !!getFieldValue(`inv_date${index}`)
                        const inputInvEndDate = isEdit ? (!!invEndDate || !!getFieldValue(`inv_end_date${index}`)) : !!getFieldValue(`inv_end_date${index}`)
                        const inputCatId = isEdit ? (!!catId || !!getFieldValue(`cat_id${index}`)) : !!getFieldValue(`cat_id${index}`)
                        const inputCatItemId = isEdit ? (!!catItemId || !!getFieldValue(`cat_item_id${index}`)) : !!getFieldValue(`cat_item_id${index}`)
                        // const inputInvRate = parseFloat(getFieldValue(`inv_rate${index}`))
                        // const inputMaxRate = parseFloat(maxRate)
                        const inputInvRate = formatter.toBigNumber(getFieldValue(`inv_rate${index}`))
                        const inputMaxRate = formatter.toBigNumber(maxRate)
                        const isAdding = !itemId
                        // console.log('invoice items', item, inputInvDate, inputCatId, inputCatItemId)
                        // console.log('invoice items 1', itemId, key, appliedCredit, creditModalInfo)
                        const isCatEnabled = inputInvDate
                        const isCatItemEnabled = inputInvDate && inputCatId
                        const isAmountRowEnabled = inputInvDate && inputCatId && inputCatItemId
                        // const isInvRateMore = inputMaxRate !== null && inputMaxRate > 0 && inputMaxRate < inputInvRate
                        const isInvRateMore = !inputMaxRate.isNaN() && inputMaxRate.isGreaterThan(0) && inputMaxRate.isLessThan(inputInvRate)

                        const currentGST = validator.isNotEmptyArray(gstList) ? gstList.find(e => e.item_code === gstCode) : null
                        const currentGSTVal = currentGST ? currentGST.value : 0
                        // const currentGSTPct = currentGSTVal === 0 ? 0 : (100 / (100 + currentGSTVal))
                        // const gstSubtotal = currentGSTPct === 0 ? 0 : (subtotal - (subtotal * currentGSTPct))
                        const currentGSTPct = formatter.toBigNumber(currentGSTVal).isEqualTo(0) ? 0 : formatter.toBigNumber(100).div(formatter.toBigNumber(100).plus(currentGSTVal))
                        const gstSubtotal = formatter.toBigNumber(currentGSTPct).isEqualTo(0) ? 0 : formatter.toBigNumber(subtotal).minus(formatter.toBigNumber(subtotal).times(currentGSTPct)).toFixed(2)

                        const isCreditAvailable = validator.isNotEmptyArray(clientCurrentCredit) && clientCurrentCredit.findIndex(cc => cc.cat_id === catId && cc.budget_id === budgetId) > -1

                        return (
                          <div key={`invItem${index}`} className={`invoice-item ${isDelete ? 'delete' : isAdding ? 'adding' : 'list'}`}>
                            {isDelete
                              ? <div className='row-flex-end row-inline'>
                                <div className='btn' onClick={() => this.onUndoRemoveInvItem(index)}>
                                  Undo Delete
                                </div>
                              </div>
                              : null}
                            <div className={isDelete ? 'blur' : ''}>
                              <Row gutter={12}>
                                <Col lg={1}>
                                  <div className='numbering'>
                                    {index + 1}
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <FormItem label='Service Start Date'>
                                    {getFieldDecorator(`inv_date${index}`, {
                                      initialValue: invDate ? moment(invDate) : null,
                                      rules: [
                                        { required: true, message: ' ' },
                                        { validator: this.validateInvoiceStartDate }
                                      ]
                                    })(
                                      <DatePicker
                                        defaultPickerValue={moment(new Date())}
                                        format={dateFormat}
                                        placeholder={'Select Date'}
                                        onChange={(date) => this.onInvItemDateChange(date, index, 'inv_date')}
                                        disabled={isDelete || isItemDisabled}
                                      />
                                    )}
                                  </FormItem>
                                </Col>
                                <Col lg={4}>
                                  <FormItem label='Service End Date'>
                                    {getFieldDecorator(`inv_end_date${index}`, {
                                      initialValue: invEndDate ? moment(invEndDate) : null,
                                      rules: [
                                        { required: true, message: ' ' },
                                        { validator: this.validateInvoiceEndDate }
                                      ]
                                    })(
                                      <DatePicker
                                        defaultPickerValue={moment(new Date())}
                                        format={dateFormat}
                                        placeholder={'Select Date'}
                                        onChange={(date) => this.onInvItemDateChange(date, index, 'inv_end_date')}
                                        disabled={isDelete || isItemDisabled}
                                      />
                                    )}
                                  </FormItem>
                                </Col>
                                <Col lg={4}>
                                  {!this.isEdit() && validator.isNotEmptyArray(settingGstList) ||
                                    this.isEdit() && (validator.isNotEmptyArray(gstList) || validator.isNotEmptyArray(settingGstList))
                                    ? <FormItem
                                      label='GST Type'>
                                      {getFieldDecorator(`gst_code${index}`, {
                                        initialValue: gstCode,
                                        rules: [
                                          { required: true, message: 'Please select GST Type' }
                                        ]
                                      })(
                                        <Select showSearch
                                          placeholder='Select GST Type'
                                          optionFilterProp='children'
                                          notFoundContent='No GST Type available'
                                          filterOption={(input, option) => this.findCats(input, option)}
                                          onChange={(value, option) => this.onInvItemSelectGST(value, index, option)}
                                          disabled={isDelete || isItemDisabled}
                                        >
                                          {gstList.map((itm, idx) => {
                                            return (
                                              <Option
                                                key={`gstitm${idx}`}
                                                value={itm.item_code}
                                                title={itm.item_name}
                                              >
                                                {itm.item_name}
                                              </Option>
                                            )
                                          })}
                                        </Select>
                                      )}
                                    </FormItem>
                                    : null}
                                </Col>
                                <Col lg={8}></Col>
                                <Col lg={2}>
                                  {this.isEdit() && isToRcv
                                    ? <FormItem label='Closed?'>
                                      {getFieldDecorator(`is_closed${index}`, {
                                        initialValue: isClosed || false,
                                        valuePropName: 'checked'
                                      })(
                                        <Switch
                                          checkedChildren='Yes'
                                          unCheckedChildren='No'
                                          disabled={isClosed}
                                        />
                                      )}
                                    </FormItem>
                                    : null}
                                </Col>
                                <Col lg={1}>
                                  {/* {!isItemDisabled && parseFloat(rcvSubtotal) <= 0 */}
                                  {!isItemDisabled && formatter.toBigNumber(rcvSubtotal).isLessThanOrEqualTo(0)
                                    ? <div className='action-buttons'>
                                      <Popconfirm
                                        title='Confirm to delete this item?'
                                        onConfirm={(e) => this.onRemoveInvItem(index)}
                                        okText='Yes'
                                        cancelText='No'
                                      >
                                        <Icon type='delete' style={{ color: '#1d2c47', fontSize: '14px', marginBottom: '4px' }} />
                                      </Popconfirm>
                                    </div>
                                    : null}
                                </Col>
                              </Row>

                              <Row gutter={12}>
                                <Col lg={1} />
                                <Col lg={9}>
                                  {/** A hidden field to store real cat_id change in form */}
                                  <FormItem
                                    style={{ display: 'none' }}
                                    label='Support Budget Real'>
                                    {getFieldDecorator(`cat_id${index}`, {
                                      initialValue: catId
                                    })(<div>{catId}</div>)}
                                  </FormItem>

                                  <FormItem
                                    label='Support Budget'>
                                    {getFieldDecorator(`cat_id_mix${index}`, {
                                      initialValue: catIdMix,
                                      rules: [
                                        { required: true, message: 'Please select support budget' }
                                      ]
                                    })(
                                      <Select showSearch
                                        placeholder='Select Support Budget'
                                        optionFilterProp='children'
                                        notFoundContent='No support categories available'
                                        filterOption={(input, option) => this.findCats(input, option)}
                                        onChange={(id, option) => this.onInvItemSelectCat(id, index, option)}
                                        disabled={!isCatEnabled || isDelete || isItemDisabled}
                                      >
                                        {cats.map((itm, idx) => {
                                          const title = `${itm.is_multiple_budget ? `(${itm.booking_number}) ` : ''}${itm.cat_identifier_text} ${itm.cat_proda_name}`
                                          const value = itm.cat_id_mix
                                          return (
                                            <Option
                                              key={`budgetcat${idx}${title}`}
                                              value={value}
                                              title={title}
                                            >
                                              {title}
                                            </Option>
                                          )
                                        })}
                                      </Select>
                                    )}
                                  </FormItem>
                                </Col>
                                <Col lg={13}>
                                  <Spin spinning={loadingCatItems}>
                                    <FormItem label='Item Number'>
                                      {getFieldDecorator(`cat_item_id${index}`, {
                                        initialValue: catItemId,
                                        rules: [
                                          { required: true, message: 'Please select item umber' }
                                        ]
                                      })(
                                        <Select showSearch
                                          placeholder='Select Item Number'
                                          optionFilterProp='children'
                                          notFoundContent='No item numbers available'
                                          filterOption={(input, option) => this.findCatItems(input, option)}
                                          onChange={(id) => this.onInvItemSelectCatItem(id, index)}
                                          disabled={!isCatItemEnabled || isDelete || isItemDisabled}
                                        >
                                          {catItems.map((itm, idx) => {
                                            return (
                                              <Option
                                                key={`budgetcatitem${idx}${catItemId}`}
                                                value={itm.cat_item_id}
                                              >
                                                <Tooltip mouseLeaveDelay={0} title={`${itm.cat_item_name} (${itm.cat_item_identifier})`}>
                                                  {`${itm.cat_item_name} (${itm.cat_item_identifier})`}
                                                </Tooltip>
                                              </Option>
                                            )
                                          })}
                                        </Select>
                                      )}
                                    </FormItem>
                                  </Spin>
                                </Col>
                                <Col lg={1} />
                              </Row>

                              {isAmountRowEnabled
                                ? <Row>
                                  <Col lg={1} />
                                  <Col lg={3}>
                                    <FormItem style={{ marginRight: '20px' }} label='Max Rate'>
                                      {getFieldDecorator(`max_rate${index}`, {
                                        // initialValue: !maxRate ? 'No Limit' : formatter.toPriceValue(maxRate)
                                        initialValue: !maxRate ? 'No Limit' : formatter.toPrice(maxRate, '')
                                      })(
                                        <Input disabled />
                                      )}
                                    </FormItem>
                                  </Col>
                                  <Col lg={3}>
                                    <FormItem style={{ marginRight: '5px' }} label='Unit'>
                                      {getFieldDecorator(`unit${index}`, {
                                        initialValue: formatter.toDecimal(unit),
                                        rules: [
                                          { required: true, message: ' ' },
                                          { validator: this.validateUnitChange }
                                        ]
                                      })(
                                        <Input
                                          style={{ marginRight: '5px' }}
                                          onChange={(e) => this.onInvItemUnitChange(e, index)}
                                          onPaste={this.handlePasteAmt(`unit${index}`)}
                                          disabled={isDelete || isItemDisabled}
                                        />
                                      )}
                                    </FormItem>
                                  </Col>
                                  <Col lg={3}>
                                    <FormItem style={{ marginRight: '5px' }} label='Invoiced Rate'>
                                      {getFieldDecorator(`inv_rate${index}`, {
                                        initialValue: formatter.toPrice(invRate, ''),
                                        rules: [
                                          { required: true, message: ' ' },
                                          { validator: (r, v, c) => this.validateInvoicedRateChange(r, v, c, maxRate) }
                                        ]
                                      })(
                                        <Input
                                          style={{ marginRight: '5px' }}
                                          onChange={(e) => this.onInvItemInvRateChange(e, index)}
                                          onPaste={this.handlePasteAmt(`inv_rate${index}`)}
                                          disabled={isDelete || isItemDisabled}
                                        />
                                      )}
                                    </FormItem>
                                  </Col>
                                  <Col lg={5}>
                                    <FormItem style={{ marginRight: '5px' }} label='Invoiced Amount [GST Amount]'>
                                      <div className={`height-label ${isDelete ? 'delete' : ''} ${isInvRateMore ? 'error' : ''}`}>
                                        {`${formatter.toPrice(subtotal, '')} [${formatter.toPrice(gstSubtotal, '')}]`}
                                      </div>
                                    </FormItem>
                                  </Col>
                                  <Col lg={4}>
                                    <FormItem style={{ marginLeft: '10px', marginRight: '10px' }} label='Item Notes'>
                                      {getFieldDecorator(`comment${index}`, {
                                        initialValue: comment || null
                                      })(
                                        <TextArea row={2} disabled={isCancelled} />
                                      )}
                                    </FormItem>
                                  </Col>
                                  <Col lg={3}>
                                    {!this.isEdit() // CR0001: allow credit apply since creating
                                      // ? (isCreditAvailable && parseFloat(subtotal) > 0
                                      ? (isCreditAvailable && formatter.toBigNumber(subtotal).isGreaterThan(0)
                                        ? <FormItem
                                          label={'Applied Credit'}
                                        >
                                          <div className='row-flex-center' style={{ marginTop: '4px' }}>
                                            <div className={`height-label validate ${isDelete ? 'delete' : ''}`}>
                                              {formatter.toPrice(appliedCredit.amount || 0, '')}
                                            </div>
                                          </div>
                                        </FormItem>
                                        : null)
                                      : <FormItem
                                        label={isAbleApplyCredit && validator.isNotEmptyArray(clientCurrentCredit) && !isToReceiveAmount ? 'Applied Credit' : 'Received Amount'}
                                      >
                                        <div className='row-flex-center' style={{ marginTop: '4px' }}>
                                          <div className={`height-label validate ${isDelete ? 'delete' : ''}`}>
                                            {formatter.toPrice(rcvSubtotal, '')}
                                          </div>
                                        </div>
                                      </FormItem>}
                                  </Col>
                                  <Col lg={2}>
                                    <div>
                                      {!this.isEdit() // CR0001: allow credit apply since creating
                                        // ? (isCreditAvailable && parseFloat(subtotal) > 0
                                        ? (isCreditAvailable && formatter.toBigNumber(subtotal).isGreaterThan(0)
                                          ? <div className='action-buttons-act'>
                                            {(this.hasAccess(Permissions.INVOICE.CREDIT_APPLY.CREATE) || this.hasAccess(Permissions.INVOICE.CREDIT_APPLY.UPDATE))
                                              ? (appliedCredit && appliedCredit.key
                                                ? <Tooltip mouseLeaveDelay={0} title={`Edit Applied Credit Amount`}>
                                                  <div className='btn-act' onClick={() => this.handleCreditAmtAddModal(true, {
                                                    key,
                                                    client_id: clientInfo.id,
                                                    cat_id: catId,
                                                    inv_date: invDate,
                                                    budget_id: budgetId,
                                                    subtotal,
                                                    sequence: index
                                                  }, appliedCredit)}>
                                                    <Icon type='dollar' style={{ color: '#fff', fontSize: '16px' }} />
                                                  </div>
                                                </Tooltip>
                                                : <Tooltip mouseLeaveDelay={0} title={`Apply Credit Amount`}>
                                                  <div className='btn-act' onClick={() => this.handleCreditAmtAddModal(true, {
                                                    key,
                                                    client_id: clientInfo.id,
                                                    cat_id: catId,
                                                    inv_date: invDate,
                                                    budget_id: budgetId,
                                                    subtotal,
                                                    sequence: index
                                                  })}>
                                                    <Icon type='dollar' style={{ color: '#fff', fontSize: '16px' }} />
                                                  </div>
                                                </Tooltip>)
                                              : null}
                                          </div>
                                          : null)
                                        // isEdit === true
                                        : isToReceiveAmount && isAbleApplyCredit && isCreditAvailable
                                          // if torcv status, will have two buttons to indicate rcv amt and crd amt apply
                                          ? <div className='action-buttons-act'>
                                            {(this.hasAccess(Permissions.INVOICE.INFO.UPDATE))
                                              ? <Tooltip mouseLeaveDelay={0} title={`Update Received Amount`}>
                                                <div className='btn-act' onClick={() => this.handleRcvAmtModal(true, itemId)}>
                                                  <Icon type='to-top' style={{ color: '#fff', fontSize: '16px' }} />
                                                </div>
                                              </Tooltip>
                                              : null}

                                            {(this.hasAccess(Permissions.INVOICE.CREDIT_APPLY.CREATE) || this.hasAccess(Permissions.INVOICE.CREDIT_APPLY.UPDATE))
                                              ? <Tooltip mouseLeaveDelay={0} title={`Apply Credit Amount`}>
                                                <div className='btn-act' onClick={() => this.handleCreditAmtModal(true, { id: itemId })}>
                                                  <Icon type='dollar' style={{ color: '#fff', fontSize: '16px' }} />
                                                </div>
                                              </Tooltip>
                                              : null}
                                          </div>
                                          // if the inv is allowed crd apply and having valid credit, enable crd amt apply button
                                          : isAbleApplyCredit &&
                                            isCreditAvailable &&
                                            (this.hasAccess(Permissions.INVOICE.CREDIT_APPLY.CREATE) || this.hasAccess(Permissions.INVOICE.CREDIT_APPLY.UPDATE))
                                            ? <div className='action-buttons-act'>
                                              <Tooltip mouseLeaveDelay={0} title={`Apply Credit Amount`}>
                                                <div className='btn-act' onClick={() => this.handleCreditAmtModal(true, { id: itemId })}>
                                                  <Icon type='dollar' style={{ color: '#fff', fontSize: '16px' }} />
                                                </div>
                                              </Tooltip>
                                            </div>
                                            // available when it is in torcv / closed status (when closed status, show the rcv amt list only)
                                            : (isToReceiveAmount || isClosedInv) && this.hasAccess(Permissions.INVOICE.INFO.UPDATE)
                                              ? <div className='action-buttons-act'>
                                                <Tooltip mouseLeaveDelay={0} title={isToReceiveAmount ? `Update Received Amount` : `Received Amount List`}>
                                                  <div className='btn-act' onClick={() => this.handleRcvAmtModal(true, itemId)}>
                                                    <Icon type='to-top' style={{ color: '#fff', fontSize: '16px' }} />
                                                  </div>
                                                </Tooltip>
                                              </div>
                                              : null
                                      }
                                    </div>
                                  </Col>
                                </Row>
                                : null}
                              {isInvRateMore
                                ? <Row>
                                  <Col lg={1} />
                                  <Col lg={22}>
                                    <div className='warning-msg-item'>{MaxRateMismatchMsg}</div>
                                  </Col>
                                  <Col lg={1} />
                                </Row>
                                : null}
                              {isAmtOverBudget
                                ? <Row>
                                  <Col lg={1} />
                                  <Col lg={22}>
                                    <div className='warning-msg-item'>{OverBudgetMsg}</div>
                                  </Col>
                                  <Col lg={1} />
                                </Row>
                                : null}
                              {isSBMismatch
                                ? <Row>
                                  <Col lg={1} />
                                  <Col lg={22}>
                                    <div className='warning-msg-item'>{SBMismatchMsg}</div>
                                  </Col>
                                  <Col lg={1} />
                                </Row>
                                : null}
                              {/** only show the period overdue message if the item is not closed */}
                              {(isSBPeriod || isSBPeriod2 || isSBPeriod1) && !isClosed
                                ? <Row>
                                  <Col lg={1} />
                                  <Col lg={22}>
                                    <div className='warning-msg-item'>{isSBPeriod2 ? SBOver90daysMsg : isSBPeriod1 ? SBOver60daysMsg : isSBPeriod ? SBOver0daysMsg : ''}</div>
                                  </Col>
                                  <Col lg={1} />
                                </Row>
                                : null}
                              {(isSBPeriods || isSBPeriods2 || isSBPeriods1) && !isClosed
                                ? <Row>
                                  <Col lg={1} />
                                  <Col lg={22}>
                                    <div className='warning-msg-item'>{isSBPeriods2 ? SBOver90daysMultiMsg : isSBPeriods1 ? SBOver60daysMultiMsg : isSBPeriods ? SBOver0daysMultiMsg : ''}</div>
                                  </Col>
                                  <Col lg={1} />
                                </Row>
                                : null}
                            </div>
                          </div>
                        )
                      })
                      : null)
                    : <div className='client-name-message-inv'>{invoiceMsg}</div>
                  }

                  { /** add button row */}
                  {!isItemDisabled
                    ? <div className='row-flex-end' style={{ marginTop: '10px', marginBottom: '10px' }}>
                      <div className='btn' onClick={() => this.onAddInvItem()}>
                        Add Item
                      </div>
                    </div>
                    : null}

                  {/** total rows section */}
                  <div key={`invTotal`} className={`invoice-item total`}>
                    <Row>
                      <Col lg={3}>
                        <div className='total-label'>Expected Inv Amt</div>
                      </Col>
                      <Col lg={3}>
                        <div className={`height-label ${invSubtotalMsg ? 'error' : ''}`}>
                          {`${formatter.toPrice(targetedSubtotal || 0, '')} (${formatter.toPrice((formatter.toPriceFloat(targetedSubtotal || 0) - formatter.toPriceFloat(item.subtotal)), '')})`}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className='total-label'>Total Inv Amt [GST AMT]</div>
                      </Col>
                      <Col lg={5}>
                        <div className={`height-label ${invSubtotalMsg ? 'error' : ''}`}>
                          {`${formatter.toPrice(item.subtotal, '')} [${formatter.toPrice(item.gst_subtotal, '')}]`}
                        </div>
                      </Col>
                      <Col lg={1} />
                      <Col lg={3}>
                        {this.isEdit()
                          ? <div className='total-label' style={{ marginRight: '15px' }}>Total Rcv Amt</div>
                          : null}
                      </Col>
                      <Col lg={3}>
                        {this.isEdit()
                          ? <div className={`height-label validate`} style={{ marginLeft: '-5px' }}>
                            {formatter.toPrice(item.received_subtotal, '')}
                          </div>
                          : null}
                      </Col>
                      <Col lg={2} />
                    </Row>
                    <Row>
                      <Col lg={1} />
                      <Col lg={5}>
                        <div className='warning-msg-item'>{invSubtotalMsg}</div>
                      </Col>
                      <Col lg={1} />
                      <Col lg={8}>
                        {isItemHasOverBudget
                          ? <div className='warning-msg-item'>{OverBudgetByTotalMsg}</div>
                          : null}
                      </Col>
                      <Col lg={1} />
                      <Col lg={6} />
                    </Row>
                    {/* { invSubtotalMsg
                    ? <Row>
                      <Col lg={5}>
                      </Col>
                      <Col lg={9}>
                        <div className='warning-msg-item'>{invSubtotalMsg}</div>
                      </Col>
                      <Col lg={10} />
                    </Row>
                    : null } */}
                  </div>
                </Panel>
              </Skeleton>
            </div>
            : null}

          { /** File Modal */}
          <AddFileModal
            invoiceId={'add'}
            invoiceInfo={{ invoice_number: invoiceNumber, invoice_date: invoiceDate }}
            key={`addfilemodalinv_new`}
            item={fileInfo}
            categoriesList={categoriesList}
            subCategoriesList={subCategoriesList}
            onClose={() => this.handleAddFileModal(false)}
            onSetFile={(values) => this.updateFileAdded(values)}
            visible={showAddFileModal}
          />

          {/** Select Proda Modal */}
          <Modal
            width='80%'
            title={`Generate Payment Request`}
            visible={showProdaModal}
            onCancel={() => this.handleProdaModal(false)}
            footer={
              [
                <Button key='close' ghost feedback={loadingSelectProda} onClick={() => this.handleProdaModal(false)}>Close</Button>,
                <Button style={{ backgroundColor: '#3d34eb' }} key='submit21' feedback={loadingSelectProda} onClick={() => this.getProdaFile()}>Download</Button>
              ]
            }
          >
            <div>
              <div className='inv-sec-row '>
                <div className='inv-title'>Download the Payment Request generated on {formatter.toStandardDate(item.proda_export_date)}</div>
              </div>
            </div>
          </Modal>

          {/** Select ABA/Rmt Modal */}
          <Modal
            width='80%'
            title={`Select and Generate ${abaRmtModalType === ExportType.TYPE_ABA ? 'ABA' : abaRmtModalType === ExportType.TYPE_RMT ? 'Remittance' : 'ABA / Remittance'}`}
            visible={showAbaRmtModal}
            onCancel={() => this.handleAbaRmtModal(false)}
            footer={
              [
                <Button key='close' ghost feedback={loadingSelectAbaRmt} onClick={() => this.handleAbaRmtModal(false)}>Close</Button>,
                <Button style={{ backgroundColor: '#3d34eb' }} key='submit1' feedback={loadingSelectAbaRmt} onClick={() => this.preAbaRmtGetFile()}>Download Selected</Button>,
                abaRmtModalType === ExportType.TYPE_RMT ? <Button style={{ backgroundColor: '#ebc034' }} key='submit2' feedback={loadingSelectAbaRmt} onClick={() => this.preAbaRmtGetFile(true, false)}>Email Selected</Button> : null,
                abaRmtModalType === ExportType.TYPE_RMT ? <Button key='submit3' feedback={loadingSelectAbaRmt} onClick={() => this.preAbaRmtGetFile(true, true)}>Download & Email Selected</Button> : null
              ]
            }
          >
            <div>
              <div className='inv-sec-row '>
                <div className='inv-title'>Select received amounts to export.</div>
                <div className='inv-sec-row' style={{ marginRight: '30px' }}>
                  <div style={{ marginRight: '10px', fontSize: '11px' }}>Select / Deselect All</div>
                  <Checkbox
                    checked={abaRmtSelectAll}
                    onClick={(e) => this.updateAbaRmtModalIdsSelectAll(e)}
                  />
                </div>
              </div>
              {abaRmtSelectErrMsg ? <div className='inv-err-msg'>{abaRmtSelectErrMsg}</div> : null}
              <div className='inv-section'>
                <List cols={abaRmtColumns} rows={item.rcv_history_list} />
              </div>
            </div>
          </Modal>

          {/** Rcv History Modal */}
          <Modal
            key={`rcbmodal`}
            width={'60vw'}
            title='Update Received Amount'
            visible={showRcvAmtModal}
            onCancel={() => this.handleRcvAmtModal(false)}
            footer={!loadingRctDetail
              ? [
                <Button key='close' ghost disabled={loadingUpdateRct} onClick={() => this.handleRcvAmtModal(false)}>Close</Button>,
                !isRcvAmtClosed ? <Button key='submit' disabled={loadingUpdateRct} onClick={() => this.preCheckRcvAmt()}>Submit</Button> : null
              ]
              : null}
          >
            <Spin spinning={loadingRctDetail || loadingUpdateRct} blur>
              {rcvModalInfo && rcvModalInfo.id || rcvModalInfo.key
                ? <div className='invoice-rcv-amt'>

                  <Row className='row' gutter={12}>
                    <Col lg={9} style={{ textAlign: 'right' }}>
                      <span className='title'>To Receive Amount:</span>
                    </Col>
                    <Col lg={9}>
                      <span className='value-amt'>{rcvModalInfo.is_closed ? 'Closed' : `$ ${rcvModalInfo.to_pay_subtotal}`}</span>
                    </Col>
                  </Row>

                  <FormItem {...formItemLayout} label='Receive Date'>
                    {getFieldDecorator('received_date', {
                      initialValue: null,
                      rules: [
                        { required: true, message: ' ' },
                        { validator: this.validateRcvAmtDate }
                      ]
                    })(
                      <DatePicker
                        defaultPickerValue={moment(new Date())}
                        format={dateFormat}
                        disabled={isRcvAmtClosed}
                      />
                      // <DateTimePicker showTime={false} disabled={isRcvAmtClosed} />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label='Current Receive Amount'>
                    {getFieldDecorator('rcv_amount', {
                      initialValue: null,
                      rules: [
                        { required: true, message: ' ' },
                        { validator: (r, v, c) => this.validateInvItemRcvAmount(r, v, c, rcvModalInfo.to_pay_subtotal) }
                      ]
                    })(
                      <Input
                        addonBefore='$'
                        onChange={(e) => this.onInvItemRcvAmountChange(e, rcvModalInfo.to_pay_subtotal)}
                        onPaste={this.handlePasteAmt('rcv_amount')}
                        disabled={isRcvAmtClosed}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label='Rcv Notes'>
                    {getFieldDecorator(`rcv_comment`, {
                      initialValue: rcvModalInfo.comment || null
                    })(
                      <TextArea
                        row={2}
                        disabled={isRcvAmtClosed}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label='Going to close the Item?'>
                    {getFieldDecorator(`is_auto_closed`, {
                      initialValue: rcvModalInfo.is_auto_closed || false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        disabled={isRcvAmtClosed}
                        onChange={(e) => this.onInvItemRcvClosedChange(e, rcvModalInfo.to_pay_subtotal)}
                      />
                    )}
                  </FormItem>
                  {rcvModalInfo.is_overpay
                    ? <div className='warning-msg'>{RcvAmountOverPayMsg}</div>
                    : null}
                  {rcvModalMsg
                    ? <div className='warning-msg'>{rcvModalMsg}</div>
                    : null}
                  {validator.isNotEmptyArray(rcvModalInfo.receive_history)
                    ? <div>
                      <List cols={rcvColumns} rows={rcvModalInfo.receive_history} />
                    </div>
                    : null}
                </div>
                : <div>Getting Invoice Item Detail...</div>}
            </Spin>
          </Modal>

          {/** Credit Edit Modal */}
          <Modal
            key={`csrcmodal`}
            width={'60vw'}
            title='Edit Credit Amount'
            visible={showCreditEditModal}
            onCancel={() => this.handleCreditAmtEditModal(false)}
            footer={[
              <Button key='close' ghost disabled={loadingUpdateCredit} onClick={() => this.handleCreditAmtEditModal(false)}>Close</Button>,
              !creditModalInfo.is_closed && clientCurrentCreditInfo.id || clientCurrentCreditInfo.key
                ? <Button key='submit' disabled={loadingUpdateCredit} onClick={() => this.preCheckCreditEditAmt()}>Submit</Button>
                : null
            ]}
          >
            <Spin spinning={loadingCreditDetail || loadingUpdateCredit} blur>
              {clientCurrentCreditInfo && clientCurrentCreditInfo.id || clientCurrentCreditInfo.key
                ? <div>
                  <FormItem {...formItemLayout} label='Apply Date'>
                    {getFieldDecorator('edit_apply_date', {
                      initialValue: clientCurrentCreditInfo.apply_date ? formatter.toMoment(clientCurrentCreditInfo.apply_date) : null,
                      rules: [
                        { required: true, message: ' ' },
                        { validator: this.validateCreditApplyAmtDate }
                      ]
                    })(
                      <DateTimePicker disabled={!isAbleApplyCredit} showTime={false} />
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label='Credit Apply Amount'
                    extra={`Max Allowed Amount: ${formatter.toPrice(clientCreditEditMaxAmt)}`}
                  >
                    {getFieldDecorator('edit_credit_amount', {
                      initialValue: clientCurrentCreditInfo.amount || 0.0,
                      rules: [
                        { required: true, message: ' ' },
                        { validator: (r, v, c) => this.validateCreditEditAmt(r, v, c) }
                      ]
                    })(
                      <Input
                        addonBefore='$'
                        disabled={!isAbleApplyCredit}
                        onPaste={this.handlePasteAmt('edit_credit_amount')}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label='Credit Apply Notes'>
                    {getFieldDecorator(`edit_credit_comment`, {
                      initialValue: clientCurrentCreditInfo.comment || null
                    })(
                      <TextArea
                        row={2}
                        disabled={!isAbleApplyCredit}
                      />
                    )}
                  </FormItem>
                </div>
                : null}

            </Spin>
          </Modal>

          {/** Credit History Modal */}
          <Modal
            key={`csrhmodal`}
            width={'80vw'}
            title='Apply Credit Amount'
            visible={showCreditAmtModal}
            onCancel={() => this.handleCreditAmtModal(false)}
            footer={!loadingCreditDetail
              ? [
                <Button key='close' ghost disabled={loadingUpdateCredit} onClick={() => this.handleCreditAmtModal(false)}>Close</Button>,
                !creditModalInfo.is_closed && clientSelectedCredit.id ? <Button key='submit' disabled={loadingUpdateCredit} onClick={() => this.preCheckCreditAmt()}>Submit</Button> : null
              ]
              : null}
          >
            <Spin spinning={loadingCreditDetail || loadingUpdateCredit} blur>
              {creditModalInfo && creditModalInfo.id || creditModalInfo.key
                ? <div className='invoice-rcv-amt'>

                  <Row className='row' gutter={12}>
                    <Col lg={9} style={{ textAlign: 'right' }}>
                      <span className='title'>To Receive Amount:</span>
                    </Col>
                    <Col lg={9}>
                      <span className='value-amt'>{isCreditClosed ? 'Closed' : `${formatter.toPrice(creditModalInfo.to_pay_subtotal)}`}</span>
                    </Col>
                  </Row>

                  {!isCreditClosed
                    ? <div>
                      {validator.isNotEmptyArray(creditModalInfo.credits)
                        ? <FormItem
                          {...formItemLayout}
                          label='Select Credit'
                        >
                          {getFieldDecorator('credit_id', {
                            initialValue: null,
                            rules: [
                              { required: true, message: 'Please select credit entry' },
                            ]
                          })(
                            <Select
                              style={{ width: '35vw' }}
                              disabled={isCreditClosed}
                              optionFilterProp='children'
                              notFoundContent='No Credit Entry available'
                              placeholder='Please select credit entry'
                              showSearch
                              filterOption={(input, option) =>
                                this.findCredits(input, option)
                              }
                              onChange={(id) => this.onCreditAmtSelect(id)}
                            >
                              {
                                creditModalInfo.credits.map((c) => {
                                  return (
                                    <Option key={c.id} value={c.id}>
                                      <div>
                                        <div>{formatter.toPrice(c.remaining_amount)} - {c.booking_number}</div>
                                        <div>{c.cat_name} ({c.report_group_name})</div>
                                      </div>
                                    </Option>
                                  )
                                })
                              }
                            </Select>
                          )}
                        </FormItem>
                        : null}

                      <FormItem {...formItemLayout} label='Apply Date'>
                        {getFieldDecorator('apply_date', {
                          initialValue: null,
                          rules: [
                            { required: true, message: ' ' },
                            { validator: this.validateCreditApplyAmtDate }
                          ]
                        })(
                          <DatePicker
                            defaultPickerValue={moment(new Date())}
                            format={dateFormat}
                            disabled={isCreditClosed}
                          />
                          // <DateTimePicker disabled={isCreditClosed} showTime={false} />
                        )}
                      </FormItem>

                      {/** Custom row to fit the credit amt apply toggle to credit amount input */}
                      <div className='credit-amt'>
                        <Row style={{ margin: '0 0 24px' }}>
                          <Col lg={formItemLayout.labelCol.lg} sm={formItemLayout.labelCol.sm} md={formItemLayout.labelCol.md}>
                            <div className='antd-label-special'>Credit Apply Amount</div>
                          </Col>
                          <Col lg={formItemLayout.wrapperCol.lg} sm={formItemLayout.wrapperCol.sm} md={formItemLayout.wrapperCol.md}>
                            <Row>
                              <Col lg={7}>
                                <Switch
                                  style={{ marginTop: '5px' }}
                                  checkedChildren='Apply All Amt'
                                  unCheckedChildren='Not Apply All'
                                  checked={isCreditApplyAll}
                                  disabled={isCreditClosed || !clientSelectedCredit.id}
                                  onChange={this.updateIsApplyCreditAll}
                                />
                              </Col>
                              <Col lg={15}>
                                <FormItem>
                                  {getFieldDecorator('credit_amount', {
                                    initialValue: 0,
                                    rules: [
                                      { required: true, message: ' ' },
                                      { validator: (r, v, c) => this.validateCreditAmt(r, v, c) }
                                    ]
                                  })(
                                    <Input
                                      addonBefore='$'
                                      disabled={isCreditClosed || isCreditApplyAll || !clientSelectedCredit.id}
                                      // onChange={(e) => this.onInvItemRcvAmountChange(e, creditModalInfo.to_pay_subtotal)}
                                      onPaste={this.handlePasteAmt('credit_amount')}
                                    />
                                  )}
                                </FormItem>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>

                      {/* <FormItem {...formItemLayout} label='Credit Apply Amount'>
                          {getFieldDecorator('credit_amount', {
                            initialValue: 0,
                            rules: [
                              { required: true, message: ' ' },
                              { validator: (r,v,c) => this.validateCreditAmt(r, v, c) }
                            ]
                          })(
                            <Input
                              addonBefore='$'
                              disabled={isCreditClosed}
                              // onChange={(e) => this.onInvItemRcvAmountChange(e, creditModalInfo.to_pay_subtotal)}
                            />
                          )}
                        </FormItem> */}

                      <FormItem {...formItemLayout} label='Credit Apply Notes'>
                        {getFieldDecorator(`credit_comment`, {
                          initialValue: creditModalInfo.comment || null
                        })(
                          <TextArea
                            row={2}
                            disabled={isCreditClosed}
                          />
                        )}
                      </FormItem>
                    </div>
                    : null}

                  {/* <FormItem {...formItemLayout} label='Closed the Item?'>
                      {getFieldDecorator(`is_auto_closed`, {
                        initialValue: creditModalInfo.is_auto_closed || false,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          checkedChildren='Yes'
                          unCheckedChildren='No'
                          disabled={creditModalInfo.is_closed}
                          onChange={(e) => this.onInvItemRcvClosedChange(e, creditModalInfo.to_pay_subtotal)}
                        />
                      )}
                    </FormItem> */}
                  {validator.isNotEmptyArray(creditModalInfo.applied_history)
                    ? <div>
                      <List cols={creditColumns} rows={creditModalInfo.applied_history} />
                    </div>
                    : null}
                </div>
                : <div>Getting Invoice Item Detail...</div>}
            </Spin>
          </Modal>

          {/** Credit Raw Add Modal - similar with Credit Apply Modal but only available for new invoice */}
          <Modal
            key={`csavmodal`}
            width={'80vw'}
            title={clientCurrentAppliedCredit && clientCurrentAppliedCredit.key ? 'Edit Applied Credit Amount' : 'Apply Credit Amount'}
            visible={showCreditAmtAddModal}
            onCancel={() => this.handleCreditAmtAddModal(false)}
            footer={!loadingCreditDetail
              ? [
                <Button key='close' ghost disabled={loadingUpdateCredit} onClick={() => this.handleCreditAmtAddModal(false)}>Close</Button>,
                !creditModalInfo.is_closed && clientSelectedCredit.id
                  ? <Button key='submit' disabled={loadingUpdateCredit} onClick={() => this.preCheckCreditAmt()}>Submit</Button>
                  : null
              ]
              : null}
          >
            <Spin spinning={loadingCreditDetail || loadingUpdateCredit} blur>
              {creditModalInfo && creditModalInfo.key
                ? <div className='invoice-rcv-amt'>

                  <Row className='row' gutter={12}>
                    <Col lg={9} style={{ textAlign: 'right' }}>
                      <span className='title'>To Receive Amount:</span>
                    </Col>
                    <Col lg={9}>
                      <span className='value-amt'>{isCreditClosed ? 'Closed' : `$ ${creditModalInfo.to_pay_subtotal}`}</span>
                    </Col>
                  </Row>

                  {!isCreditClosed
                    ? <div>
                      {validator.isNotEmptyArray(creditModalInfo.credits)
                        ? <FormItem
                          {...formItemLayout}
                          label='Select Credit'
                        >
                          {getFieldDecorator('credit_id', {
                            initialValue: clientCurrentAppliedCredit.credit_id || null,
                            rules: [
                              { required: true, message: 'Please select credit entry' },
                            ]
                          })(
                            <Select
                              style={{ width: '35vw' }}
                              disabled={isCreditClosed}
                              showSearch
                              optionFilterProp='children'
                              notFoundContent='No Credit Entry available'
                              placeholder='Please select credit entry'
                              showSearch
                              filterOption={(input, option) =>
                                this.findCredits(input, option)
                              }
                              onChange={(id) => this.onCreditAmtSelect(id)}
                            >
                              {
                                creditModalInfo.credits.map((c) => {
                                  return (
                                    <Option key={c.id} value={c.id}>
                                      <div>
                                        <div>{formatter.toPrice(c.remaining_amount)} - {c.booking_number}</div>
                                        <div>{c.cat_name} ({c.report_group_name})</div>
                                      </div>
                                    </Option>
                                  )
                                })
                              }
                            </Select>
                          )}
                        </FormItem>
                        : null}

                      <FormItem {...formItemLayout} label='Apply Date'>
                        {getFieldDecorator('apply_date', {
                          initialValue: clientCurrentAppliedCredit && clientCurrentAppliedCredit.key ? moment(clientCurrentAppliedCredit.apply_date) : null,
                          rules: [
                            { required: true, message: ' ' },
                            { validator: this.validateCreditApplyAmtDate }
                          ]
                        })(
                          <DatePicker
                            defaultPickerValue={moment(new Date())}
                            format={dateFormat}
                            disabled={isCreditClosed}
                          />
                          // <DateTimePicker disabled={isCreditClosed} showTime={false} />
                        )}
                      </FormItem>

                      {/** Custom row to fit the credit amt apply toggle to credit amount input */}
                      {/** Add a line on above to show real remaining value able to claim (clientCreditMaxAmt) */}
                      <div className='credit-amt'>
                        {clientSelectedCredit && clientSelectedCredit.id
                          ? <Row style={{ margin: '0 0 8px', color: 'red' }}>
                            <Col lg={formItemLayout.labelCol.lg} sm={formItemLayout.labelCol.sm} md={formItemLayout.labelCol.md}>
                            </Col>
                            <Col lg={formItemLayout.wrapperCol.lg} sm={formItemLayout.wrapperCol.sm} md={formItemLayout.wrapperCol.md}>
                              You could apply maximum {formatter.toPrice(clientCreditMaxAmt)} on this item.
                            </Col>
                          </Row>
                          : null}
                        <Row style={{ margin: '0 0 24px' }}>
                          <Col lg={formItemLayout.labelCol.lg} sm={formItemLayout.labelCol.sm} md={formItemLayout.labelCol.md}>
                            <div className='antd-label-special'>Credit Apply Amount</div>
                          </Col>
                          <Col lg={formItemLayout.wrapperCol.lg} sm={formItemLayout.wrapperCol.sm} md={formItemLayout.wrapperCol.md}>
                            <Row>
                              <Col lg={7}>
                                <Switch
                                  style={{ marginTop: '5px' }}
                                  checkedChildren='Apply All Amt'
                                  unCheckedChildren='Not Apply All'
                                  checked={isCreditApplyAll}
                                  disabled={isCreditClosed || !clientSelectedCredit.id}
                                  onChange={this.updateIsApplyCreditAll}
                                />
                              </Col>
                              <Col lg={15}>
                                <FormItem>
                                  {getFieldDecorator('credit_amount', {
                                    initialValue: clientCurrentAppliedCredit && clientCurrentAppliedCredit.key ? clientCurrentAppliedCredit.amount : 0,
                                    rules: [
                                      { required: true, message: ' ' },
                                      { validator: (r, v, c) => this.validateCreditAmt(r, v, c) }
                                    ]
                                  })(
                                    <Input
                                      addonBefore='$'
                                      disabled={isCreditClosed || isCreditApplyAll || !clientSelectedCredit.id}
                                      // onChange={(e) => this.onInvItemRcvAmountChange(e, creditModalInfo.to_pay_subtotal)}
                                      onPaste={this.handlePasteAmt('credit_amount')}
                                    />
                                  )}
                                </FormItem>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                      </div>

                      {/* <FormItem {...formItemLayout} label='Credit Apply Amount'>
                          {getFieldDecorator('credit_amount', {
                            initialValue: clientCurrentAppliedCredit.amount || null,
                            rules: [
                              { required: true, message: ' ' },
                              { validator: (r,v,c) => this.validateCreditAmt(r, v, c) }
                            ]
                          })(
                            <Input
                              addonBefore='$'
                              disabled={isCreditClosed}
                              // onChange={(e) => this.onInvItemRcvAmountChange(e, creditModalInfo.to_pay_subtotal)}
                            />
                          )}
                        </FormItem> */}

                      <FormItem {...formItemLayout} label='Credit Apply Notes'>
                        {getFieldDecorator(`credit_comment`, {
                          initialValue: clientCurrentAppliedCredit.comment || null
                        })(
                          <TextArea
                            row={2}
                            disabled={isCreditClosed}
                          />
                        )}
                      </FormItem>
                    </div>
                    : null}
                </div>
                : <div>Getting Invoice Item Detail...</div>}
            </Spin>
          </Modal>

          {/** Invoice Authorisation Modal */}
          <Modal
            key={`invathmodal`}
            width={'416px'}
            visible={showInvAuthModal}
            onCancel={(invAuthModalInfo && invAuthModalInfo.isDisabledClose) || loadingUpdateComm ? null : () => this.handleInvAuthModal(false)}
            footer={[
              ((invAuthModalInfo && invAuthModalInfo.isDisabledClose) || invAuthModalInfo.invTypeValue === undefined)
                ? null
                : <Button key='close' ghost feedback={loadingUpdateComm} onClick={() => this.handleInvAuthModal(false)}>Cancel</Button>,
              invAuthModalInfo && (invAuthModalInfo.invTypeValue === InvoiceType.INV_TYPE_PM.value)
                ? <Button key='skipas' style={{ backgroundColor: '#ebab34' }} feedback={loadingUpdateComm} onClick={() => this.handleConfirmInvAuth(INV_AUTH_ACTION_SKIP)}>Skip & Auth</Button>
                : null,
              !invAuthModalInfo
                ? null
                : invAuthModalInfo.invTypeValue === undefined
                  ? null
                  : invAuthModalInfo.invTypeValue === InvoiceType.INV_TYPE_PM.value // if invoice type is pm, the action button name is send now but the action type is send now, if invoice type is rmb, action button name is send now but action type will be skip (send the authorised email must use skip action)
                    ? <Button key='submit-sendnow' style={{ backgroundColor: '#1890ff' }} feedback={loadingUpdateComm} onClick={() => this.handleConfirmInvAuth(INV_AUTH_ACTION_SENDNOW)}>Send Now</Button>
                    : invAuthModalInfo.invTypeValue === InvoiceType.INV_TYPE_RMB.value
                      ? <Button key='submit-skipas' style={{ backgroundColor: '#1890ff' }} feedback={loadingUpdateComm} onClick={() => this.handleConfirmInvAuth(INV_AUTH_ACTION_SKIP)}>Send Now</Button>
                      : null
            ]}
          >
            <div>
              <span><Icon type='question-circle' style={{ color: '#faad14', fontSize: '22px', marginRight: '16px' }} /><span style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '15px', lineHeight: '1.4', fontWeight: '500' }}>{invAuthModalInfo && invAuthModalInfo.title ? invAuthModalInfo.title : null}</span></span>
              <div style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '13px', marginTop: '8px', marginLeft: '38px' }}>{invAuthModalInfo && invAuthModalInfo.content ? invAuthModalInfo.content : null}</div>
            </div>
          </Modal>

          {/** Invoice Delete Modal */}
          <SideModal
            title={'Invoice Delete Reason'}
            showModal={showInvDeleteModal}
            onClose={() => this.handleDeleteModal(false)}
            buttons={[
              <Button key='deletesave' onClick={this.handleDelete} feedback={loading}>Proceed Delete</Button>
            ]}
          >
            {showInvDeleteModal
              ? <Form layout='vertical'>
                <FormItem label='Reason Type'>
                  {getFieldDecorator('delete_reason_type', {
                    rules: [
                      { required: true, message: 'Please select reason type' }
                    ]
                  })(
                    <Select disabled={loading} style={{ width: '100%' }}>
                      {invoiceReasonList.map((items, idx) => {
                        return <Option key={`invrsn-${idx}`} value={items.name}>{items.name}</Option>
                      })}
                    </Select>
                  )}
                </FormItem>

                <FormItem label='Reason Notes'>
                  {getFieldDecorator('delete_reason_note', {
                    rules: [
                      { required: true, message: 'Please enter reason notes' },
                      { whitespace: true, message: `Please enter reason notes` },
                      { min: 2, message: 'Minimum 2 characters required' },
                    ]
                  })(
                    <TextArea disabled={loading} row={2} />
                  )}
                </FormItem>
              </Form>
              : null}
          </SideModal>
        </Loading>
      </Form>
    )
  }

  /** fetch data */
  fetchCommCount = async () => {
    try {
      const { item } = this.state
      const r = await commService.getCommCount('invoice', item.id)

      if (r && r.count !== undefined) {
        let data = {
          commCount: r.count || 0,
          commSentCount: r.countSent
        }
        this.setState(data)

        return data
      }

      return null
    } catch (e) {
      return null
    }
  }

  fetchClientBudget = async () => {
    const { clientInfo, item } = this.state

    if (clientInfo && clientInfo.id) {
      try {
        this.setState({ loadingClientBudget: true })

        let clientBudget = await clientInvoiceService.listAllClientBudgetCurrent(clientInfo.id, item.id || null)
        clientBudget = clientBudget.filter(e => e.is_monthly_fee_main_set === !!item.is_sdb_invoice)
        const clientCredit = await creditClientService.getClientAvailableCredits(clientInfo.id)

        this.setState({
          clientBudget,
          clientCredit
        }, () => {
          this.onUpdateCurrentClientBudgetList()
          this.validateClientCredit()

          this.setState({ loadingClientBudget: false })
        })
      } catch (e) {
        notify.error('Unable to refresh SBs', 'Some error occured. Please try again later.')
        this.setState({ loadingClientBudget: false })
      }
    }
  }

  fetchDataList = async () => {
    try {
      this.setState({ loadingClient: true })
      const [clients, providers, mainCats, subCats] = await Promise.all([
        clientService.listAllClients(),
        providerService.listAllProviders(),
        settingFileService.listFileCatByPage(1, 0, { active: true, module_type: 'invoice' }),
        settingFileService.listFileCatSubByPage(1, 0, { active: true, module_type: 'invoice' })
      ])

      this.setState({
        clientList: validator.isNotEmptyArray(clients) ? clients : [],
        providerList: validator.isNotEmptyArray(providers) ? providers : [],
        categoriesList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
        subCategoriesList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
        loadingClient: false
      })
      // console.log('client list', clients, providers)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client list successfully. Please try again later.')
    }
  }

  fetchDataListEdit = async () => {
    try {
      const providers = await providerService.listAllProviders()

      this.setState({
        providerList: validator.isNotEmptyArray(providers) ? providers : [],
      })
      // console.log('client list', clients, providers)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client list successfully. Please try again later.')
    }
  }

  fetchInvGSTCodes = async () => {
    const gstList = await settingGSTRateService.getItemAll()

    if (validator.isNotEmptyArray(gstList)) {
      this.setState({
        settingGstList: gstList
      })
    }
  }

  fetchInvStatusList = async () => {
    const filter = {}
    filter.identifier = {
      $or: [
        { condition: '=', value: 'invoice-status' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)

    if (settings && validator.isNotEmptyArray(settings.list)) {
      this.setState({
        statusList: settings.list.filter(item => item.identifier === 'invoice-status')
      })
    }
  }

  fetchInvoice = async (isLoading = true) => {
    if (!this.hasAccess(Permissions.INVOICE.INFO.READ)) {
      return
    }

    try {
      const refId = this.getRefId()
      let id = null

      if (refId === 'add' || refId === 'new' || !refId) return

      if (isLoading) {
        this.setState({
          loading: true,
          loadingHidden: true,
          loadingItems: true
        })
      } else {
        this.setState({ loadingHidden: true, loadingItems: true })
      }
      const inv = await invoiceService.getRef(refId)

      let clientInfo = {}
      let providerInfo = {}
      let invoiceItems = []
      let invoiceHistory = []
      let clientCredit = []
      let clientCurrentCredit = []
      let showPrivateAlert = false
      let showPrivateAlertProvider = false

      if (inv && inv.id) {
        id = inv.id
        // invoiceItems = inv.items || []
        clientInfo = inv.client_info || {}
        providerInfo = inv.provider_info || {}
        invoiceHistory = inv.history || []
        // clientCredit = inv.credits || []
        // clientCurrentCredit = inv.current_credits || []

        // delete inv.items
        delete inv.client_info
        delete inv.provider_info
        delete inv.history

        const invType = inv.invoice_type === InvoiceType.INV_TYPE_PM.value
          ? InvoiceType.INV_TYPE_PM
          : inv.invoice_type === InvoiceType.INV_TYPE_RMB.value
            ? InvoiceType.INV_TYPE_RMB
            : inv.invoice_type === InvoiceType.INV_TYPE_STD.value
              ? InvoiceType.INV_TYPE_STD
              : this.state.invType

        this.setState({
          loading: false,
          loadingHidden: false,
          item: inv,
          itemOri: cloneDeep(inv),
          clientInfo,
          providerInfo,
          invType,
          invoiceHistory,
          isAllowABNExempted: inv.is_invoice_abn_exempted || false,
          isEnableEmailSend: inv.is_enable_send_email || false,
          isAllowSaveProcess: inv.is_sdb_invoice === true ? true : validator.isNotEmptyArray(inv.comm_file_list),
          isInvNoDuplicated: false // add this flag for fetched invoice as default is undefined and may affect inv items showing up
        }, async () => {
          this.fetchInvReasons()
          this.fetchCommCount()

          let clientBudget = []
          if (inv.client_id) {
            clientBudget = await clientInvoiceService.listAllClientBudgetCurrent(inv.client_id, inv.id)
            clientBudget = clientBudget.filter(e => e.is_monthly_fee_main_set === !!inv.is_sdb_invoice)
          }

          if (clientInfo && clientInfo.id) {
            if (clientInfo.private_alert) {
              showPrivateAlert = true
            }
          }

          if (providerInfo && providerInfo.id) {
            // if (clientInfo.private_alert) {
              showPrivateAlertProvider = true
            // }
          }

          try {
            const items = await invoiceService.getItemsRef(refId)

            if (items && items.items) {
              invoiceItems = items.items || []
              clientCredit = items.credits || []
              clientCurrentCredit = items.current_credits || []

              this.setState({
                item: { ...this.state.item, rcv_history_list: items.rcv_history_list || [] }, // move rcv_history_list originally from inv api to item state, or else Get ABA/RMT button wont show
                loadingItems: false,
                invoiceItems,
                invoiceItemsOri: cloneDeep(invoiceItems), // must deepclone or else ori inv items are affected when changing inv items on inputs
                invoiceMsg: validator.isNotEmptyArray(invoiceItems) ? '' : SelectInvoiceMsg,
                clientBudget,
                clientCredit,
                clientCurrentCredit
              }, () => {
                this.handleInvoiceDateChange(moment(inv.invoice_date), false)
                this.onUpdateCurrentClientBudgetList()
                this.onTargetedInvAmountChange(inv.targeted_subtotal || 0) // add checking on targeted subtotal to make sure mismatch message come out once inv is loaded
                this.onInvMultiItemsValidateBudget(invoiceItems)
              })
            }
          } catch (e) {
            notify.error('Unable to load successfully', 'Unable to load invoice successfully. Please try again later.')
            this.setState({ isInvItemsNotLoaded: true })
          }
        })

        // this.setState({
        //   loading: false,
        //   item: inv,
        //   itemOri: cloneDeep(inv),
        //   invoiceItems,
        //   invoiceItemsOri: cloneDeep(invoiceItems), // must deepclone or else ori inv items are affected when changing inv items on inputs
        //   clientInfo,
        //   clientBudget,
        //   providerInfo,
        //   invoiceHistory,
        //   clientCredit,
        //   clientCurrentCredit,
        //   showPrivateAlert,
        //   isAllowABNExempted: inv.is_invoice_abn_exempted || false,
        //   isEnableEmailSend: inv.is_enable_send_email || false
        // }, () => {
        //   this.fetchCommCount()
        //   this.handleInvoiceDateChange(moment(inv.invoice_date), false)
        //   this.onUpdateCurrentClientBudgetList()
        //   this.fetchInvReasons()
        // })
      } else {
        notify.error('Unable to load successfully', 'Unable to load invoice successfully. Please try again later.')
        this.setState({ loading: false })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load invoice successfully. Please try again later.')
    }
  }

  fetchInvReasons = async () => {
    try {
      const invoiceReasonList = await settingReasonService.listReasonItemsAll('inv-delete')
      if (validator.isNotEmptyArray(invoiceReasonList)) {
        this.setState({ invoiceReasonList })
      }
    } catch (e) {
      console.log('fetch inv reasons err', e)
    }
  }

  findCats = (input, option) => {
    const item = `${option.props.children}`
    return item.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findCatItems = (input, option) => {
    const item = `${option.props.children.props.children}`
    return item.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  startSelectClientModal = (showClientModal, cb = () => { }) => {
    this.setState({ showClientModal }, cb)
  }

  startSelectProviderModal = (showProviderModal, cb = () => { }) => {
    this.setState({ showProviderModal }, cb)
  }

  /** handle change client/provider/tab/inv info */
  handleChangeClient = async (clientId) => {
    this.startSelectClientModal(false, async () => {
      this.setState({
        loadingClient: true,
        clientMsg: '',
        clientCredit: [],
        clientCurrentCredit: []
      })
      const client = await clientService.get(clientId)

      let budget = []
      let credit = []
      let showPrivateAlert = false
      let isNoBank = false
      let isNoRmtEmail = false

      if (client && client.id) {
        const { item, invType } = this.state

        if (invType === InvoiceType.INV_TYPE_RMB) {
          if (!(client.pm_bank_acc_no && client.pm_bank_code && client.pm_bsb)) {
            isNoBank = true
            this.triggerClientNoBankError(client)
          } else if (!(client.pm_rmt_email && client.pm_rmt_email_name)) {
            isNoRmtEmail = true
            this.triggerClientNoRmtEmailError(client)
          }
        }

        if (client.private_alert) {
          showPrivateAlert = true
          this.triggerClientWarning(client)
        }

        budget = await clientInvoiceService.listAllClientBudgetCurrent(clientId)
        budget = budget.filter(e => e.is_monthly_fee_main_set === !!item.is_sdb_invoice)
        credit = await creditClientService.getClientAvailableCredits(clientId)
      }

      this.setState({
        loadingClient: false,
        clientInfo: client && client.id ? client : {},
        clientBudget: validator.isNotEmptyArray(budget) ? budget : [],
        clientMsg: client && client.id ? (isNoBank && isNoRmtEmail ? `${ClientNoBankInfoMsg} ${ClientNoRmtEmailMsg}` : isNoBank ? ClientNoBankInfoMsg : isNoRmtEmail ? ClientNoRmtEmailMsg : '') : SelectClientMsg,
        clientCredit: credit,
        showPrivateAlert
      })
    })
  }

  triggerClientWarning = (clientInfo) => {
    warning({
      title: `Participant: ${clientInfo.first_name} ${clientInfo.last_name}`,
      content: (
        <div>
          {clientInfo.public_alert
            ? <div dangerouslySetInnerHTML={{ __html: this.messageHTMLReplace(clientInfo.public_alert) }} />
            : null}
          {clientInfo.private_alert
            ? <div dangerouslySetInnerHTML={{ __html: this.messageHTMLReplace(clientInfo.private_alert) }} />
            : null}
        </div>
      ),
      okText: 'OK',
      onOk() { },
    })
  }

  triggerClientNoBankError = (clientInfo) => {
    this.setState({ clientMsg: ClientNoBankInfoMsg })
    error({
      title: `Participant: ${clientInfo.first_name} ${clientInfo.last_name} - No Banking Details`,
      content: (
        <div>
          <p>Please furnish banking details for this participant for reimbursement.</p>
          <p>Click <strong>Go to Participant</strong> to be redirected to the participant's profile.</p>
        </div>
      ),
      okText: 'Go to Participant',
      onOk() {
        if (window) {
          window.open(`/participants/${clientInfo.ref_id}/info`)
        }
      },
    })
  }

  triggerClientNoRmtEmailError = (clientInfo) => {
    this.setState({ clientMsg: ClientNoRmtEmailMsg })
    error({
      title: `Participant: ${clientInfo.first_name} ${clientInfo.last_name} - No Remittance Email and Recipient`,
      content: (
        <div>
          <p>Please furnish remittance email and recipient for this participant for reimbursement.</p>
          <p>Click <strong>Go to Participant</strong> to be redirected to the participant's profile.</p>
        </div>
      ),
      okText: 'Go to Participant',
      onOk() {
        if (window) {
          window.open(`/participants/${clientInfo.ref_id}/info`)
        }
      },
    })
  }

  triggerSBExpireWarning = (isMulti = false) => {
    warning({
      title: `Service Booking Ended`,
      content: (
        <div>
          {isMulti ? SBOver60daysMultiMsg : SBOver60daysMsg}
        </div>
      ),
      okText: 'OK',
      onOk() { },
    })
  }

  triggerSBInvalidWarning = (isMulti = false) => {
    error({
      title: `Service Booking Ended`,
      content: (
        <div>
          {isMulti ? SBOver90daysMultiMsg : SBOver90daysMsg}
        </div>
      ),
      okText: 'OK',
      onOk() { },
    })
  }

  handleInvTypeChange = (e, isDrafted) => {
    const value = e.target.value

    this.setState({ invType: value }, () => {
      if (this.isEdit() && isDrafted || !this.isEdit()) {
        let isNoBank = false
        if (value !== InvoiceType.INV_TYPE_RMB) {
          const providerList = this.state.providerList
          const item = this.state.item

          const p = providerList.find(e => e.id === item.provider_id)

          this.setState({
            providerInfo: p && p.id ? p : {},
            clientMsg: ''
          })
        } else {
          let isNoBank = false
          let isNoRmtEmail = false
          if (value === InvoiceType.INV_TYPE_RMB) {
            const { clientInfo } = this.state

            if (clientInfo && clientInfo.id) {
              if (!(clientInfo.pm_bank_acc_no && clientInfo.pm_bank_code && clientInfo.pm_bsb)) {
                isNoBank = true
                this.triggerClientNoBankError(clientInfo)
              } else if (!(clientInfo.pm_rmt_email && clientInfo.pm_rmt_email_name)) {
                isNoRmtEmail = true
                this.triggerClientNoRmtEmailError(clientInfo)
              }
            }
          }

          this.setState({ providerInfo: {}, clientMsg: isNoBank && isNoRmtEmail ? `${ClientNoBankInfoMsg} ${ClientNoRmtEmailMsg}` : isNoBank ? ClientNoBankInfoMsg : isNoRmtEmail ? ClientNoRmtEmailMsg : '' })
        }
      }
    })
  }

  handleProviderChange = async (providerId) => {
    const { form } = this.props
    const id = this.getId()
    const providers = await providerService.get(providerId)
    let showPrivateAlertProvider = false

    this.startSelectProviderModal(false, async () => {
      const { providerList } = this.state
      const provider = providerList.find(e => e.id === parseInt(providerId))
      this.setState({
        providerInfo: provider && provider.id ? provider : {},
        providerMsg: provider && provider.id ? '' : SelectProviderMsg
      }, () => {
        const invNumber = form.getFieldValue('invoice_number')
        if (invNumber !== null && invNumber !== undefined) {
          this.debounceValidateInvoiceNumber(id, invNumber)
        }
      })

      if (providers && providers.id) {
        if (providers.private_alert) {
          showPrivateAlertProvider = true
          this.triggerProviderWarning(providers)
        }
      }

      this.setState({
        showPrivateAlertProvider
      })
    })
  }

  triggerProviderWarning = (providerInfo) => {
    warning({
      title: `Provider: ${providerInfo.fullname}`,
      content: (
        <div>
          {providerInfo.public_alert
            ? <div dangerouslySetInnerHTML={{ __html: this.messageHTMLReplace(providerInfo.public_alert) }} />
            : null}
          {providerInfo.private_alert
            ? <div dangerouslySetInnerHTML={{ __html: this.messageHTMLReplace(providerInfo.private_alert) }} />
            : null}
        </div>
      ),
      okText: 'OK',
      onOk() { },
    })
  }

  handleTabChange = (index) => {
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({ currentTab: index })
    if (tab && tab.tabId) {
      this.props.history.replace(`${urlRedirect}/${refId}${tab.path}`)
      if (tab.tabId === 1) {
        this.fetchInvoice(false)
      }
    }
  }

  toggleAllowABNExempted = (e) => {
    const { form } = this.props
    this.setState({ isAllowABNExempted: e }, () => {
      setTimeout(() => {
        form.validateFieldsAndScroll(['is_invoice_abn_exempted'])
      }, 1000)
    })
  }

  handleInvoiceDateChange = async (date, isUpdate = true) => {
    const { clientBudget = [], invoiceItems } = this.state
    const { form } = this.props

    if (validator.isNotEmptyArray(clientBudget)) {
      this.handleUpdateCurrentBudget(date, clientBudget)
    }

    setTimeout(() => {
      let fields = []
      for (let i = 0; i < invoiceItems.length; i++) {
        const invItem = invoiceItems[i]
        fields.push(`inv_date${i}`)
        fields.push(`inv_end_date${i}`)

        if (isUpdate) {
          this.onInvItemDateChange(invItem['inv_date'], i, 'invoice_date')
        }
      }

      form.validateFieldsAndScroll(fields, (error, values) => { })
    }, 500)
  }

  handleUpdateCurrentBudget = async (date, budget) => {
    const currentBudget = budget.find(e => {
      // need to set to startOf / endOf day to make sure the range function is working and moment within period is detected correctly
      const currDate = moment.isMoment(date) ? date.clone().startOf('day') : moment(date).startOf('day')
      const planStart = moment(e.period_start_date).startOf('day')
      const planEnd = moment(e.period_end_date).endOf('day')

      if (planStart.isBefore(date) && planEnd.isAfter(date)) {
        return true
      }
      return false
    })

    if (this.isEdit()) {
      if (currentBudget && currentBudget.budget_id) {
        this.setState({
          clientCurrentBudget: currentBudget
        })
      }
    } else {
      const { clientInfo, providerInfo } = this.state
      if (currentBudget && currentBudget.budget_id) {
        let items = []
        items.push(Object.assign({}, DefaultInvoiceItem, {
          key: formatter.getHash(8),
          client_id: clientInfo && clientInfo.id ? clientInfo.id : null,
          provider_id: providerInfo && providerInfo.id ? providerInfo.id : null
        }))
        this.setState({
          clientCurrentBudget: currentBudget,
          invoiceItems: items,
          invoiceMsg: ''
        })
      } else {
        let items = []
        items.push(Object.assign({}, DefaultInvoiceItem, {
          key: formatter.getHash(8),
          client_id: clientInfo && clientInfo.id ? clientInfo.id : null,
          provider_id: providerInfo && providerInfo.id ? providerInfo.id : null
        }))
        this.setState({
          clientCurrentBudget: {},
          invoiceItems: items,
          invoiceMsg: ''
        })
      }
    }

  }

  /** handle save / update / delete */
  handleDeleteModal = (showInvDeleteModal) => {
    const { item } = this.state

    if (item.id && item.is_allow_edit) {
      this.setState({ showInvDeleteModal })
    } else {
      this.setState({ showInvDeleteModal: false })
      warning({
        title: 'Unable to Delete Invoice',
        content: 'The invoice is unable to delete anymore.'
      })
    }
  }

  handleDelete = () => {
    const { onDeleteInvoice } = this
    const { form } = this.props
    const { validateFields } = form
    const { item } = this.state

    if (item.id && item.is_allow_edit) {
      validateFields(['delete_reason_type', 'delete_reason_note'], async (errors, values) => {
        if (!errors) {
          confirm({
            title: 'Proceed to delete invoice?',
            content: (
              <div style={{ color: 'rgb(238, 27, 27)' }}>
                <p>About to delete this invoice. Are you sure?</p>
                <p>This action is irreversible.</p>
              </div>
            ),
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk() {
              // eslint-disable-next-line no-lone-blocks
              onDeleteInvoice(values, item)
            },
            onCancel() {
            }
          })
        }
      })
    } else {
      warning({
        title: 'Unable to Delete Invoice',
        content: 'The invoice is unable to delete anymore.'
      })
    }
  }

  onDeleteInvoice = async (values, item) => {
    this.setState({ loadingSave: true, loadingHidden: true })
    const isSdbInvoice = !!item.is_sdb_invoice

    try {
      const r = await invoiceService.remove(item.id)

      if (r && r.id) {
        let logText = 'Invoice is deleted. '
        logText += `Delete Reason Type: ${values['delete_reason_type']}, Notes: ${values['delete_reason_note']}`

        notify.success('Deleted successfully', 'Invoice deleted successfully.')
        log.deleteInvoice(item.id, logText)

        window.location.replace(`${urlRedirect}-list${isSdbInvoice ? '/std' : '/pm'}`)
        fetchingInvoices(true)

        this.handleDeleteModal(false)
      } else {
        notify.error('Unable to delete successfully', 'Unable to delete invoice successfully. Please try again later.')
      }
    } catch (e) {
      console.log('delete invoice error', e)
      notify.error('Unable to delete successfully', 'Unable to delete invoice successfully. Please try again later.')
    }

    this.setState({ loadingSave: false, loadingHidden: false })
  }

  handleEditButton = () => {
    this.setState({ showEdit: false, showSave: true })
  }

  validateClientCredit = () => {
    const { clientCredit, clientBudget, invoiceItems } = this.state

    let newCurrentCredit = []
    for (let i = 0; i < invoiceItems.length; i++) {
      const { booking_number: bookingNo, cats: categories = [], inv_date: invDate, cat_id: catId } = invoiceItems[i]

      if (invDate && catId) {
        const mInvDate = moment.isMoment(invDate) ? invDate.clone().startOf('day') : moment(invDate).startOf('day')
        const activeBudget = clientBudget.find(e => {
          const startDate = moment(e.period_start_date).startOf('day')
          const endDate = moment(e.period_end_date).endOf('day')
          if (startDate.isBefore(mInvDate) && endDate.isAfter(mInvDate)) {
            const budgetCats = e.categories

            // add finding on categories to identify correct budget to apply when the client has more than one budget at the same time
            if (validator.isNotEmptyArray(budgetCats)) {
              if (budgetCats.find(f => f.cat_id === catId && f.booking_number === bookingNo)) {
                return true
              } else if (budgetCats.find(f => f.cat_id === catId)) {
                return true
              }
            }
          }
          return false
        })
        const catt = categories.find(e => e.cat_id === catId)

        if (activeBudget && catt) {
          const c = clientCredit.filter(e => e.budget_id === activeBudget.budget_id && e.report_group_id === catt.report_group_id)

          if (validator.isNotEmptyArray(c)) {
            // check repeat entry for newCurrentCredit
            for (let j = 0; j < c.length; j++) {
              const ca = c[j]
              // different with filter from client credit with report_group_id, at here need to find the repeated category id in order to correctly include all categories credits being included
              const cb = newCurrentCredit.find(e => ca.budget_id === e.budget_id && ca.cat_id === e.cat_id)

              if (!cb) {
                newCurrentCredit.push(ca)
              }
            }
          }
        }
      }
    }

    this.setState({ clientCurrentCredit: newCurrentCredit }, () => this.validateClientCreditBalance())
  }

  validateClientCreditBalance = () => {
    // applied_credit will only appear when the job is created (i.e. !this.isEdit()) because no inv item and credit will be applied after saved
    // when it is edited state the credit will be applied and deducted from credit immediately, so no temp remaining amount i.e. estimated_remaining_amount value required in edit mode.
    // this function will be still called in edited mode anyway.
    const { clientCurrentCredit, invoiceItems } = this.state

    let appliedAmount = 0

    for (let i = 0; i < invoiceItems.length; i++) {
      const invItem = invoiceItems[i]

      if (invItem.applied_credit && invItem.applied_credit.credit_id) {
        const creditIdx = clientCurrentCredit.findIndex(e => e.id === invItem.applied_credit.credit_id)

        if (creditIdx > -1) {
          const credit = clientCurrentCredit[creditIdx]

          // credit.used_amount = (credit.used_amount === undefined ? 0 : parseFloat(credit.used_amount)) + parseFloat(invItem.applied_credit.amount)
          credit.used_amount = formatter.toBigNumber(formatter.toBigNumber(credit.used_amount).isNaN() ? 0 : credit.used_amount).plus(invItem.applied_credit.amount).toFixed(2)
        }
      }
    }

    for (let i = 0; i < clientCurrentCredit.length; i++) {
      // clientCurrentCredit[i].estimated_remaining_amount = parseFloat(clientCurrentCredit[i].remaining_amount) - (clientCurrentCredit[i].used_amount === undefined ? 0 : clientCurrentCredit[i].used_amount)
      clientCurrentCredit[i].estimated_remaining_amount = formatter.toBigNumber(clientCurrentCredit[i].remaining_amount).minus(formatter.toBigNumber(clientCurrentCredit[i].used_amount).isNaN() ? 0 : clientCurrentCredit[i].used_amount).toFixed(2)
    }

    this.setState({ clientCurrentCredit })
  }

  handlePasteABN = (event) => {
    const { form } = this.props
    event.preventDefault()
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')

    if (pastedData) {
      const cleanedData = pastedData.replace(/\D+/g, '')
      form.setFieldsValue({ invoice_abn: cleanedData })
      form.validateFieldsAndScroll(['invoice_abn']).catch(console.log)
    }
  }

  validateABN = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
      callback(new Error('Please enter ABN'))
    } else if (!validator.isDigit(value) || value.length !== 11) {
      callback(new Error('ABN is invalid in format'))
    } else {
      callback()
    }
  }

  validateInvoiceProviderABN = (e) => {
    e.persist()

    const { form } = this.props
    const id = this.isEdit() ? this.getId() : null

    const value = e.target.value ? e.target.value.trim() : e.target.value
    form.setFieldsValue({ invoice_abn: value })
    const invNumber = form.getFieldValue('invoice_number')

    if (invNumber !== null && invNumber !== undefined) {
      this.debounceValidateInvoiceNumber(id, invNumber)
    }
  }

  validateInvoiceNumber = (e) => {
    e.persist()

    const { form } = this.props
    const { invType, providerInfo } = this.state
    const id = this.isEdit() ? this.getId() : null

    const value = e.target.value ? e.target.value.trim() : e.target.value

    const providerABN = form.getFieldValue('invoice_abn')

    // must check the invoice type to have better prediction.
    // provider info and inv abn on change function no need to check inv type becuase they will only trigger when layout UI is available
    if ((invType === InvoiceType.INV_TYPE_PM && providerInfo && providerInfo.id) ||
      (invType === InvoiceType.INV_TYPE_RMB && providerABN !== null && providerABN !== undefined)) {
      this.debounceValidateInvoiceNumber(id, value)
    }
  }

  validateInvoiceNumberEntry = (rule, value, callback) => {
    const { isInvNoDuplicated } = this.state
    if (isInvNoDuplicated) {
      callback(new Error(InvDuplicatedMsg))
    } else {
      callback()
    }
  }

  debounceValidateInvoiceNumber = async (id, value) => {
    const { form } = this.props
    const { isInvNoDuplicated, providerInfo, invType } = this.state
    const data = {
      id,
      invoice_number: value
    }

    if (invType === InvoiceType.INV_TYPE_PM) {
      if (providerInfo && providerInfo.id) {
        data.invabn = providerInfo.abn
      } else {
        form.setFields({ invoice_number: { value: value, errors: [{ message: InvDuplicatedProviderMissingMsg }] } })

        return
      }
    } else if (invType === InvoiceType.INV_TYPE_RMB) {
      const invABN = form.getFieldValue('invoice_abn')

      if (invABN) {
        data.invabn = invABN
      } else {
        form.setFields({ invoice_number: { value: value, errors: [{ message: InvDuplicatedABNMissingMsg }] } })

        return
      }
    }

    if (value !== '') {
      // set the field value first and disabled all duplicated flags to temporary remove error alert
      form.setFields({ invoice_number: { value: value } })
      this.setState({ loadingCheckInvoiceNo: true, isInvNoDuplicated: isInvNoDuplicated !== false ? undefined : false })

      const r = await commonService.checkDuplicate('invoice', data)

      if (r && r.errors) {
        // if error (duplicate detected), flag is true and set error message on input. meanwhile, the validate function will set the input as errorneous input
        this.setState({ loadingCheckInvoiceNo: false, isInvNoDuplicated: true })
        const w = window

        confirm({
          title: 'Possible Duplicated Invoice',
          content: (
            <div style={{ color: 'rgb(238, 27, 27)' }}>
              <p>There is an invoice which has the same invoice number with entered value.</p>
              <p>Press "Go to" button to check the duplicated invoice or press "OK" to edit.</p>
            </div>
          ),
          okText: 'Go to Duplicated Invoice Page',
          cancelText: 'OK',
          onOk() {
            w.open(`${urlRedirect}/${r.ref_id}/info`)
          },
          onCancel() {
          }
        })

        // set the field value with errors
        form.setFields({ invoice_number: { value: value, errors: [{ message: InvDuplicatedMsg }] } })
      } else {
        this.setState({ loadingCheckInvoiceNo: false, isInvNoDuplicated: false })
      }
    }
  }

  validateTargetedInvAmount = (rule, value, callback) => {
    const { form } = this.props
    const targetedSubtotal = form.getFieldValue('targeted_subtotal')
    // if (value === null || value === undefined || value === '' || value === 0) {
    if (value === null || value === undefined || value === '' || formatter.toBigNumber(value).isEqualTo(0)) {
      callback(new Error(`Expected Invoiced Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Expected Invoiced Amount is not number or decimal format`))
      } else {
        callback()
      }
    }
  }

  onTargetedInvAmountChange = (e) => {
    const value = e && e.target ? e.target.value : e
    const { invoiceItems, item } = this.state
    if (invoiceItems.length === 0) return

    // if (formatter.toPriceFloat(value) !== formatter.toPriceFloat(item.subtotal)) {
    if (!formatter.toBigNumber(value).isEqualTo(item.subtotal)) {
      // const diff = `${formatter.toPrice((value ? parseFloat(formatter.toPriceFloat(value)) : 0) - (item.subtotal ? parseFloat(formatter.toPriceFloat(item.subtotal)) : 0))}`
      const diff = `${formatter.toPrice(formatter.toBigNumber(formatter.toBigNumber(value).isNaN() ? 0 : formatter.toBigNumber(value)).minus(formatter.toBigNumber(item.subtotal).isNaN() ? 0 : formatter.toBigNumber(item.subtotal)))}`
      this.setState({ invSubtotalMsg: `${diff} ${TotalAmountMismatchMsg2}` })
    } else {
      this.setState({ invSubtotalMsg: '' })
    }
  }

  handlePasteAmt = (key) => (event) => {
    event.preventDefault()
    const { form } = this.props
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')

    if (pastedData) {
      const cleanedData = pastedData.replace(/[^0-9,.]/g, '')
      form.setFieldsValue({ [key]: cleanedData })
      form.validateFieldsAndScroll([key]).catch(console.log)
    }
  }

  handleSave = async (saveType = InvoiceSaveType.INV_SAVETYPE_SAVE) => {
    const { onSave } = this
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { clientInfo, clientMsg, providerInfo, invType, item, invoiceItems, loading, loadingHidden, loadingCheckInvoiceNo, statusList } = this.state

    let clientNewMsg = ''
    let providerMsg = ''
    let invoiceMsg = ''
    let isPass = true
    let isNoBank = clientMsg === ClientNoBankInfoMsg

    if (loading || loadingHidden || loadingCheckInvoiceNo) return

    const isSaveInv = saveType === InvoiceSaveType.INV_SAVETYPE_SAVE
    const isDraftInv = saveType === InvoiceSaveType.INV_SAVETYPE_DRAFTED
    const isCancelInv = saveType === InvoiceSaveType.INV_SAVETYPE_CANCELLED
    const isRejectInv = saveType === InvoiceSaveType.INV_SAVETYPE_REJECTED

    const isItemDisabled = statusList.find(e => {
      if (e.reference >= '005' && e.reference <= '009') {
        return item.status === e.value
      }

      return false
    })

    const isRunning = statusList.find(e => {
      if (e.reference >= '002' && e.reference <= '009') {
        return item.status === e.value
      }

      return false
    })

    const isProcessing = statusList.find(e => {
      if (e.reference === '002') {
        return item.status === e.value
      }

      return false
    })

    const isClosed = statusList.find(e => {
      if (e.reference === '009') {
        return item.status === e.value
      }

      return false
    })

    const isRejected = statusList.find(e => {
      if (e.reference === '010') {
        return item.status === e.value
      }

      return false
    })

    const isDrafted = statusList.find(e => {
      if (e.reference === '001') {
        return item.status === e.value
      }

      return false
    })

    if (!clientInfo.id) {
      clientNewMsg = SelectClientMsg
      isPass = false
    }

    if (!providerInfo.id && invType === InvoiceType.INV_TYPE_PM) {
      providerMsg = SelectProviderMsg
      isPass = false
    }

    if (isSaveInv && !validator.isNotEmptyArray(invoiceItems)) {
      invoiceMsg = SelectInvoiceMsg
      isPass = false
    }

    const id = this.getId()

    validateFieldsAndScroll(async (errors, values) => {
      if (!isPass) {
        this.setState({ clientMsg: isNoBank ? clientMsg : clientNewMsg, providerMsg, invoiceMsg })
      }

      // only check errors if the save type is Save and Drafted, always allow action for rejected and cancelled action
      if ((!errors && (isSaveInv || isDraftInv)) || (isCancelInv || isRejectInv)) {
        if (!isPass) {
          return
        }

        values.invoice_type = invType.value
        values.save_type = saveType

        if (isItemDisabled && isSaveInv) {
          this.onSave(values)
        } else {
          /**
           * condition to show modal
           * 1. expected inv amt > subtotal
           * 2. any of invoice item max rate is lesser than invoice amount
           * 3. no auth amount > subtotal
           */
          let section = {
            isPrivateAlert: false,
            isPrivateAlertData: {},
            isInvRateMaxRate: false,
            isInvRateMaxRateData: [],
            isInvRateMaxRateInfoText: '',
            isInvSubtotal: false,
            isInvSubtotalData: {},
            isInvSubtotalInfoText: '',
            isAutoAuth: false,
            isAutoAuthData: {},
            isAutoAuthInfoText: '',
            isInvItemOverBudget: false,
            isInvItemOverBudgetData: [],
            isInvItemOverBudgetInfoText: '',
            isInvItemSBOverDue: false,
            isInvItemSBOverDue60Days: false,
            isInvItemSBOverDue90Days: false,
            // inv status
            isReimbursement: invType === InvoiceType.INV_TYPE_RMB && (!this.isEdit() || isDrafted || isRejected),
            isLogWarningRequired: false
          }
          let isTriggerConfirm = !isSaveInv

          if (clientInfo && clientInfo.private_alert) {
            isTriggerConfirm = true
            section.isPrivateAlert = true
            section.isPrivateAlertData = {
              client_first_name: clientInfo.first_name,
              client_last_name: clientInfo.last_name,
              private_alert: clientInfo.private_alert
            }
          }

          if (clientInfo.pm_is_auth_req === false) {
            // if (parseFloat(item.subtotal) < parseFloat(clientInfo.pm_auth_amount)) {
            if (formatter.toBigNumber(item.subtotal).isLessThan(clientInfo.pm_auth_amount)) {
              isTriggerConfirm = true
              section.isAutoAuth = true
              section.isAutoAuthData = {
                // subtotal: formatter.toPriceFloat(item.subtotal),
                // auth_amount: formatter.toPriceFloat(clientInfo.pm_auth_amount),
                subtotal: formatter.toBigNumber(item.subtotal).toFixed(2),
                auth_amount: formatter.toBigNumber(clientInfo.pm_auth_amount).toFixed(2),
                client_first_name: clientInfo.first_name,
                client_last_name: clientInfo.last_name
              }
              section.isAutoAuthInfoText = `This invoice will be automatically authorised due to the subtotal of invoice (${section.isAutoAuthData.subtotal}) is lower than participant ${section.isAutoAuthData.client_first_name} ${section.isAutoAuthData.client_last_name}'s maximum Authorisation Amount (${formatter.toPrice(section.isAutoAuthData.auth_amount)})`
            }
          }

          // if (formatter.toPriceFloat(values.targeted_subtotal) !== formatter.toPriceFloat(item.subtotal)) {
          if (!formatter.toBigNumber(values.targeted_subtotal).isEqualTo(item.subtotal)) {
            // const subtotal = formatter.toPriceFloat(item.subtotal)
            // const targetedSubtotal = formatter.toPriceFloat(values.targeted_subtotal)
            // const diff = Math.abs(subtotal - targetedSubtotal)
            const subtotal = formatter.toBigNumber(item.subtotal).toFixed(2)
            const targetedSubtotal = formatter.toBigNumber(values.targeted_subtotal).toFixed(2)
            const diff = formatter.toBigNumber(subtotal).minus(targetedSubtotal).absoluteValue().toFixed(2)
            isTriggerConfirm = true
            section.isInvSubtotal = true
            section.isInvSubtotalData = {
              subtotal: formatter.toPrice(subtotal),
              targeted_subtotal: formatter.toPrice(targetedSubtotal),
              diff_subtotal: formatter.toPrice(diff)
            }
            section.isInvSubtotalInfoText = `${section.isInvSubtotalData.targeted_subtotal} Expected Invoiced Amount\n${section.isInvSubtotalData.subtotal} Total Invoiced Amount\n${section.isInvSubtotalData.diff_subtotal} DIFFERENCE`
          }

          if (validator.isNotEmptyArray(invoiceItems)) {
            for (let i = 0; i < invoiceItems.length; i++) {
              const invItem = invoiceItems[i]
              // const maxRate = (invItem.max_rate === null || invItem.max_rate === undefined) ? 0 : parseFloat(invItem.max_rate)
              const maxRate = formatter.toBigNumber(formatter.toBigNumber(invItem.max_rate).isNaN() ? 0 : invItem.max_rate)
              const invRate = formatter.toBigNumber(values[`inv_rate${i}`])

              const { cats = [], catItems = [], subtotal } = invItem
              const catId = values[`cat_id${i}`] || invItem.cat_id
              const cat = cats.find(e => e.cat_id === catId)
              const catItemId = values[`cat_item_id${i}`] || invItem.cat_item_id
              const catItem = catItems.find(e => e.cat_item_id === catItemId)

              // if (maxRate !== null && parseFloat(maxRate) > 0 && parseFloat(maxRate) < parseFloat(invRate)) {
              if (maxRate.isGreaterThan(0) && maxRate.isLessThan(invRate)) {
                isTriggerConfirm = true
                section.isInvRateMaxRate = true
                const data = {
                  inv_date: values[`inv_date${i}`] || invItem.inv_date,
                  cat_name: cat ? cat.cat_name : invItem.cat_name,
                  cat_identifier: cat ? cat.cat_identifier : invItem.cat_identifier,
                  cat_item_name: catItem ? catItem.cat_item_name : invItem.cat_item_name,
                  cat_item_identifier: catItem ? catItem.cat_item_identifier : invItem.cat_item_identifier,
                  // max_rate: formatter.toPriceFloat(maxRate),
                  // inv_rate: formatter.toPriceFloat(invRate),
                  max_rate: maxRate.toFixed(2),
                  inv_rate: invRate.toFixed(2),
                }
                section.isInvRateMaxRateData.push(data)

                section.isInvRateMaxRateInfoText += `- ${formatter.toShortDate(data.inv_date)} => ${data.cat_item_name} (${data.cat_item_identifier}): ${data.inv_rate} (max rate: ${formatter.toPrice(data.max_rate)})`
              }

              // const budgetOver = this.onInvItemValidateBudget(invItem, true)
              const budgetOver = !!invItem.is_amt_over_budget

              if (budgetOver) {
                isTriggerConfirm = true
                section.isInvItemOverBudget = true
                const data = {
                  ...invItem.amt_cp,
                  cat_name: cat ? cat.cat_name : invItem.cat_name,
                  cat_identifier: cat ? cat.cat_identifier : invItem.cat_identifier,
                  cat_item_name: catItem ? catItem.cat_item_name : invItem.cat_item_name,
                  cat_item_identifier: catItem ? catItem.cat_item_identifier : invItem.cat_item_identifier,
                  inv_date: values[`inv_date${i}`] || invItem.inv_date,
                  subtotal: formatter.toPriceFloat(subtotal)
                }
                section.isInvItemOverBudgetData.push(data)
                section.isInvItemOverBudgetInfoText += `- ${data.cat_item_name} (${data.cat_item_identifier}): Remaining value from budget "${data.booking_number}" (${data.cat_name}): ${formatter.toPrice(data.remaining_forecast_value_excl)}, Invoiced Amount: ${formatter.toPrice(data.subtotal)}\n`
              }

              if (!isClosed) {
                if (invItem.is_sb_period || invItem.is_sb_periods) {
                  isTriggerConfirm = true
                  section.isInvItemSBOverDue = true
                }

                if (invItem.is_sb_period1 || invItem.is_sb_periods1) {
                  isTriggerConfirm = true
                  section.isInvItemSBOverDue60Days = true
                }

                if (invItem.is_sb_period2 || invItem.is_sb_periods2) {
                  isTriggerConfirm = true
                  section.isInvItemSBOverDue90Days = true
                }
              }
            }

            if (section.isInvItemOverBudgetInfoText) {
              section.isInvItemOverBudgetInfoText = `The following invoice item${section.isInvItemOverBudgetData.length === 1 ? ' has' : 's have'} exceeded the budget of service booking:\n\n` + section.isInvItemOverBudgetInfoText
            }

            if (section.isInvRateMaxRateInfoText) {
              section.isInvRateMaxInfoText = `The following invoice item${section.isInvRateMaxRateData.length === 1 ? ' has' : 's have'} higher invoice rate than item number max rate:\n\n` + section.isInvRateMaxRateInfoText
            }
          }

          const actionTitle = isSaveInv
            ? (
              isRunning
                ? `Save Invoice`
                : `${this.isEdit() ? 'Process' : 'Proceed To Create and Process'} this Invoice?`
            )
            : isDraftInv
              ? (
                isDrafted
                  ? `Update draft`
                  : `Proceed to Save as Draft?`
              )
              : isRejectInv
                ? `Proceed to Reject the Invoice?`
                : isCancelInv
                  ? `Proceed to Cancel the Invoice?`
                  : ''

          const actionBodySave = (
            <div>
              <p>Check the following issues before proceed.</p>
              {section.isReimbursement
                ? <div>
                  <p style={{ color: '#ee1b1b', fontWeight: '800' }}>Reimbursement invoice will be authorised immediately once created and saved. Most of the details are unable to edit after authorised unless the invoice is rejected.</p>
                  <p style={{ color: '#ee1b1b', fontWeight: '800' }}>Please review all the details before save & proceed.</p>
                </div>
                : null}

              {section.isInvItemSBOverDue90Days
                ? <div>
                  <p style={{ color: '#ee1b1b', fontWeight: '800', fontSize: '14px' }}>The Service Booking for this invoice has passed 90 days of SB's end date. The claim may not be successful even it is submitted.</p>
                </div>
                : section.isInvItemSBOverDue60Days
                  ? <div>
                    <p style={{ color: '#ee1b1b', fontWeight: '800', fontSize: '14px' }}>The Service Booking for this invoice has passed 60 days of SB's end date.</p>
                  </div>
                  : section.isInvItemSBOverDue
                    ? <div>
                      <p style={{ color: '#ee1b1b', fontWeight: '800', fontSize: '14px' }}>The Service Booking for this invoice has ended.</p>
                    </div>
                    : null}

              <ul>
                {section.isPrivateAlert
                  ? <li>
                    <p style={{ color: '#D66E00', fontWeight: '700', marginBottom: '5px' }}>Participant: {section.isPrivateAlertData.client_first_name} {section.isPrivateAlertData.client_last_name}</p>
                    <p style={{ color: '#D66E00' }}>{this.messageHTMLReplace(section.isPrivateAlertData.private_alert)}</p>
                  </li>
                  : null}
                {section.isInvItemOverBudget
                  ? <li>
                    <p>The following invoice item{section.isInvItemOverBudgetData.length === 1 ? ' has' : 's have'} exceeded the budget of service booking:</p>
                    <ol>
                      {section.isInvItemOverBudgetData.map((e, idx) => (
                        <li key={`bdgts${idx}`}>
                          <p style={{ color: '#ee1b1b', fontWeight: '800' }}>
                            {e.cat_item_name} ({e.cat_item_identifier}):
                          </p>
                          <p style={{ color: '#ee1b1b', fontWeight: '800' }}>
                            Remaining value from budget "{e.booking_number}" ({e.cat_name}): {formatter.toPrice(e.remaining_forecast_value_excl)}, Invoiced Amount: {formatter.toPrice(e.subtotal)}
                          </p>
                        </li>
                      ))}
                    </ol>
                  </li>
                  : null}
                {section.isAutoAuth && !isProcessing
                  ? <li>
                    <p style={{ color: '#D66E00' }}>This invoice will be automatically authorised due to the subtotal of invoice (${section.isAutoAuthData.subtotal}) is lower than participant <strong>{section.isAutoAuthData.client_first_name} {section.isAutoAuthData.client_last_name}</strong>'s maximum Authorisation Amount ({formatter.toPrice(section.isAutoAuthData.auth_amount)})</p>
                  </li>
                  : null}

                {section.isInvSubtotal
                  ? <li style={{ color: '#ee1b1b' }}>
                    <div>
                      {section.isInvSubtotalData.targeted_subtotal} Expected Invoiced Amount<br />
                      {section.isInvSubtotalData.subtotal} Total Invoiced Amount<br />
                      {section.isInvSubtotalData.diff_subtotal} DIFFERENCE
                    </div>
                  </li>
                  : null}

                {section.isInvRateMaxRate
                  ? <li>
                    <p>The following invoice item{section.isInvRateMaxRateData.length === 1 ? ' has' : 's have'} higher invoice rate than item number max rate:</p>
                    <ol>
                      {section.isInvRateMaxRateData.map((e, idx) => (
                        <li key={`mxrt${idx}`}>
                          <p>{formatter.toShortDate(e.inv_date)}</p>
                          <p style={{ fontWeight: '800' }}>{e.cat_item_name} ({e.cat_item_identifier}): </p>
                          <p><span style={{ color: '#ee1b1b' }}>${e.inv_rate}</span><span> (max rate: {formatter.toPrice(e.max_rate)})</span></p>
                        </li>
                      ))}
                    </ol>
                  </li>
                  : null}
              </ul>
              {section.isInvItemOverBudget
                ? <p>Please resolve the issue(s) before save.</p>
                : <p>Please read the issues above and confirm your action to proceed.</p>}
            </div>
          )

          const excludeMsg = <p style={{ color: '#ee1b1b', fontStyle: 'italic' }}>* This invoice will be excluded from comitted transactions under the Service Budget. If your Service Budget does not have enough balance to accommondate the amount of this invoice, you are unable to process this invoice in later time. *</p>

          const noSaveMsg = <p style={{ color: '#ee1b1b', fontWeight: '900' }}>All updates made will not be saved.</p>

          const actionBodyDrafted = (
            <div>
              {isDrafted
                ? null
                : this.isEdit()
                  ? <p style={{ color: '#ee1b1b88' }}>You are about to revert this invoice to draft. Proceed?</p>
                  : <p style={{ color: '#ee1b1b88' }}>You are about to save this invoice as a draft. Proceed?</p>}
              {excludeMsg}
            </div>
          )

          const actionBodyRejected = (
            <div>
              <p style={{ color: '#ee1b1b88' }}>You are about to reject this invoice. Proceed?</p>
              {excludeMsg}
              {noSaveMsg}
            </div>
          )

          const actionBodyCancelled = (
            <div>
              <p style={{ color: '#ee1b1b88' }}>You are about to cancel this invoice. Proceed?</p>
              <p style={{ color: '#ee1b1b', fontWeight: 'bold' }}>* You are unable to retrieve or regain any access on this invoice if you choose to cancel this invoice. THIS ACTION IS IRREVERSIBLE!! *</p>
              {noSaveMsg}
            </div>
          )

          const actionBody = isSaveInv
            ? actionBodySave
            : isDraftInv
              ? actionBodyDrafted
              : isRejectInv
                ? actionBodyRejected
                : isCancelInv
                  ? actionBodyCancelled
                  : ''

          if (isTriggerConfirm && isSaveInv) {
            section.isLogWarningRequired = true
          }

          if ((isTriggerConfirm || section.isReimbursement) && actionTitle) {
            confirm({
              title: actionTitle,
              content: actionBody,
              okText: 'Confirm',
              cancelText: 'Cancel',
              okButtonProps: {
                disabled: isSaveInv && section.isInvItemOverBudget === true
              },
              onOk() {
                // eslint-disable-next-line no-lone-blocks
                onSave(values, section)
              },
              onCancel() {
              }
            })
          } else {
            this.onSave(values)
          }
        }
      }
    })
  }

  onSave = async (values, extra) => {
    const { clientInfo, providerInfo, providerList, fileList, invType, item, itemOri, invoiceItems, invoiceItemsOri, loading, loadingSave, loadingHidden, loadingCheckInvoiceNo, statusList } = this.state
    const id = this.getId()

    let invoiceData = {}
    let invoiceItemData = []

    if (loading || loadingSave || loadingHidden || loadingCheckInvoiceNo) return

    this.setState({ loadingSave: true, loadingHidden: true })

    const isItemDisabled = statusList.find(e => {
      if (e.reference >= '005' && e.reference <= '009') {
        return item.status === e.value
      }

      return false
    })

    const saveType = values.save_type
    const isSaveDrafted = saveType === InvoiceSaveType.INV_SAVETYPE_DRAFTED

    // able to support amount entry with comma
    // values.targeted_subtotal = formatter.toPriceFloat(values.targeted_subtotal)
    values.targeted_subtotal = formatter.toBigNumber(values.targeted_subtotal).toFixed(2)

    delete values.processed_at

    if (this.isEdit()) {
      // if invoice is disabled to edit, only comment is allowed to be updated
      invoiceData = isItemDisabled && !isSaveDrafted
        ? {
          invoice_comment: values['invoice_comment'] === undefined ? item.invoice_comment : values['invoice_comment'] === '' ? null : values['invoice_comment'],
          invoice_private_comment: values['invoice_private_comment'] === undefined ? item.invoice_private_comment : values['invoice_private_comment'] === '' ? null : values['invoice_private_comment'],
          invoice_number: values['invoice_number'] === undefined ? item.invoice_number : values['invoice_number'] === '' ? null : values['invoice_number'],
          invoice_abn: values['invoice_abn'] === undefined ? item.invoice_abn : values['invoice_abn'] === '' ? null : values['invoice_abn'],
          invoice_provider_name: values['invoice_provider_name'] === undefined ? item.invoice_provider_name : values['invoice_provider_name'] === '' ? null : values['invoice_provider_name'],
          is_invoice_abn_exempted: values['is_invoice_abn_exempted'] === undefined ? item.is_invoice_abn_exempted : values['is_invoice_abn_exempted'],
        }
        : {
          invoice_date: values['invoice_date'] || item.invoice_date,
          invoice_number: values['invoice_number'] || item.invoice_number,
          invoice_comment: values['invoice_comment'] === undefined ? item.invoice_comment : values['invoice_comment'] === '' ? null : values['invoice_comment'],
          invoice_private_comment: values['invoice_private_comment'] === undefined ? item.invoice_private_comment : values['invoice_private_comment'] === '' ? null : values['invoice_private_comment'],
          targeted_subtotal: values['targeted_subtotal'] === undefined ? item.targeted_subtotal : values['targeted_subtotal'],
          is_allow_targeted_override: extra && extra.isInvSubtotal !== undefined ? extra.isInvSubtotal : item.is_allow_targeted_override || false,
          is_invoice_required_abn: values['invoice_abn'] !== null && values['invoice_abn'] !== undefined,
          invoice_abn: values['invoice_abn'] === undefined ? item.invoice_abn : values['invoice_abn'] === '' ? null : values['invoice_abn'],
          invoice_provider_name: values['invoice_provider_name'] === undefined ? item.invoice_provider_name : values['invoice_provider_name'] === '' ? null : values['invoice_provider_name'],
          is_invoice_abn_exempted: values['is_invoice_abn_exempted'] === undefined ? item.is_invoice_abn_exempted : values['is_invoice_abn_exempted'],
        }

      // allow to change client / provider info if it is in draft
      if (isSaveDrafted) {
        invoiceData.client_id = clientInfo.id
        invoiceData.provider_id = invType.value === InvoiceType.INV_TYPE_RMB.value ? null : providerInfo.id
        invoiceData.invoice_type = invType.value
      }

      for (let i = 0; i < invoiceItems.length; i++) {
        const invItem = invoiceItems[i]
        const { cats = [], catItems = [], applied_credit = {} } = invItem
        const catId = values[`cat_id${i}`] || invItem.cat_id
        const cat = cats.find(e => e.cat_id === catId)
        const catItemId = values[`cat_item_id${i}`] || invItem.cat_item_id
        const catItem = catItems.find(e => e.cat_item_id === catItemId)
        let dat = isItemDisabled && !isSaveDrafted
          ? {
            id: invItem.id,
            comment: values[`comment${i}`] === undefined ? invItem.comment : values[`comment${i}`] === '' ? null : values[`comment${i}`],
            is_closed: values[`is_closed${i}`] || invItem.is_closed
          }
          : {
            id: invItem.id,
            inv_date: values[`inv_date${i}`] || invItem.inv_date,
            inv_end_date: values[`inv_end_date${i}`] || invItem.inv_end_date,
            booking_number: invItem.booking_number ? invItem.booking_number : null,
            budget_id: invItem.budget_id ? invItem.budget_id : invItem.budget_id,
            cat_id: catId,
            cat_name: cat ? cat.cat_name : invItem.cat_name,
            cat_identifier: cat ? cat.cat_identifier : invItem.cat_identifier,
            cat_item_id: catItemId,
            cat_item_name: catItem ? catItem.cat_item_name : invItem.cat_item_name,
            cat_item_identifier: catItem ? catItem.cat_item_identifier : invItem.cat_item_identifier,
            rate_set_id: catItem ? catItem.rate_set_id : invItem.rate_set_id,
            max_rate: invItem.max_rate,
            // unit: formatter.toPriceFloat(values[`unit${i}`]) || invItem.unit,
            // inv_rate: formatter.toPriceFloat(values[`inv_rate${i}`]) || invItem.inv_rate,
            unit: formatter.toBigNumber(values[`unit${i}`] || invItem.unit).toFixed(2),
            inv_rate: formatter.toBigNumber(values[`inv_rate${i}`] || invItem.inv_rate).toFixed(2),
            comment: values[`comment${i}`] === undefined ? invItem.comment : values[`comment${i}`] === '' ? null : values[`comment${i}`],
            is_delete: invItem.is_delete || false,
            applied_credit: applied_credit,
            gst_code: values[`gst_code${i}`] || invItem.gst_code,
            gst_code_name: invItem.gst_code_name
          }
        invoiceItemData.push(dat)
      }
    } else {
      invoiceData = {
        invoice_type: invType.value,
        client_id: clientInfo.id,
        provider_id: invType === InvoiceType.INV_TYPE_RMB ? null : providerInfo.id,
        invoice_date: values['invoice_date'],
        invoice_number: values['invoice_number'],
        invoice_comment: values['invoice_comment'] || null,
        invoice_private_comment: values['invoice_private_comment'] || null,
        targeted_subtotal: values['targeted_subtotal'] || item.targeted_subtotal,
        is_allow_targeted_override: extra && extra.isInvSubtotal === true,
        is_invoice_required_abn: values['invoice_abn'] !== null && values['invoice_abn'] !== undefined,
        invoice_abn: values['invoice_abn'],
        invoice_provider_name: values['invoice_provider_name'],
        is_invoice_abn_exempted: values['is_invoice_abn_exempted'] || false
      }

      for (let i = 0; i < invoiceItems.length; i++) {
        const invItem = invoiceItems[i]
        const { cats = [], catItems = [], applied_credit = {} } = invItem
        const catId = values[`cat_id${i}`]
        const cat = cats.find(e => e.cat_id === catId)
        const catItemId = values[`cat_item_id${i}`]
        const catItem = catItems.find(e => e.cat_item_id === catItemId)

        let dat = {
          inv_date: values[`inv_date${i}`],
          inv_end_date: values[`inv_end_date${i}`],
          booking_number: invItem.booking_number ? invItem.booking_number : null,
          budget_id: invItem.budget_id ? invItem.budget_id : null,
          cat_id: catId,
          cat_name: cat ? cat.cat_name : null,
          cat_identifier: cat ? cat.cat_identifier : null,
          cat_item_id: catItemId,
          cat_item_name: catItem ? catItem.cat_item_name : null,
          cat_item_identifier: catItem ? catItem.cat_item_identifier : null,
          rate_set_id: catItem ? catItem.rate_set_id : null,
          max_rate: invItem.max_rate,
          // unit: formatter.toPriceFloat(values[`unit${i}`]),
          // inv_rate: formatter.toPriceFloat(values[`inv_rate${i}`]),
          unit: formatter.toBigNumber(values[`unit${i}`]).toFixed(2),
          inv_rate: formatter.toBigNumber(values[`inv_rate${i}`]).toFixed(2),
          comment: values[`comment${i}`] || null,
          applied_credit: applied_credit,
          gst_code: values[`gst_code${i}`],
          gst_code_name: invItem.gst_code_name
        }
        invoiceItemData.push(dat)
      }
    }

    invoiceData.items = invoiceItemData
    invoiceData.save_type = saveType

    /**
     * client_id: 1
invoice_comment: null
invoice_date: Moment {_isAMomentObject: true, _isUTC: false, _pf: {…}, _locale: Locale, _d: Sat Mar 20 2021 22:00:00 GMT+0800 (Malaysia Time), …}
invoice_number: "333222111"
is_allow_targeted_override: undefined
items: (2) [{
    budget_id: 2
    cat_id: 1
    cat_identifier: "1"
    cat_item_id: 1
    cat_item_identifier: "01_002_0107_1_1"
    cat_item_name: "Assistance With Self-Care Activities - Standard - Weekday Night"
    cat_name: "Assistance with daily life"
    comment: null
    inv_date: Moment {_isAMomentObject: true, _isUTC: false, _pf: {…}, _locale: Locale, _d: Sat Mar 20 2021 22:00:00 GMT+0800 (Malaysia Time), …}
    inv_rate: 62.17
    max_rate: 62.17
    rate_set_id: 1
    unit: 1
  },
  {
    budget_id: 2
    cat_id: 3
    cat_identifier: "3"
    cat_item_id: 163
    cat_item_identifier: "03_040000111_0103_1_1"
    cat_item_name: "Disability-Related Health Consumables - High Cost"
    cat_name: "Consumables"
    comment: null
    inv_date: Moment {_isAMomentObject: true, _isUTC: false, _pf: {…}, _locale: Locale, _d: Fri Mar 19 2021 22:00:00 GMT+0800 (Malaysia Time), …}
    inv_rate: "15.4"
    max_rate: 0
    rate_set_id: 1
    unit: 1
  }
]
provider_id: 50
targeted_subtotal: "77.57"
     */

    let r = null

    if (this.isEdit()) {
      r = await invoiceService.save(id, invoiceData)
    } else {
      r = await invoiceService.add(invoiceData)
    }
    console.log('r', r)
    if (r && r.id) {
      let saveWarningLogText = ''

      if (extra && extra.isLogWarningRequired) {
        if (extra.isInvItemOverBudgetInfoText) {
          saveWarningLogText += `• Invoice Item(s) exceed the budget of service booking(s):\n${extra.isInvItemOverBudgetInfoText}\n`
        }
        if (extra.isInvRateMaxRateInfoText) {
          saveWarningLogText += `• Invoice Item(s) has higher item rate than maximum rate:\n${extra.isInvRateMaxRateInfoText}\n`
        }
        if (extra.isInvSubtotalInfoText) {
          saveWarningLogText += `• Expected Invoiced Amount and Total Invoice Amount not matched:\n${extra.isInvSubtotalInfoText}\n\n`
        }
        if (extra.isAutoAuthInfoText) {
          saveWarningLogText += `• Invoice Automatically Authorised due to lower Participant's Authorisation Amount:\n${extra.isAutoAuthInfoText}\n\n`
        }

        // pending log text prefix for save warning
        if (saveWarningLogText) {
          saveWarningLogText = `User confirmed processing the invoice despite of issue(s):\n\n${saveWarningLogText}`
        }
      }

      if (!this.isEdit()) {
        // ------ add log message start --------
        let logText = `New ${invType.name}: client: ${clientInfo.first_name} ${clientInfo.last_name}; provider: ${providerInfo.fullname}; invoiced date: ${formatter.toDate(invoiceData.invoice_date, dateFormat)}; invoice number: ${invoiceData.invoice_number}; expected amount: ${invoiceData.targeted_subtotal}${invoiceData.invoice_abn ? `, Invoice ABN: ${invoiceData.invoice_abn}` : ''}${invoiceData.invoice_provider_name ? `, Invoice Provider: ${invoiceData.invoice_provider_name}` : ''}\nitems:\n`

        let itemLogText = ''
        for (let i = 0; i < invoiceData.items.length; i++) {
          const itm = invoiceData.items[i]
          const gst = validator.isNotEmptyArray(itm.gst_list) ? itm.gst_list.find(e => e.item_code === itm.gst_code) : null
          const gstName = gst ? gst.item_name : itm.gst_code ? itm.gst_code.toUpperCase() : ''

          itemLogText = itemLogText + `${i + 1}. ${itm.cat_name} - ${itm.cat_item_name}${itm.booking_number ? ` (${itm.booking_number})` : ''}, serviced started from ${formatter.toDate(itm.inv_date, dateFormat)} and ended on ${formatter.toDate(itm.inv_end_date, dateFormat)}, max rate: ${!itm.max_rate ? 'No limit' : itm.max_rate}, unit: ${itm.unit}, invoiced rate: ${itm.inv_rate}, ${gstName ? `GST Type: ${gstName}, ` : ''}comment: ${itm.comment}; \n`
        }

        logText += itemLogText || 'No invoice items added.'

        if (saveWarningLogText) {
          logText += `\n\n${saveWarningLogText}`
        }

        if (saveType !== InvoiceSaveType.INV_SAVETYPE_SAVE) {
          logText += `\n\n The invoice is ${saveType}.`
        }

        await log.addInvoice(r.id, logText)
        // ------ add log message end --------

        // ------ append file start -------
        if (validator.isNotEmptyArray(fileList)) {
          await this.uploadFiles(invoiceData, r)

          // because the file upload is done after invoice created, so after uploaded the file, auth status must be refreshed.
          // or else if the invoice is directly created and processed, the comm will not trigger correctly
          const r2 = await invoiceService.getAuthStatus(r.id)
          if (r2 && r2.id) {
            r.auth_status = r2.auth_status
          }
        }
        // ------ append file end -------

        notify.success('Saved successfully', 'Invoice saved successfully.')

        // ------ check inv authorisaton start ------
        item.id = r.id
        // const isAuthorisation = await this.validateAuthorisedPay(clientInfo, item, fileList)

        const redirect = () => {
          window.location.replace(`${urlRedirect}/${r.ref_id}/info`)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }

        if (r.auth_status !== undefined) {
          if (r.auth_status === InvoiceAuthType.AUTH_DETAILS_UPDATED) {
            this.showAuthorisedUpdateAlert(() => redirect())
          } else if (r.auth_status === InvoiceAuthType.AUTH_NOT_REQUIRED) {
            await this.showAuthorisedDoneAlert(r.id, () => redirect(), true)
          } else if (r.auth_status === InvoiceAuthType.AUTH_REQUIRED_AND_ALERT) {
            this.showAuthorisedAlert(clientInfo, r.id, () => redirect(), true)
          } else if (r.auth_status === InvoiceAuthType.NO_EMAIL_CONFIGURED) {
            this.showAuthorisedEmailReminder(clientInfo, () => redirect())
          } else if (r.auth_status === InvoiceAuthType.AUTH_ALERT_MISSING_FILES) {
            this.showAuthorisedReminder(clientInfo, () => redirect())
            // } else if (r.auth_status === InvoiceAuthType.AUTH_CREATED_OR_SCHEDULED) {
            //   this.showAuthorisedCreatedAlert(() => redirect())
            // }
          } else {
            redirect()
          }
        } else {
          redirect()
        }
        // ------ check inv authorisaton end ------
      } else {
        // ------ update log message start --------
        let logText = ''
        let itemLogText = ''
        for (let i = 0; i < invoiceData.items.length; i++) {
          const itm = invoiceData.items[i]
          const prevItm = invoiceItemsOri.find(e => e.id === itm.id) || {}

          if (itm.is_delete) {
            itemLogText += `item ${i + 1}: deleted`
          } else {
            const prevItem = isItemDisabled
              ? {
                comment: prevItm.comment,
                is_closed: prevItm.is_closed || false
              }
              : {
                booking_number: prevItm.booking_number,
                category: prevItm.cat_name,
                category_item: prevItm.cat_item_name,
                category_item_identifier: prevItm.cat_item_identifier,
                comment: prevItm.comment,
                serviced_start_date: formatter.toDate(prevItm.inv_date, dateFormat),
                serviced_end_date: formatter.toDate(prevItm.inv_end_date, dateFormat),
                max_rate: !prevItm.max_rate ? 'No Limit' : prevItm.max_rate,
                unit: prevItm.unit,
                invoiced_rate: prevItm.inv_rate,
                is_closed: prevItm.is_closed || false,
                gst_code_name: prevItm.gst_code_name || ''
              }
            const currentitem = isItemDisabled
              ? {
                comment: itm.comment,
                is_closed: itm.is_closed || false
              }
              : {
                booking_number: itm.booking_number,
                category: itm.cat_name,
                category_item: itm.cat_item_name,
                category_item_identifier: itm.cat_item_identifier,
                comment: itm.comment,
                serviced_start_date: formatter.toDate(itm.inv_date, dateFormat),
                serviced_end_date: formatter.toDate(itm.inv_end_date, dateFormat),
                max_rate: !itm.max_rate ? 'No Limit' : itm.max_rate,
                unit: itm.unit,
                invoiced_rate: itm.inv_rate,
                is_closed: itm.is_closed || false,
                gst_code_name: itm.gst_code_name || ''
              }

            let changeText = log.generateItemChanges(
              prevItem,
              currentitem,
              [
                'booking_number',
                'category'
              ],
              [
                { key: 'comment', label: 'Item Notes' },
                { key: 'gst_code_name', label: 'GST Type' }
              ]
            )

            if (prevItem.booking_number !== currentitem.booking_number) {
              changeText += `Support Budget from ${prevItem.category}${prevItem.booking_number ? ` (${prevItem.booking_number})` : ''} to ${currentitem.category}${currentitem.booking_number ? ` (${currentitem.booking_number})` : ''}`
            } else if (prevItem.category !== currentitem.category) {
              changeText += `Support Budget from ${prevItem.category} to ${currentitem.category}`
            }

            if (changeText) {
              itemLogText += `item ${i + 1}: ${changeText}`
            }
          }
        }

        const prevInvType = !isSaveDrafted // if isSaveDrafted, ignore invoice Type update
          ? null
          : itemOri.invoice_type === InvoiceType.INV_TYPE_RMB.value
            ? InvoiceType.INV_TYPE_RMB.name
            : itemOri.invoice_type === InvoiceType.INV_TYPE_PM.value
              ? InvoiceType.INV_TYPE_PM.name
              : itemOri.invoice_type === InvoiceType.INV_TYPE_STD.value
                ? InvoiceType.INV_TYPE_STD.name
                : null

        const currInvType = !isSaveDrafted
          ? null
          : values.invoice_type === InvoiceType.INV_TYPE_RMB.value
            ? InvoiceType.INV_TYPE_RMB.name
            : values.invoice_type === InvoiceType.INV_TYPE_PM.value
              ? InvoiceType.INV_TYPE_PM.name
              : values.invoice_type === InvoiceType.INV_TYPE_STD.value
                ? InvoiceType.INV_TYPE_STD.name
                : null

        const prevProvider = !itemOri.provider_id
          ? 'NULL'
          : providerList.find(e => e.id === itemOri.provider_id)
            ? providerList.find(e => e.id === itemOri.provider_id).fullname
            : `Provider with ID ${itemOri.provider_id}`

        const currProvider = providerInfo && providerInfo.fullname ? providerInfo.fullname : 'NULL'

        const prevInvoice = {
          invoice_type: prevInvType,
          invoiced_date: formatter.toDate(itemOri.invoice_date, dateFormat),
          invoice_number: itemOri.invoice_number,
          expected_amount: itemOri.targeted_subtotal,
          invoice_comment: itemOri.invoice_comment,
          invoice_private_comment: itemOri.invoice_private_comment,
          invoice_abn: itemOri.invoice_abn,
          invoice_provider_name: itemOri.invoice_provider_name,
          is_invoice_abn_exempted: itemOri.is_invoice_abn_exempted || false,
          provider: prevProvider
        }

        const currInvoice = {
          invoice_type: currInvType,
          invoiced_date: formatter.toDate(values.invoice_date, dateFormat),
          invoice_number: values.invoice_number,
          expected_amount: values.targeted_subtotal,
          invoice_comment: values.invoice_comment,
          invoice_private_comment: values.invoice_private_comment,
          invoice_abn: values.invoice_abn,
          invoice_provider_name: values.invoice_provider_name,
          is_invoice_abn_exempted: values.is_invoice_abn_exempted || false,
          provider: currProvider
        }

        const prefixLog = log.updateInvoice(
          r.id,
          prevInvoice,
          currInvoice,
          undefined,
          [
            { key: 'invoice_abn', label: 'Invoice Provider ABN' },
            { key: 'invoice_provider_name', label: 'Invoice Provider Name' },
            { key: 'is_invoice_abn_exempted', label: 'ATO Excluded Supply' },
            { key: 'invoice_comment', label: 'Remittance Comment' },
            { key: 'invoice_private_comment', label: 'Private Notes' },
          ],
          itemLogText ? `items:\n${itemLogText}` : '',
          saveType !== InvoiceSaveType.INV_SAVETYPE_SAVE ? `This invoice is ${saveType}` : '',
          saveWarningLogText
        )

        // ------ check inv authorisaton ------
        if (r.auth_status !== undefined) {
          if (r.auth_status === InvoiceAuthType.AUTH_DETAILS_UPDATED) {
            this.showAuthorisedUpdateAlert()
          } else if (r.auth_status === InvoiceAuthType.AUTH_REQUIRED_AND_ALERT) {
            this.showAuthorisedAlert(clientInfo, r.id, () => { }, true)
          } else if (r.auth_status === InvoiceAuthType.AUTH_NOT_REQUIRED) {
            await this.showAuthorisedDoneAlert(r.id, () => { })
          } else if (r.auth_status === InvoiceAuthType.NO_EMAIL_CONFIGURED) {
            this.showAuthorisedEmailReminder(clientInfo)
          } else if (r.auth_status === InvoiceAuthType.AUTH_ALERT_MISSING_FILES) {
            this.showAuthorisedReminder(clientInfo)
          }
          // else if (r.auth_status === InvoiceAuthType.AUTH_CREATED_OR_SCHEDULED) {
          //   this.showAuthorisedCreatedAlert()
          // }
        }

        // ------ update log message end --------
        await this.fetchInvoice()
        notify.success('Saved successfully', 'Invoice saved successfully.')
      }
    } else {
      if (r) {
        notify.error('Unable to save successfully', formatter.toErrorMessage(r))
      } else {
        notify.error('Unable to save successfully', 'Unable to save invoice successfully. Please try again later.')
      }
    }

    this.setState({ loadingSave: false, loadingHidden: false })
  }

  onCreateComm = async (invId, action, isRefreshInvoice = true) => {
    const body = {
      genre: 'invoice',
      genre_id: invId,
      action
    }

    // api side accept only TYPE_INV_AUTH for the entry, although action for SENDNOW and SKIP are generating different type of TYPE_INV_AUTH
    const r = await commService.create(CommType.PM_COMM_TYPE_INV_AUTH, body)

    if (r && r.id && isRefreshInvoice) {
      this.fetchInvoice()
    }

    return r
  }

  /** start invoice items handling */
  validateInvoiceStartDate = (rule, value, callback) => {
    const { form } = this.props
    const invoiceDate = form.getFieldValue('invoice_date')

    const field = rule.field
    const index = !field
      ? null
      : field.indexOf('inv_date') > -1
        ? field.substring('inv_date'.length)
        : null
    const anotherField = `inv_end_date${index || ''}`
    const invoiceEndDate = form.getFieldValue(anotherField)

    if (value === null) {
      callback(new Error('Please select service start date'))
    } else if (invoiceEndDate === null) {
      callback(new Error('Please select service end date'))
    } else {
      const mStartDate = moment.isMoment(value) ? value.clone().startOf('date') : moment(value).startOf('date')
      const mEndDate = moment.isMoment(invoiceEndDate) ? invoiceEndDate.clone().endOf('date') : moment(invoiceEndDate).endOf('date')
      // const mInvDate = moment.isMoment(invoiceDate) ? invoiceDate.clone().startOf('date') : moment(invoiceDate).startOf('date')

      // if (mStartDate.isAfter(mInvDate, 'date')) {
      //   callback(new Error('Service Start Date must be before/on the Invoice Date'))
      // } else if (mStartDate.isAfter(mEndDate, 'date')) {
      if (moment().endOf('day').isBefore(mStartDate, 'date')) {
        callback(new Error('Service Start Date must be before/on today'))
      } else if (mStartDate.isAfter(mEndDate, 'date')) {
        callback(new Error('Service Start Date must be before Service End Date'))
      } else {
        callback()
      }
    }

    form.validateFields([`inv_end_date${index}`])
  }

  validateInvoiceEndDate = (rule, value, callback) => {
    const { form } = this.props
    const invoiceDate = form.getFieldValue('invoice_date')

    const field = rule.field
    const index = !field
      ? null
      : field.indexOf('inv_end_date') > -1
        ? field.substring('inv_end_date'.length)
        : null
    const anotherField = `inv_date${index || ''}`
    const invoiceStartDate = form.getFieldValue(anotherField)

    if (value === null) {
      callback(new Error('Please select service end date'))
    } else if (invoiceStartDate === null) {
      callback(new Error('Please select service start date'))
    } else {
      const mEndDate = moment.isMoment(value) ? value.clone().endOf('date') : moment(value).endOf('date')
      const mStartDate = moment.isMoment(invoiceStartDate) ? invoiceStartDate.clone().startOf('date') : moment(invoiceStartDate).startOf('date')
      // const mInvDate = moment.isMoment(invoiceDate) ? invoiceDate.clone().startOf('date') : moment(invoiceDate).startOf('date')

      // if (mEndDate.isAfter(mInvDate, 'date')) {
      //   callback(new Error('Service End Date must be before/on the Invoice Date'))
      // } else if (mStartDate.isAfter(mEndDate, 'date')) {
      if (moment().endOf('day').isBefore(mEndDate, 'date')) {
        callback(new Error('Service End Date must be before/on today'))
      } else if (mStartDate.isAfter(mEndDate, 'date')) {
        callback(new Error('Service Start Date must be before Service End Date'))
      } else {
        callback()
      }
    }

    form.validateFields([`inv_date${index}`])
  }

  validateUnitChange = (rule, value, callback) => {
    // if (value === null || value === undefined || value === '' || parseFloat(value) === 0) {
    if (value === null || value === undefined || value === '' || formatter.toBigNumber(value).isEqualTo(0)) {
      callback(new Error(`Unit is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Unit is not number or decimal format`))
      } else {
        callback()
      }
    }
  }

  validateInvoicedRateChange = (rule, value, callback, maxRate) => {
    // const pValue = parseFloat(value)
    // if (value === null || value === undefined || value === '' || pValue === 0) {
    if (value === null || value === undefined || value === '' || formatter.toBigNumber(value).isEqualTo(0)) {
      callback(new Error(`Invoiced Rate is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Invoiced Rate is not number or decimal format`))
      } else {
        // if (maxRate !== 0 && pValue > maxRate) {
        //   callback(new Error(MaxRateMismatchMsg))
        // } else {
        //   callback()
        // }
        callback()
      }
    }
  }

  onUpdateCurrentClientBudgetList = () => {
    const { clientBudget, invoiceItems } = this.state

    let currentList = []
    for (let i = 0; i < invoiceItems.length; i++) {
      const invItem = invoiceItems[i]

      if (invItem.inv_end_date || invItem.inv_date) {
        const date = invItem.inv_end_date
          ? (moment.isMoment(invItem.inv_end_date) ? invItem.inv_end_date.clone() : moment(invItem.inv_end_date))
          : invItem.inv_date
            ? (moment.isMoment(invItem.inv_date) ? invItem.inv_date.clone() : moment(invItem.inv_date))
            : null

        if (date) {
          const activeBudgets = clientBudget.filter(e => {
            const startDate = moment(e.period_start_date).startOf('day')
            const endDate = moment(e.period_end_date).endOf('day')
            // MS0029 - different with budget filter & validation on other functions, this section could not filter activeBudgets with particular budgetId, or else the current Budget List will not show the budgets that are not selected in invoice items.
            // The requirement of current budget list is showing all available budgets within inv items service date selected. Only the validation function need to filter on inv item budgetId
            if (startDate.isSameOrBefore(date) && endDate.isSameOrAfter(date)) {
              return true
            }
            return false
          })

          if (validator.isNotEmptyArray(activeBudgets)) {
            for (let j = 0; j < activeBudgets.length; j++) {
              const activeBudget = activeBudgets[j]

              if (activeBudget && activeBudget.budget_id && currentList.findIndex(e => activeBudget.budget_id === e.budget_id) < 0) {
                activeBudget.is_over_period = formatter.toPeriodStatus(activeBudget.period_end_date).isDue
                currentList.push(activeBudget)
              }
            }
          }
        }
      }
    }

    this.setState({ clientCurrentBudgetList: currentList })
  }

  onAddInvItem = () => {
    const { invoiceItems, clientInfo, providerInfo } = this.state
    invoiceItems.push(Object.assign({}, DefaultInvoiceItem, {
      key: formatter.getHash(8),
      client_id: clientInfo && clientInfo.id ? clientInfo.id : null,
      provider_id: providerInfo && providerInfo.id ? providerInfo.id : null,
      invoiceMsg: ''
    }))
    this.setState({ invoiceItems })
  }

  onRemoveInvItem = async (index) => {
    const { invoiceItems } = this.state
    // const { form } = this.props
    // form.setFieldsValue({[`unit${index}`]: null, [`item_cat_item_id${index}`]: null})
    let invoiceItem = invoiceItems[index]

    if (invoiceItem.id) {
      invoiceItem.is_delete = true
    } else {
      this.setState({ loadingItems: true })
      invoiceItems.splice(index, 1)
      setTimeout(() => {
        this.setState({ loadingItems: false })
      }, 500)
    }

    this.setState({
      invoiceItems,
      invoiceMsg: validator.isNotEmptyArray(invoiceItems) ? '' : SelectInvoiceMsg
    }, () => {
      this.onInvItemUpdateInvTotal()
      this.onUpdateCurrentClientBudgetList()
      this.validateClientCreditBalance() // update credit estimate balance when any of inv item is deleted
    })
  }

  onUndoRemoveInvItem = (index) => {
    const { invoiceItems } = this.state

    let invoiceItem = invoiceItems[index]
    invoiceItem.is_delete = false

    this.setState({ invoiceItems }, () => {
      this.onInvItemUpdateInvTotal()
      this.onUpdateCurrentClientBudgetList()
    })
  }

  onInvItemDateChange = async (date, index, field) => {
    const { form } = this.props
    const { clientBudget, invoiceItems } = this.state

    date = moment.isMoment(date) ? date : moment(date) // date could be ISO2823 format if called from handleInvoiceDateChange()
    const invoiceItem = invoiceItems[index]

    const invoiceDate = form.getFieldValue('invoice_date')
    let std = field === 'inv_date' ? date : form.getFieldValue(`inv_date${index}`)
    let etd = field === 'inv_end_date' ? date : form.getFieldValue(`inv_end_date${index}`)

    const resetItem = (invItem) => {
      invItem.budget_id = ''
      invItem.booking_number = null
      invItem.cats = []
      invItem.catItems = []
      invItem.cat_id = ''
      invItem.cat_id_mix = ''
      invItem.cat_item_id = ''

      invItem.max_rate = 0
      invItem.unit = null
      invItem.inv_rate = 0
      invItem.subtotal = 0

      invItem.is_sb_period = false
      invItem.is_sb_period1 = false
      invItem.is_sb_period2 = false
      invItem.is_sb_periods1 = false
      invItem.is_sb_periods2 = false

      this.onInvItemUpdateInvTotal()
    }

    // reject if any date is empty
    if (!(std && etd)) {
      resetItem(invoiceItem)

      if (!std && etd) {
        std = moment.isMoment(etd) ? etd.clone().startOf('day') : moment(etd).startOf('day')
        form.setFieldsValue({ [`inv_date${index}`]: std })
        invoiceItem.inv_date = std
      } else if (!etd && std) {
        etd = moment.isMoment(std) ? std.clone().endOf('day') : moment(std).endOf('day')
        form.setFieldsValue({ [`inv_end_date${index}`]: etd })
        invoiceItem.inv_end_date = etd
      } else {
        return
      }
    }

    const mInvDate = moment.isMoment(invoiceDate)
      ? invoiceDate.clone().startOf('date')
      : moment(invoiceDate).startOf('date')

    const mStartDate = (moment.isMoment(std)
      ? std.clone().startOf('date')
      : moment(std).startOf('date'))

    const mEndDate = (moment.isMoment(etd)
      ? etd.clone().endOf('date')
      : moment(etd).endOf('date'))

    // // reject the date and clear cat lists if selected cat item start date / end date is invalid
    // if (mStartDate.isAfter(mInvDate, 'date') ||
    //   mEndDate.isAfter(mInvDate, 'date') ||
    //   mStartDate.isAfter(mEndDate, 'date')) {
    //   resetItem(invoiceItem)
    //   return
    // }

    // check start date and end date whether fall inside any of active budgets
    // and have to make sure the filtered budgets are identical when compared using start date and end date
    const activeBudgetsStart = clientBudget.filter(e => {
      const startDate = moment(e.period_start_date).startOf('day')
      const endDate = moment(e.period_end_date).endOf('day')
      if (startDate.isSameOrBefore(std, 'date') && endDate.isSameOrAfter(std, 'date')) {
        return true
      }
      return false
    })

    const activeBudgetsEnd = clientBudget.filter(e => {
      const startDate = moment(e.period_start_date).startOf('day')
      const endDate = moment(e.period_end_date).endOf('day')
      if (startDate.isSameOrBefore(etd, 'date') && endDate.isSameOrAfter(etd, 'date')) {
        return true
      }
      return false
    })

    if (activeBudgetsStart.length !== activeBudgetsEnd.length) {
      invoiceItem.is_sb_mismatch = true
      resetItem(invoiceItem)
      return
    } else {
      const startIds = activeBudgetsStart.map(e => e.budget_id)
      const endIds = activeBudgetsEnd.map(e => e.budget_id)

      if (!isEqual(startIds, endIds)) {
        invoiceItem.is_sb_mismatch = true
        resetItem(invoiceItem)
        return
      }
    }

    // console.log('budget ends', clientBudget, activeBudgetsStart, activeBudgetsEnd)

    // always use end date to detect active budget and append categories, compared with using start date (inv date) previously
    if (validator.isNotEmptyArray(activeBudgetsEnd)) {
      if (activeBudgetsEnd.length === 1) {
        const activeBudget = activeBudgetsEnd[0]
        // handle SB period status and validate expired period
        const periodStatus = formatter.toPeriodStatus(activeBudget.period_end_date)
        const periodStatusInvDate = formatter.toPeriodStatus(activeBudget.period_end_date, std)
        invoiceItem.is_sb_period = periodStatus.isDue
        invoiceItem.is_sb_period1 = periodStatus.isDuePeriod1
        invoiceItem.is_sb_period2 = periodStatus.isDuePeriod2

        if (periodStatus.isDuePeriod2) {
          this.triggerSBInvalidWarning()
        } else if (periodStatus.isDuePeriod1) {
          this.triggerSBExpireWarning()
        }

        // if due period 2 is true (SB end date is more than 90 days, stop all further actions to disallow admin to continue)
        invoiceItem.budget_id = periodStatusInvDate.isDuePeriod2 ? null : activeBudget.budget_id
        invoiceItem.booking_number = periodStatusInvDate.isDuePeriod2 ? null : activeBudget.booking_number
        invoiceItem.cats = periodStatusInvDate.isDuePeriod2 ? [] : activeBudget.categories
      } else {
        let categoriesList = []
        invoiceItem.is_sb_periods = false
        invoiceItem.is_sb_periods1 = false
        invoiceItem.is_sb_periods2 = false

        for (let i = 0; i < activeBudgetsEnd.length; i++) {
          const activeBudget = activeBudgetsEnd[i]
          const periodStatus = formatter.toPeriodStatus(activeBudget.period_end_date)

          invoiceItem.is_sb_periods = invoiceItem.is_sb_periods || periodStatus.isDue
          invoiceItem.is_sb_periods1 = invoiceItem.is_sb_periods1 || periodStatus.isDuePeriod1
          invoiceItem.is_sb_periods2 = invoiceItem.is_sb_periods2 || periodStatus.isDuePeriod2

          const categories = activeBudget.categories
          categoriesList = categoriesList.concat(categories)
        }

        if (invoiceItem.is_sb_periods2) {
          this.triggerSBInvalidWarning(true)
        } else if (invoiceItem.is_sb_periods1) {
          this.triggerSBExpireWarning(true)
        }

        invoiceItem.cats = categoriesList
        invoiceItem.budget_id = null
        invoiceItem.booking_number = null
      }

      invoiceItem.is_sb_mismatch = false
      invoiceItem.catItems = []
      invoiceItem.cat_id = ''
      invoiceItem.cat_id_mix = ''
      invoiceItem.cat_item_id = ''
    } else {
      invoiceItem.is_sb_period = false
      invoiceItem.is_sb_period1 = false
      invoiceItem.is_sb_period2 = false
      invoiceItem.is_sb_periods = false
      invoiceItem.is_sb_periods1 = false
      invoiceItem.is_sb_periods2 = false
      invoiceItem.is_sb_mismatch = false

      invoiceItem.budget_id = ''
      invoiceItem.booking_number = null
      invoiceItem.cats = []
      invoiceItem.catItems = []
      invoiceItem.cat_id = ''
      invoiceItem.cat_id_mix = ''
      invoiceItem.cat_item_id = ''
    }

    // reset invoice unit/rate/subtotal after select new cat
    invoiceItem.max_rate = 0
    invoiceItem.unit = null
    invoiceItem.inv_rate = 0
    invoiceItem.subtotal = 0

    // assign value
    if (field === 'inv_date') {
      invoiceItem.inv_date = date
    } else if (field === 'inv_end_date') {
      invoiceItem.inv_end_date = date
    }

    this.onInvItemUpdateGSTList(index, date)

    this.onInvItemUpdateInvTotal()
    // this.onInvItemValidateBudget(invoiceItem)
    this.onInvMultiItemsValidateBudget(invoiceItems)

    // set client current budget list
    this.onUpdateCurrentClientBudgetList()

    // set form value
    form.setFieldsValue({
      [`cat_id${index}`]: undefined,
      [`cat_id_mix${index}`]: undefined,
      [`cat_item_id${index}`]: undefined,
      [`max_rate${index}`]: 0,
      [`unit${index}`]: null,
      [`inv_rate${index}`]: 0,
      [`subtotal${index}`]: 0,
    })
  }

  onInvItemUpdateGSTList = (index, date) => {
    const { invoiceItems, settingGstList } = this.state

    const activeGstList = settingGstList.filter(e => {
      const startDate = moment(e.start_date).startOf('day')
      const endDate = moment(e.end_date).endOf('day')
      if (startDate.isSameOrBefore(date) && endDate.isSameOrAfter(date)) {
        return true
      }
      return false
    })

    if (validator.isArray(activeGstList)) {
      invoiceItems[index].gst_list = activeGstList

      // set default gst code if gst_code is undefined.
      // cannot be set at input side or else the dropdown select will show a unmatched p2 value.
      if (invoiceItems[index].gst_code === undefined) {
        invoiceItems[index].gst_code = 'p2'
      }
    }

    this.setState({ invoiceItems })
  }

  onInvItemSelectCat = async (id, index, option) => {
    /**
     * due to supporting multiple SBs, the selection of categories will heavily based on budget id.
     * cat_id_mix is a set of number formed by `${cat_id}-${budget_id}` to indicate the uniqueness of the option
     * it will be available on invoice loading and category lists from api by default
     * this function required to strip the id and apply with correct cat id by searching the cat_id_mix from cat list, as well as set the correct cat_id for cat item.
     */
    const { form } = this.props
    const { clientInfo, invoiceItems } = this.state
    const invItem = index < invoiceItems.length ? invoiceItems[index] : {}
    const cat = invItem && validator.isNotEmptyArray(invItem.cats)
      ? invItem.cats.find(e => e.cat_id_mix === id)
      : null

    if (cat) {
      const date = form.getFieldValue(`inv_date${index}`)
      const d = moment.isMoment(date) ? date.format(dateFormat2) : date
      const body = {
        date: d,
        cat_id: cat.cat_id,
        budget_id: cat.budget_id
      }

      // reset invoice unit/rate/subtotal after select new cat
      form.setFieldsValue({
        [`cat_id${index}`]: cat.cat_id,
        [`cat_item_id${index}`]: undefined,
        [`max_rate${index}`]: 0,
        [`unit${index}`]: null,
        [`inv_rate${index}`]: 0,
        [`subtotal${index}`]: 0,
      })

      invoiceItems[index].cat_id = cat.cat_id
      invoiceItems[index].cat_id_mix = id // this value will be defaultly set by input, but need to manully update invoiceItems[index] to make sure delete inv item works.
      invoiceItems[index].cat_item_id = undefined
      invoiceItems[index].max_rate = 0
      invoiceItems[index].unit = null
      invoiceItems[index].inv_rate = 0
      invoiceItems[index].subtotal = 0
      invoiceItems[index].report_group_id = cat.report_group_id

      invoiceItems[index].budget_id = cat.budget_id
      invoiceItems[index].booking_number = cat.booking_number

      this.setState({ loadingCatItems: true })
      const catItems = await clientInvoiceService.listAllClientCatItems(clientInfo.id, body)

      if (validator.isNotEmptyArray(catItems)) {
        let invoiceItem = invoiceItems[index]
        invoiceItems[index].catItems = catItems
      } else {
        invoiceItems[index].catItems = []
      }

      this.onInvItemUpdateInvTotal()
      // invoiceItems[index] = this.onInvItemValidateBudget(invoiceItems[index])
      this.onInvMultiItemsValidateBudget(invoiceItems)

      this.validateClientCredit()

      this.setState({ loadingCatItems: false })
    } else {

    }
  }

  onInvItemSelectCatItem = (id, index) => {
    const { invoiceItems } = this.state
    const { form } = this.props

    const invoiceItem = invoiceItems[index]
    const catItem = invoiceItem.catItems.find(e => e.cat_item_id === id)
    // console.log('cat item', invoiceItem, id, catItem)

    if (catItem) {
      // const maxRate = (catItem.max_rate === null || catItem.max_rate === undefined) ? 0 : parseFloat(catItem.max_rate)
      // const invRate = maxRate === 0 ? 0 : maxRate
      const maxRate = formatter.toBigNumber(formatter.toBigNumber(catItem.max_rate).isNaN() ? 0 : catItem.max_rate)
      const invRate = formatter.toBigNumber(formatter.toBigNumber(maxRate).isEqualTo(0) ? 0 : maxRate)
      const subt = 0 // adjust subtotal to 0 if unit default is null, and (maxRate === 0 ? 0 : 1 * maxRate) if default is 1
      invoiceItem.max_rate = maxRate.toFixed(2)
      invoiceItem.unit = null
      invoiceItem.inv_rate = invRate.toFixed(2)
      invoiceItem.subtotal = subt

      // assign value
      invoiceItems[index].cat_item_id = id

      form.setFieldsValue({
        [`max_rate${index}`]: maxRate.toFixed(2),
        [`unit${index}`]: null,
        [`inv_rate${index}`]: invRate.toFixed(2),
        [`subtotal${index}`]: subt,
      })

      this.onInvItemUpdateInvTotal()
      // this.onInvItemValidateBudget(invoiceItem)
      this.onInvMultiItemsValidateBudget(invoiceItems)
    }
  }

  onInvItemSelectGST = (value, index, option) => {
    const { invoiceItems } = this.state

    const invItem = invoiceItems[index]
    const gstList = invItem.gst_list
    const ls = validator.isNotEmptyArray(gstList)
      ? gstList.find(e => e.item_code === value)
      : null
    invoiceItems[index].gst_code = value
    invoiceItems[index].gst_code_name = ls ? ls.item_name : ''
    invoiceItems[index].gst_value = ls ? ls.value : 0

    this.setState({ invoiceItems }, () => {
      this.onInvItemUpdateInvTotal()
    })
  }

  onInvItemUnitChange = (e, index) => {
    const value = e.target.value

    if (validator.isCurrencyAmount(value)) {
      this.onInvItemUpdateInvAmount(value, null, index)
    } else {
      this.onInvItemUpdateInvAmount(0, null, index)
    }

    const { invoiceItems } = this.state
    // const invoiceItem = invoiceItems[index]
    // this.onInvItemValidateBudget(invoiceItem)
    this.onInvMultiItemsValidateBudget(invoiceItems)
  }

  onInvItemInvRateChange = (e, index) => {
    const value = e.target.value

    if (validator.isCurrencyAmount(value)) {
      this.onInvItemUpdateInvAmount(null, value, index)
    } else {
      this.onInvItemUpdateInvAmount(null, 0, index)
    }

    const { invoiceItems } = this.state
    // const invoiceItem = invoiceItems[index]
    // this.onInvItemValidateBudget(invoiceItem)
    this.onInvMultiItemsValidateBudget(invoiceItems)
  }

  onInvItemUpdateInvAmount = (unit = null, rate = null, index) => {
    const { form } = this.props
    const { invoiceItems, item } = this.state
    const invoiceItem = invoiceItems[index]

    const pUnit1 = unit === null ? form.getFieldValue(`unit${index}`) || 0 : unit || 0
    const pRate1 = rate === null ? form.getFieldValue(`inv_rate${index}`) : rate

    // const pUnit = formatter.toPriceFloat(pUnit1)
    // const pRate = formatter.toPriceFloat(pRate1)
    const pUnit = formatter.toBigNumber(formatter.toBigNumber(pUnit1).isNaN() ? 0 : pUnit1)
    const pRate = formatter.toBigNumber(formatter.toBigNumber(pRate1).isNaN() ? 0 : pRate1)

    // const pAmount = (pUnit * pRate)
    // invoiceItem.unit = pUnit
    // invoiceItem.inv_rate = pRate
    // const subtotal = formatter.toPriceFloat(pAmount)
    // invoiceItem.subtotal = subtotal
    const pAmount = formatter.toBigNumber(pUnit).times(pRate)
    invoiceItem.unit = pUnit.toFixed(2)
    invoiceItem.inv_rate = pRate.toFixed(2)
    const subtotal = pAmount.toFixed(2)
    invoiceItem.subtotal = subtotal

    this.onInvItemUpdateInvTotal()
    // this.onInvItemValidateBudget(invoiceItem)
    this.onInvMultiItemsValidateBudget(invoiceItems)
  }

  onInvItemUpdateInvTotal = () => {
    const { form } = this.props
    const { invoiceItems = [], item } = this.state

    const targetedInvAmount = formatter.toBigNumber(
      formatter.toBigNumber(form.getFieldValue('targeted_subtotal')).isNaN()
        ? formatter.toBigNumber(item.targeted_subtotal).isNaN() ? 0 : item.targeted_subtotal
        : form.getFieldValue('targeted_subtotal')
    )
    let invSubtotalMsg = ''

    let subtotal = 0
    let gstSubtotal = 0
    for (let i = 0; i < invoiceItems.length; i++) {
      const itm = invoiceItems[i]
      // const itmSubtotal = parseFloat(itm.subtotal)
      // const gstValue = itm.gst_value || 0
      // const itmGSTSubtotal = formatter.toPriceFloat(gstValue === 0 ? 0 : (itmSubtotal - itmSubtotal * (100 / (100 + gstValue))))
      const itmSubtotal = formatter.toBigNumber(formatter.toBigNumber(itm.subtotal).isNaN() ? 0 : itm.subtotal)
      const gstValue = formatter.toBigNumber(formatter.toBigNumber(itm.gst_value).isNaN() ? 0 : itm.gst_value)
      const itmGSTSubtotal = formatter.toBigNumber(gstValue.isEqualTo(0) ? 0 : formatter.toBigNumber(itmSubtotal).minus(formatter.toBigNumber(itmSubtotal).times(formatter.toBigNumber(100).div(formatter.toBigNumber(100).plus(gstValue)))))

      invoiceItems[i].gst_subtotal = itmGSTSubtotal.toFixed(2)

      if (!itm.is_delete) {
        // subtotal += itmSubtotal
        // gstSubtotal += itmGSTSubtotal
        subtotal = itmSubtotal.plus(subtotal)
        gstSubtotal = itmGSTSubtotal.plus(gstSubtotal)
      }
    }

    // const fSubtotal = formatter.toPriceFloat(subtotal)
    // const fGstSubtotal = formatter.toPriceFloat(gstSubtotal)
    // item.subtotal = fSubtotal
    // item.gst_subtotal = fGstSubtotal
    const fSubtotal = formatter.toBigNumber(subtotal)
    const fGstSubtotal = formatter.toBigNumber(gstSubtotal)
    item.subtotal = fSubtotal.toFixed(2)
    item.gst_subtotal = fGstSubtotal.toFixed(2)

    if (invoiceItems.length > 0) {
      // if (formatter.toPriceFloat(targetedInvAmount) !== fSubtotal) {
      //   const diff = `${formatter.toPrice((targetedInvAmount ? parseFloat(formatter.toPriceFloat(targetedInvAmount)) : 0) - (fSubtotal ? parseFloat(formatter.toPriceFloat(fSubtotal)) : 0))}`
      //   invSubtotalMsg = `${diff} ${TotalAmountMismatchMsg2}`
      // }
      if (!targetedInvAmount.isEqualTo(fSubtotal)) {
        const diff = `${formatter.toPrice(targetedInvAmount.minus(fSubtotal).toFixed(2))}`
        invSubtotalMsg = `${diff} ${TotalAmountMismatchMsg2}`
      }
    }

    this.setState({ item, invSubtotalMsg })
  }

  onInvItemValidateBudget = (invItem, isReturnBudgetInfo = false) => {
    const { clientBudget } = this.state
    const { inv_date: invDate, budget_id: budgetId, cat_id: catId, cat_item_id: catItemId, subtotal, applied_credit: appliedCredit } = invItem

    if (invDate && catId && catItemId) {
      const mInvDate = moment.isMoment(invDate) ? invDate.clone().startOf('day') : moment(invDate).startOf('day')

      const activeBudget = clientBudget.find(e => {
        const startDate = moment(e.period_start_date).startOf('day')
        const endDate = moment(e.period_end_date).endOf('day')
        if (startDate.isBefore(mInvDate) && endDate.isAfter(mInvDate)) {
          if (budgetId) {
            if (e.budget_id === budgetId) {
              return true
            } else {
              return false
            }
          }
          return true
        }
        return false
      })

      if (activeBudget) {
        const { booking_number, categories = [] } = activeBudget
        const cat = categories.find(e => {
          if (e.cat_id && e.cat_item_id) {
            return e.cat_item_id === catItemId && e.cat_id === catId
          } else if (e.cat_id) {
            return e.cat_id === catId
          } else {
            return false
          }
        })

        if (cat) {
          const finalSubtotal = parseFloat(formatter.toFloatDecimal(parseFloat(subtotal) - (appliedCredit && appliedCredit.key ? parseFloat(appliedCredit.amount) : 0)))
          // if (subtotal > cat.remaining_value) {
          if (formatter.toBigNumber(subtotal).isGreaterThan(cat.remaining_value)) {
            invItem.is_amt_over_budget = true
            return isReturnBudgetInfo ? { ...cat, booking_number } : invItem
          }
        }
      }
    }

    invItem.is_amt_over_budget = false
    return isReturnBudgetInfo ? null : invItem
  }

  onInvMultiItemsValidateBudget = (invItems = [], isReturnBudgetInfo = false) => {
    const { clientBudget } = this.state

    const sortedInvItems = cloneDeep(invItems)
    const compareData = []
    let infoCategories = []

    for (let i = 0; i < invItems.length; i++) {
      invItems[i].is_amt_over_budget = false
      invItems[i].amt_cp = {}
    }

    for (let i = 0; i < sortedInvItems.length; i++) {
      const itm = sortedInvItems[i]
      const { inv_date: invDate, budget_id: budgetId, report_group_id: reportGroupId, cat_id: catId, cat_item_id: catItemId, subtotal, applied_credit: appliedCredit } = itm

      if (invDate && catId && (reportGroupId || catItemId)) {
        const mInvDate = moment.isMoment(invDate) ? invDate.clone().startOf('day') : moment(invDate).startOf('day')
        const activeBudget = clientBudget.find(e => {
          const startDate = moment(e.period_start_date).startOf('day')
          const endDate = moment(e.period_end_date).endOf('day')
          if (startDate.isBefore(mInvDate) && endDate.isAfter(mInvDate)) {
            if (budgetId) {
              if (e.budget_id === budgetId) {
                return true
              } else {
                return false
              }
            }
            return true
          }
          return false
        })
        // console.log('client budget onInvItemsValidateBudget', clientBudget)

        if (activeBudget) {
          const { booking_number, categories = [] } = activeBudget
          const cat = categories.find(e => {
            if (e.cat_id && e.cat_item_id) {
              return e.cat_item_id === catItemId && e.cat_id === catId
            } else if (e.cat_id) {
              return e.cat_id === catId
            } else {
              return false
            }
          })

          if (cat) {
            const cp = compareData.find(e => e.budget_id === cat.budget_id && e.cat_id === cat.cat_id && (cat.cat_item_id ? e.cat_item_id === cat.cat_item_id : true))
            // console.log('onInvItemsValidateBudget cp', cp, cat)
            if (cp) {
              // cp.subtotal += parseFloat(formatter.toFloatDecimal(itm.subtotal))
              cp.subtotal = formatter.toBigNumber(itm.subtotal).plus(cp.subtotal).toFixed(2)

              if (appliedCredit && appliedCredit.key) {
                // cp.credit_subtotal += parseFloat(formatter.toFloatDecimal(appliedCredit.amount))
                cp.credit_subtotal = formatter.toBigNumber(appliedCredit.amount).plus(cp.credit_subtotal).toFixed(2)
              }
            } else {
              compareData.push({
                budget_id: cat.budget_id,
                cat_id: cat.cat_id,
                cat_name: cat.cat_name,
                cat_item_id: cat.cat_item_id || null,
                booking_number: cat.booking_number,
                remaining_forecast_value: cat.remaining_forecast_value,
                remaining_forecast_value_excl: cat.remaining_forecast_value_excl,
                // subtotal: parseFloat(formatter.toFloatDecimal(itm.subtotal)),
                // credit_subtotal: appliedCredit && appliedCredit.key ? parseFloat(formatter.toFloatDecimal(appliedCredit.amount)) : 0
                subtotal: formatter.toBigNumber(formatter.toBigNumber(itm.subtotal).isNaN() ? 0 : itm.subtotal).toFixed(2),
                credit_subtotal: formatter.toBigNumber(appliedCredit && appliedCredit.key ? formatter.toBigNumber(appliedCredit.amount).isNaN() ? 0 : appliedCredit.amount : 0).toFixed(2)
              })

              infoCategories.push({ ...cat, booking_number })
            }
          }
        }
      }
    }

    // console.log('onInvItemsValidateBudget compare data', compareData, infoCategories)

    for (let k = 0; k < compareData.length; k++) {
      const cp = compareData[k]
      // console.log('onInvItemsValidateBudget cp', cp)
      if (cp && cp.budget_id) {
        // new finalSubtotal value to include cached credit subtotal (while create invoice). if the budget is over but credit is applied, subtract the applied credit value from subtotal. this only works for create case.
        // const finalSubtotal = parseFloat(formatter.toFloatDecimal(cp.subtotal - cp.credit_subtotal))
        const finalSubtotal = formatter.toBigNumber(cp.subtotal).minus(cp.credit_subtotal)

        // check the new excl value to exclude the current invoice for update case
        // if (finalSubtotal > cp.remaining_forecast_value_excl) {
        if (finalSubtotal.isGreaterThan(cp.remaining_forecast_value_excl)) {
          const itms = invItems.filter(e => e.budget_id === cp.budget_id && e.cat_id === cp.cat_id && (cp.cat_item_id ? e.cat_item_id === cp.cat_item_id : true))
          // console.log('onInvItemsValidateBudget cp inv item', itms)
          for (let m = 0; m < itms.length; m++) {
            itms[m].is_amt_over_budget = true
            itms[m].amt_cp = cp
          }
        }
      }
    }

    this.setState({ invoiceItems: invItems })

    return isReturnBudgetInfo ? infoCategories : null
  }

  /** Rcv Amt Modal */

  handleRcvAmtModal = (showRcvAmtModal, itemId) => {
    this.setState({ showRcvAmtModal }, () => this.getRcvAmtInfo(showRcvAmtModal, itemId))
  }

  getRcvAmtInfo = async (showRcvAmtModal, itemId) => {
    if (showRcvAmtModal === true) {
      this.setState({ loadingRctDetail: true })

      const detail = await invoiceService.getRcvAmtInfo(itemId)

      if (detail && detail.id) {
        this.setState({ loadingRctDetail: false, rcvModalInfo: detail })
      } else {
        this.setState({ loadingRctDetail: false, rcvModalInfo: {} })
      }
    } else {
      this.setState({ rcvModalInfo: {} })
    }
  }

  validateRcvAmtDate = (rule, value, callback, invDate) => {
    const { item } = this.state
    // if (value === null || value === undefined || value === '' || value === 0) {
    if (value === null || value === undefined || value === '' || formatter.toBigNumber(value).isEqualTo(0)) {
      callback(new Error(`Receive Date is required`))
    } else {
      if (item.invoice_date) {
        const m = moment(item.invoice_date).startOf('day')
        const v = moment.isMoment(value) ? value : moment(value)

        if (v.isBefore(m)) {
          callback(new Error(`Receive Date cannot be earlier than invoice date.`))
        }
      }

      callback()
    }
  }

  validateInvItemRcvAmount = (rule, value, callback, maxRcvAmount) => {
    const { form } = this.props
    const rcvAmount = formatter.toPriceFloat(form.getFieldValue('rcv_amount'))
    // if (value === null || value === undefined || value === '' || value === 0) {
    if (value === null || value === undefined || value === '' || formatter.toBigNumber(value).isEqualTo(0)) {
      callback(new Error(`Item Receive Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Item Receive Amount is not number or decimal format`))
      } else {
        // value = formatter.toPriceFloat(value)
        // const maxVal = formatter.toPriceFloat(maxRcvAmount)
        value = formatter.toBigNumber(value)
        const maxVal = formatter.toBigNumber(maxRcvAmount)

        // if (maxVal < parseFloat(value)) {
        if (maxVal.isLessThan(value)) {
          callback(new Error(`Item Receive Amount cannot exceed To Receive Amount`))
        // } else if (parseFloat(value) === 0) {
        } else if (formatter.toBigNumber(value).isEqualTo(0)) {
          callback(new Error(`Item Receive Amount cannot be zero`))
        } else {
          callback()
        }

        // if (parseFloat(value) === 0) {
        //   callback(new Error(`Item Receive Amount cannot be zero`))
        // } else {
        //   callback()
        // }
      }
    }
  }

  onInvItemRcvAmountChange = (e, maxRcvAmount) => {
    const { form } = this.props
    // const value = formatter.toPriceFloat(e.target.value)
    const value = formatter.toBigNumber(e.target.value)
    let isAutoClose = this.state.rcvModalInfo.is_auto_closed
    let isOverpay = false
    // if (value === maxRcvAmount) {
    if (value.isEqualTo(maxRcvAmount)) {
      isAutoClose = true
      isOverpay = false
    // } else if (value > maxRcvAmount) {
    } else if (value.isGreaterThan(maxRcvAmount)) {
      isAutoClose = true
      isOverpay = true
    } else {
      isAutoClose = false
      isOverpay = false
    }

    form.setFieldsValue({ is_auto_closed: isAutoClose })
    this.setState({
      rcvModalInfo: { ...this.state.rcvModalInfo, is_auto_closed: isAutoClose, is_overpay: isOverpay }
    })
  }

  onInvItemRcvClosedChange = (e, maxRcvAmount) => {
    const { form } = this.props
    // const rcvAmount = formatter.toPriceFloat(form.getFieldValue('rcv_amount'))
    const rcvAmount = formatter.toBigNumber(formatter.toBigNumber(form.getFieldValue('rcv_amount')).isNaN() ? 0 : form.getFieldValue('rcv_amount'))
    if (e === true) {
      // if (parseFloat(rcvAmount) < parseFloat(maxRcvAmount)) {
      if (formatter.toBigNumber(rcvAmount).isLessThan(maxRcvAmount)) {
        this.setState({ rcvModalMsg: RcvAmountEarlyCloseMsg })
      } else {
        this.setState({ rcvModalMsg: '' })
      }
    } else {
      this.setState({ rcvModalMsg: '' })
    }
  }

  preCheckRcvAmt = async () => {
    const { rcvModalInfo, loadingUpdateRct } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { onUpdateRcvAmt } = this

    if (loadingUpdateRct) return

    validateFieldsAndScroll(['rcv_amount', 'rcv_comment', 'is_auto_closed', 'received_date'], async (errors, values) => {
      if (!errors) {
        // const rcvAmount = formatter.toPriceFloat(values.rcv_amount)
        const rcvAmount = formatter.toBigNumber(values.rcv_amount)
        const isClosed = values.is_auto_closed
        // const toPayAmount = formatter.toPriceFloat(rcvModalInfo.to_pay_subtotal)
        const toPayAmount = formatter.toBigNumber(rcvModalInfo.to_pay_subtotal)

        // if (isClosed && rcvAmount !== toPayAmount) {
        if (isClosed && !rcvAmount.isEqualTo(toPayAmount)) {
          confirm({
            title: 'Proceed To Update Received Amount?',
            content: (
              <div style={{ color: 'rgb(238, 27, 27)' }}>
                <p>The amount of current receiving amount is not matched with to pay amount, and you are about to close this item.</p>
                <p>Confirm your action to proceed.</p>
              </div>
            ),
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk() {
              // eslint-disable-next-line no-lone-blocks
              onUpdateRcvAmt(rcvModalInfo, values)
            },
            onCancel() {
            }
          })
        } else {
          onUpdateRcvAmt(rcvModalInfo, values)
        }
      }
    })
  }

  onUpdateRcvAmt = async (rcvModalInfo, values) => {
    const data = {
      id: rcvModalInfo.id,
      // received_amount: formatter.toPriceFloat(values.rcv_amount),
      received_amount: formatter.toBigNumber(values.rcv_amount).toFixed(2),
      is_auto_closed: values.is_auto_closed,
      comment: values.rcv_comment,
      received_date: values.received_date
    }

    if (this.state.loadingUpdateRct) return

    this.setState({ loadingUpdateRct: true })
    const r = await invoiceService.addRcvAmt(data)

    if (r && r.id) {
      notify.success('Update Receive amount successfully', 'Receive amount is updated successfully')
      this.setState({ loadingUpdateRct: false })
      this.handleRcvAmtModal(false)
      this.fetchInvoice()
    } else {
      notify.error('Unable to update received amount', 'Unable to update received amount successfully. Please try again later.')
      this.setState({ loadingUpdateRct: false })
    }
  }

  /** Credit Amt Add Modal */
  handleCreditAmtAddModal = (showCreditAmtAddModal, values, appliedCredit) => {
    this.setState({ showCreditAmtAddModal, isCreditApplyAll: appliedCredit && appliedCredit.is_credit_applied_all ? true : false }, () => this.getCreditAmtAddInfo(showCreditAmtAddModal, values, appliedCredit))
  }

  /** Credit Amt Modal */
  handleCreditAmtModal = (showCreditAmtModal, values) => {
    this.setState({ showCreditAmtModal, isCreditApplyAll: false }, () => this.getCreditAmtInfo(showCreditAmtModal, values))
  }

  findCredits = (input, option) => {
    const cd = option.props.children.props.children
    let text = ''
    if (validator.isNotEmptyArray(cd)) {
      for (let i = 0; i < cd.length; i++) {
        let text2 = validator.isNotEmptyArray(cd[i].props.children) ? cd[i].props.children.join() : ''
        text += text2
      }

      return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    return false
  }

  onCreditAmtSelect = (id, invoiceItemSeq = null) => {
    const { creditModalInfo, invoiceItems } = this.state

    if (validator.isNotEmptyArray(creditModalInfo.credits)) {
      const credit = creditModalInfo.credits.find(e => e.id === id)

      if (credit) {
        // calculate credit applied from other inv items under same credit id and update max applicable credit amt when select from any of inv item
        let appliedCreditAmt = 0
        for (let i = 0; i < invoiceItems.length; i++) {
          const invItem = invoiceItems[i]
          if (invoiceItemSeq !== null && i === invoiceItemSeq) continue

          if (invItem.applied_credit && invItem.applied_credit.credit_id && invItem.applied_credit.credit_id === credit.id) {
            // appliedCreditAmt += parseFloat(invItem.applied_credit.amount)
            appliedCreditAmt = formatter.toBigNumber(invItem.applied_credit.amount).plus(appliedCreditAmt)
          }
        }

        // const toPaySubtotal = formatter.toPriceFloat(creditModalInfo.to_pay_subtotal)
        // const maxAmt = formatter.toPriceFloat(parseFloat(credit.remaining_amount) - appliedCreditAmt)
        const toPaySubtotal = formatter.toBigNumber(creditModalInfo.to_pay_subtotal)
        const maxAmt = formatter.toBigNumber(credit.remaining_amount).minus(appliedCreditAmt)
        this.setState({
          clientSelectedCredit: credit,
          clientCreditMaxAmt: toPaySubtotal.isGreaterThan(maxAmt) ? maxAmt.toFixed(2) : toPaySubtotal.toFixed(2)
        })
      }
    }
  }

  regulateCreditBalance = (detail) => {
    const { invoiceItems } = this.state
  }

  validateCreditApplyAmtDate = (rule, value, callback) => {
    const { item } = this.state
    // if (value === null || value === undefined || value === '' || value === 0) {
    if (value === null || value === undefined || value === '' || formatter.toBigNumber(value).isEqualTo(0)) {
      callback(new Error(`Apply Date is required`))
    } else {
      if (item.invoice_date) {
        const m = moment(item.invoice_date).startOf('day')
        const v = moment.isMoment(value) ? value : moment(value)

        if (v.isBefore(m)) {
          callback(new Error(`Apply Date cannot be earlier than invoice date.`))
        }
      }

      callback()
    }
  }

  validateCreditAmt = (rule, value, callback) => {
    const { clientCreditMaxAmt } = this.state
    // if (value === null || value === undefined || value === '' || value === 0) {
    if (value === null || value === undefined || value === '' || formatter.toBigNumber(value).isEqualTo(0)) {
      callback(new Error(`Credit Apply Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Credit Apply Amount is not number or decimal format`))
      } else {
        // if (clientCreditMaxAmt < parseFloat(value)) {
        if (formatter.toBigNumber(clientCreditMaxAmt).isLessThan(value)) {
          callback(new Error(`Credit Apply Amount cannot exceed To Receive Amount or Available Credit Amount`))
        // } else if (parseFloat(value) === 0) {
        } else if (formatter.toBigNumber(value).isLessThan(0)) {
          callback(new Error(`Credit Apply Amount cannot be zero`))
        } else {
          callback()
        }
      }
    }
  }

  updateIsApplyCreditAll = (e) => {
    const { form } = this.props
    const { clientCreditMaxAmt, creditModalInfo } = this.state

    this.setState({ isCreditApplyAll: e }, () => {
      if (e === true) {
        // const v1 = parseFloat(clientCreditMaxAmt)
        // const v2 = parseFloat(creditModalInfo.to_pay_subtotal)
        // const val = v2 > v1 ? v1 : v2
        const v1 = formatter.toBigNumber(clientCreditMaxAmt)
        const v2 = formatter.toBigNumber(creditModalInfo.to_pay_subtotal)
        const val = v2.isGreaterThan(v1) ? v1.toFixed(2) : v2.toFixed(2)
        form.setFieldsValue({ credit_amount: val })
      } else {
        form.setFieldsValue({ credit_amount: undefined })
      }
    })
  }

  getCreditAmtAddInfo = async (showCreditAmtAddModal, values = {}, appliedCredit = {}) => {
    const isEdit = this.isEdit()

    if (showCreditAmtAddModal === true) {
      this.setState({ loadingCreditDetail: true })

      const detail = await creditClientService.getCreditAmtInfoForAdd(values)
      // console.log('credit amt add info', detail, appliedCredit)
      // getCreditAmtInfoForAdd() api return key instead of id, because matching with invoice item key
      if (detail && detail.key) {
        this.setState({
          loadingCreditDetail: false,
          creditModalInfo: detail,
          clientSelectedCredit: {},
          clientCurrentAppliedCredit: appliedCredit,
          clientCreditMaxAmt: 0.0
        }, () => {
          if (appliedCredit && appliedCredit.key && appliedCredit.credit_id) {
            this.onCreditAmtSelect(appliedCredit.credit_id, values.sequence)
          }
        })
      } else {
        this.setState({ loadingCreditDetail: false, creditModalInfo: {}, clientSelectedCredit: {}, clientCreditMaxAmt: 0.0, clientCurrentAppliedCredit: {} })
      }
    } else {
      this.setState({ creditModalInfo: {}, clientSelectedCredit: {}, clientCreditMaxAmt: 0.0, clientCurrentAppliedCredit: {} })
    }
  }

  getCreditAmtInfo = async (showCreditAmtModal, values = {}) => {
    const { item } = this.state
    const { id: itemId } = values
    if (showCreditAmtModal === true && itemId) {
      this.setState({ loadingCreditDetail: true })

      const detail = await creditClientService.getCreditAmtInfo(itemId, item.client_id)

      if (detail && detail.id) {
        this.setState({
          loadingCreditDetail: false,
          creditModalInfo: detail,
          clientSelectedCredit: {},
          clientCreditMaxAmt: 0.0
        })
      } else {
        this.setState({ loadingCreditDetail: false, creditModalInfo: {}, clientSelectedCredit: {}, clientCreditMaxAmt: 0.0 })
      }
    } else {
      this.setState({ creditModalInfo: {}, clientSelectedCredit: {}, clientCreditMaxAmt: 0.0 })
    }
  }

  preCheckCreditAmt = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { onUpdateCreditAmt } = this
    const { clientSelectedCredit, creditModalInfo } = this.state

    const isEdit = this.isEdit()

    validateFieldsAndScroll(['credit_id', 'apply_date', 'credit_amount', 'credit_comment'], async (errors, values) => {
      if (!errors) {
        confirm({
          title: 'Proceed To Apply Credit Amount?',
          content: (
            <div style={{ color: 'rgb(238, 27, 27)' }}>
              <p>Redeem {formatter.toPrice(values.credit_amount)} from credit {formatter.toPrice(clientSelectedCredit.remaining_amount)}.</p>
              {isEdit
                ? <p><strong>**If you update the invoice item amount, the credit applied will be cleared immediately.**</strong></p>
                : <p><strong><em>**The credit amount will be applied and saved in record only when you create and save the invoice successfully.**</em></strong></p>}
              <p>Confirm your action to proceed.</p>
            </div>
          ),
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk() {
            // eslint-disable-next-line no-lone-blocks
            onUpdateCreditAmt(creditModalInfo, clientSelectedCredit, values)
          },
          onCancel() {
          }
        })
      }
    })
  }

  onUpdateCreditAmt = async (creditModalInfo, clientSelectedCredit, values) => {
    const isEdit = this.isEdit()

    if (isEdit) {
      const data = {
        credit_id: values.credit_id,
        inv_item_id: creditModalInfo.id,
        amount: formatter.toBigNumber(values.credit_amount).toFixed(2),
        comment: values.credit_comment,
        apply_date: values.apply_date
      }

      this.setState({ loadingUpdateCredit: true })
      const r = await creditClientService.applyCredit(data)

      if (r && r.id) {
        notify.success('Apply Credit Amount successfully', 'Credit amount is applied to invoice item successfully')
        this.setState({ loadingUpdateCredit: false })
        this.handleCreditAmtModal(false)
        this.fetchInvoice()
      } else {
        notify.error('Unable to apply credit amount', 'Unable to update received amount successfully. Please try again later.')
        this.setState({ loadingUpdateCredit: false })
      }
    } else {
      const { invoiceItems, isCreditApplyAll } = this.state
      const data = {
        key: creditModalInfo.key,
        credit_id: values.credit_id,
        inv_item_id: creditModalInfo.id,
        is_credit_applied_all: isCreditApplyAll,
        amount: values.credit_amount,
        comment: values.credit_comment,
        apply_date: values.apply_date
      }

      const idx = invoiceItems.findIndex(e => e.key === creditModalInfo.key)

      if (idx > -1) {
        invoiceItems[idx].applied_credit = data
      }

      // update estimated credit amt right after detail of credit fetched
      this.validateClientCreditBalance()

      this.handleCreditAmtAddModal(false)
    }

    // validate invoice items after apply credit (on create) so if the credit amt is applied, the alert message of exceed budget could be removed
    this.onInvMultiItemsValidateBudget(this.state.invoiceItems)
  }

  /** Credit Amt Edit Modal */
  handleCreditAmtEditModal = (showCreditEditModal, creditInfo = {}) => {
    this.setState({ showCreditEditModal }, () => this.getCreditAmtEditInfo(showCreditEditModal, creditInfo))
  }

  getCreditAmtEditInfo = (showCreditEditModal, creditInfo = {}) => {
    const { creditModalInfo } = this.state

    if (showCreditEditModal === true && creditInfo.id) {
      // start check maximum available amount to update the credit amount
      const creditId = creditInfo.credit_id
      const currentCreditAmount = formatter.toBigNumber(creditInfo.amount)

      const credits = creditModalInfo.credits || []
      const crd = credits.find(e => e.id === creditId)
      let maxAmt = currentCreditAmount.toFixed(2)

      if (crd && crd.id) {
        // const creditRemainingAmt = parseFloat(crd.remaining_amount)
        const creditRemainingAmt = formatter.toBigNumber(crd.remaining_amount)
        // maximum available amount for credit side
        // const creditMaxAmount = creditRemainingAmt + currentCreditAmount
        const creditMaxAmount = formatter.toBigNumber(creditRemainingAmt).plus(currentCreditAmount)
        // maximum available amount for inv item side
        // const itemMaxAmount = parseFloat(creditModalInfo.to_pay_subtotal) + currentCreditAmount
        const itemMaxAmount = formatter.toBigNumber(creditModalInfo.to_pay_subtotal).plus(currentCreditAmount)

        maxAmt = creditMaxAmount.isGreaterThan(itemMaxAmount) ? itemMaxAmount.toFixed(2) : creditMaxAmount.toFixed(2)
      }

      this.setState({
        showCreditEditModal,
        clientCurrentCreditInfo: creditInfo,
        clientCreditEditMaxAmt: maxAmt
      })
    } else {
      this.setState({
        showCreditEditModal,
        clientCurrentCreditInfo: creditInfo,
        clientCreditEditMaxAmt: 0.0
      })
    }
  }

  validateCreditEditAmt = (rule, value, callback) => {
    const { form } = this.props
    const { clientCreditEditMaxAmt } = this.state
    // if (value === null || value === undefined || value === '' || value === 0) {
    if (value === null || value === undefined || value === '' || formatter.toBigNumber(value).isEqualTo(0)) {
      callback(new Error(`Credit Apply Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Credit Apply Amount is not number or decimal format`))
      } else {
        // if (clientCreditEditMaxAmt < parseFloat(value)) {
        if (formatter.toBigNumber(clientCreditEditMaxAmt).isLessThan(value)) {
          callback(new Error(`Credit Apply Amount cannot exceed Allowed Amount`))
        // } else if (parseFloat(value) === 0) {
        } else if (formatter.toBigNumber(value).isEqualTo(0)) {
          callback(new Error(`Credit Apply Amount cannot be zero`))
        } else {
          callback()
        }
      }
    }
  }

  preCheckCreditEditAmt = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { onUpdateCreditEditAmt } = this
    const { clientCurrentCreditInfo } = this.state

    validateFieldsAndScroll(['edit_apply_date', 'edit_credit_amount', 'edit_credit_comment'], async (errors, values) => {
      if (!errors) {
        confirm({
          title: 'Proceed To Update Credit Amount?',
          content: (
            <div style={{ color: 'rgb(238, 27, 27)' }}>
              <p>Update credit amount to {formatter.toPrice(values.edit_credit_amount)} (previously {formatter.toPrice(clientCurrentCreditInfo.amount)}).</p>
              <p>Confirm your action to proceed.</p>
            </div>
          ),
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk() {
            // eslint-disable-next-line no-lone-blocks
            onUpdateCreditEditAmt(clientCurrentCreditInfo, values)
          },
          onCancel() {
          }
        })
      }
    })
  }

  onUpdateCreditEditAmt = async (clientCurrentCreditInfo, values) => {
    const { showCreditAmtModal, creditModalInfo } = this.state
    const data = {
      id: clientCurrentCreditInfo.id,
      amount: values.edit_credit_amount,
      comment: values.edit_credit_comment,
      apply_date: values.edit_apply_date
    }

    this.setState({ loadingUpdateCredit: true })
    const r = await creditClientService.updateCredit(data)

    if (r && r.id) {
      notify.success('Update Credit Amount successfully', 'Credit amount is updated successfully')
      this.setState({ loadingUpdateCredit: false })
      this.handleCreditAmtEditModal(false)
      // this.getCreditAmtInfo(showCreditAmtModal, creditModalInfo.id) //TODO: check this function is whether required
      this.fetchInvoice()
    } else {
      notify.error('Unable to update credit amount', 'Unable to update credit amount successfully. Please try again later.')
      this.setState({ loadingUpdateCredit: false })
    }
  }

  /** PRODA Payment Reques Edit Modal */
  handleProdaModal = (showProdaModal) => {
    this.setState({ showProdaModal })
  }

  getProdaFile = async (isSendEmail = false, isDownload = true) => {
    const { item, loadingSelectProda } = this.state

    if (loadingSelectProda) return

    if (!item.proda_export_id) {
      notify.success('Unable to download PRODA File', 'No Payment Request file available in the records')
      return
    }

    this.setState({ loadingSelectProda: true })

    const ids = [item.proda_export_id]
    const selectIds = [item.id]
    const type = item.is_sdb_invoice ? ExportType.TYPE_PRODA_STD : ExportType.TYPE_PRODA_PM
    const r = await exportFile.fetchFilesInv(item.id, type, null, ids, selectIds, isSendEmail, isDownload)

    if (r && r.id && isSendEmail) {
      notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
    }

    setTimeout(() => {
      this.setState({ loadingSelectProda: false })
      this.handleProdaModal(false)

      if (isDownload && isSendEmail) {
        notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
      }
    }, 4000)
  }

  /** ABA/RMT Edit Modal */
  handleAbaRmtModal = (showAbaRmtModal, abaRmtModalType = '') => {
    const { item } = this.state
    let abaRmtSelectList = []
    if (showAbaRmtModal === true && item && validator.isNotEmptyArray(item.rcv_history_list)) {
      abaRmtSelectList = item.rcv_history_list.map(e => ({ export_id: e.export_id, id: e.id }))
    }

    this.setState({ showAbaRmtModal, abaRmtModalType, abaRmtSelectList, abaRmtSelectAll: showAbaRmtModal })
  }

  updateAbaRmtModalIdsSelect = (e, id, exportId) => {
    const check = e.target.checked
    let { abaRmtSelectList, item } = this.state

    if (validator.isNotEmptyArray(item.rcv_history_list)) {
      if (check) {
        const c = abaRmtSelectList.find(e => e.id === id && e.export_id === exportId)
        if (!c) {
          abaRmtSelectList.push({ id, export_id: exportId })
        }
      } else {
        const c = abaRmtSelectList.findIndex(e => e.id === id && e.export_id === exportId)
        if (c > -1) {
          abaRmtSelectList.splice(c, 1)
        }
      }

      this.setState({
        abaRmtSelectList,
        abaRmtSelectAll: item.rcv_history_list && abaRmtSelectList.length >= item.rcv_history_list.length,
        abaRmtSelectErrMsg: ''
      })
    }
  }

  updateAbaRmtModalIdsSelectAll = (e) => {
    const check = e.target.checked

    let { abaRmtSelectList, item } = this.state

    if (validator.isNotEmptyArray(item.rcv_history_list)) {
      if (check) {
        abaRmtSelectList = item.rcv_history_list.map(e => ({ export_id: e.export_id, id: e.id }))
      } else {
        abaRmtSelectList = []
      }

      this.setState({
        abaRmtSelectList,
        abaRmtSelectAll: check,
        abaRmtSelectErrMsg: ''
      })
    }
  }

  preAbaRmtGetFile = (isSendEmail, isDownload) => {
    const { getAbaRmtFile } = this
    const { abaRmtSelectList = [] } = this.state

    const title = isSendEmail && isDownload
      ? `Are you sure to export and email selected items?`
      : isSendEmail
        ? `Are you sure to email selected items?`
        : `Are you sure to export selected items?`

    if (validator.isNotEmptyArray(abaRmtSelectList)) {
      confirm({
        title: title,
        content: 'Press Ok to continue, Cancel to return',
        onOk() {
          getAbaRmtFile(isSendEmail, isDownload)
        }
      })
    } else {
      this.setState({ abaRmtSelectErrMsg: ItemsSelectErrMsg })
    }
  }

  getAbaRmtFile = async (isSendEmail = false, isDownload = true) => {
    const { item, loadingSelectAbaRmt, abaRmtModalType, abaRmtSelectList } = this.state

    if (loadingSelectAbaRmt) return

    let ids = []
    let selectIds = []

    for (let i = 0; i < abaRmtSelectList.length; i++) {
      const s = abaRmtSelectList[i]

      const a = ids.findIndex(e => e === s.export_id)
      const b = selectIds.findIndex(e => e === s.id)

      if (!(a > -1)) {
        ids.push(s.export_id)
      }

      if (!(b > -1)) {
        selectIds.push(s.id)
      }
    }

    // console.log('file 1', ids, selectIds)

    this.setState({ loadingSelectAbaRmt: true })

    const r = await exportFile.fetchFilesInv(item.id, abaRmtModalType, null, ids, selectIds, isSendEmail, isDownload)

    if (r && r.id && isSendEmail) {
      notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
    }

    setTimeout(() => {
      this.setState({ loadingSelectAbaRmt: false })
      this.handleAbaRmtModal(false)

      if (isDownload && isSendEmail) {
        notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
      }
    }, 4000)
  }

  /** Add new Invoice - File Upload Modal */
  handleAddFileModal = (showAddFileModal, info = {}) => {
    this.setState({ showAddFileModal, fileInfo: info })
  }

  updateFileAdded = (values) => {
    const { fileList } = this.state
    if (values.seq !== undefined) {
      const idx = fileList.findIndex(e => e.seq === values.seq)
      if (idx > -1) {
        fileList.splice(idx, 1, values)
      } else {
        fileList.push(values)
      }
    } else {
      fileList.push(values)
    }

    this.setState({ fileList }, () => {
      this.validateFiles(fileList)
      this.handleAddFileModal(false)
    })
  }

  handleDeleteFile = (item) => {
    const { fileList } = this.state

    if (item.seq) {
      const idx = fileList.findIndex(e => e.seq === item.seq)
      if (idx > -1) {
        fileList.splice(idx, 1)

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].seq = String(i)
        }
      }

      this.setState({ fileList }, () => {
        this.validateFiles(fileList)
      })
    }
  }

  validateFiles = (fileList = []) => {
    const { item } = this.state
    const commFileList = fileList.filter(e => e.is_attach_mail_comm === true)

    this.setState({ isAllowSaveProcess: item.is_sdb_invoice === true ? true : validator.isNotEmptyArray(commFileList) })
  }

  uploadFiles = async (invoiceInfo, result) => {
    const { fileList, categoriesList, subCategoriesList } = this.state

    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      delete file.seq
      file.genre_id = result.id

      const cat = categoriesList.find(e => e.id === file.main_cat_id)
      const subcat = subCategoriesList.find(e => e.cat_sub_id === file.sub_cat_id)

      const r = await fileService.add(file)

      if (r && r.id) {
        log.addInvoiceFile(result.id, `New file added while invoice created for ${invoiceInfo.invoice_number} with file name "${file.fileName}" and labelled with "${file.label}". ${cat ? `Main Category as "${cat.name}", ` : ''}${subcat ? `Sub Category as "${subcat.cat_sub_name}".` : ''} ${file.issuance_date ? `Issuance Date set as "${formatter.toShortDate(file.issuance_date)}"` : ''}${file.expiry_date ? ` and Expiry Date set as "${formatter.toShortDate(file.expiry_date)}".` : '.'} File is ${file.active ? 'enabled' : 'disabled'}.`)
      }
    }
  }

  /** handle Invoice Authorisation Modal */
  handleInvAuthModal = (showInvAuthModal, invAuthModalInfo = {}) => {
    this.setState({ showInvAuthModal, invAuthModalInfo })
  }

  handleConfirmInvAuth = async (action) => {
    const { invAuthModalInfo } = this.state

    if (action) {
      this.setState({ loadingUpdateComm: true })

      const r = await this.onCreateComm(invAuthModalInfo.invId, action)
      let title = null
      let content = null
      const okFunction = invAuthModalInfo && invAuthModalInfo.okFunction ? invAuthModalInfo.okFunction : null

      // if (action === INV_AUTH_ACTION_SENDNOW) {
      //   title = 'Authorised Email is Scheduled'
      //   content = <div><p>Authorised email is created and scheduled. Please go to Comm tab to go through the content before scheduled sending time.</p></div>
      // } else if (action === INV_AUTH_ACTION_SKIP) {
      //   title = 'Participant Authorisation Email is Scheduled'
      //   content = <div><p>Participant Authorisation email is created. Please go to Comm tab to go through the content before scheduled sending time.</p></div>
      // }

      if (r && r.id) {
        if (title && content) {
          this.handleInvAuthModal(false)
          this.setState({ loadingUpdateComm: false })
          // info({
          //   title: title,
          //   content: content,
          //   onOk () {
          //     okFunction && okFunction()
          //   }
          // })
          if (okFunction) {
            okFunction()
          }
        } else {
          notify.success('Create Comm Successfully', 'Comm is created successfully.')

          // if has okFunction (aka new created invoice), need to have the effect to show notification first, then only close the inv auth modal and perform okFunction (redirect)
          if (okFunction) {
            setTimeout(() => {
              okFunction()
            }, 500)

            setTimeout(() => {
              this.handleInvAuthModal(false)
              this.setState({ loadingUpdateComm: false })
            }, 2000)
          } else {
            this.handleInvAuthModal(false)
            this.setState({ loadingUpdateComm: false })
          }
        }
      } else {
        notify.error('Unable to Create Comm', 'Please try again later.')
        this.handleInvAuthModal(false)
        this.setState({ loadingUpdateComm: false })
      }
    }
  }

  // function to send auth inv directly without the modal content handling
  handleConfirmInvAuthDirect = async (invId, action, okFunction = () => { }) => {
    if (action) {
      this.setState({ loadingUpdateComm: true })

      const r = await this.onCreateComm(invId, action)

      if (r && r.id) {
        notify.success('Create Comm Successfully', 'Comm is created successfully.')
      } else {
        notify.error('Unable to Create Comm', 'Please try again later.')
      }

      if (okFunction) {
        setTimeout(() => {
          okFunction()
        }, 500)

        setTimeout(() => {
          this.handleInvAuthModal(false)
          this.setState({ loadingUpdateComm: false })
        }, 2000)
      } else {
        this.handleInvAuthModal(false)
        this.setState({ loadingUpdateComm: false })
      }
    }
  }

  /** Validate invoice client auto authorised pay */
  validateAuthorisedPay = async (clientInfo = {}, invoiceInfo = {}, fileList = []) => {
    const commCount = await this.fetchCommCount()
    // if comm count > 0, then it could be AUTH_PAY_UPDATED_DETAILS, but if comm sent count > 0 i.e. comm sent, then status become AUTH_PAY_REMINDER_NOT_REQUIRED
    let isTrigger = commCount && commCount.commCount > 0 ? (commCount.commSentCount > 0 ? InvoiceAuthType.AUTH_PAY_REMINDER_NOT_REQUIRED : InvoiceAuthType.AUTH_PAY_UPDATED_DETAILS) : InvoiceAuthType.AUTH_PAY_REMINDER_REQUIRED

    if (isTrigger !== InvoiceAuthType.AUTH_PAY_UPDATED_DETAILS && isTrigger !== InvoiceAuthType.AUTH_PAY_REMINDER_NOT_REQUIRED && clientInfo && clientInfo.id && invoiceInfo && invoiceInfo.id) {
      if (clientInfo.pm_is_auth_req === false) {
        if (clientInfo.pm_auth_amount) {
          // const clientAmt = parseFloat(clientInfo.pm_auth_amount)
          // const invAmt = parseFloat(invoiceInfo.subtotal)
          const clientAmt = formatter.toBigNumber(clientInfo.pm_auth_amount)
          const invAmt = formatter.toBigNumber(invoiceInfo.subtotal)

          // if (invAmt <= clientAmt) {
          if (invAmt.isLessThanOrEqualTo(clientAmt)) {
            isTrigger = InvoiceAuthType.AUTH_PAY_REMINDER_NOT_REQUIRED
          }
        } else {
          isTrigger = InvoiceAuthType.AUTH_PAY_REMINDER_NOT_REQUIRED
        }
      }

      if (isTrigger > 0) {
        if (!validator.isNotEmptyArray(fileList)) {
          isTrigger = InvoiceAuthType.AUTH_PAY_REMINDER_ALERT
        } else if (!(clientInfo.pm_auth_email && clientInfo.pm_auth_email_name)) {
          isTrigger = InvoiceAuthType.AUTH_PAY_NO_EMAIL_CONFIGURED
        }
      }
    }

    return isTrigger
  }

  showAuthorisedReminder = (clientInfo = {}, okFunction = () => { }) => {
    let content = ''
    if (clientInfo.pm_is_auth_req === false) {
      if (clientInfo.pm_auth_amount) {
        content = <div><p>Invoice Authorisation is required for invoices above {formatter.toPrice(clientInfo.pm_auth_amount)} for this participant.</p><p>FILE MISSING: Please upload the original invoice</p></div>
      } else {
        content = <div><p>FILE MISSING: Please upload the original invoice</p></div>
      }
    } else {
      content = <div><p>Invoice Authorisation is required for all invoices for this participant.</p><p>FILE MISSING: Please upload the original invoice.</p></div>
    }

    warning({
      title: `File REQUIRED; Invoice Authorisation REQUIRED`,
      content: content,
      okText: 'OK',
      onOk() {
        okFunction()
      },
    })
  }

  showAuthorisedEmailReminder = (clientInfo = {}, okFunction = () => { }) => {
    let content = ''
    if (!(clientInfo.pm_auth_email && clientInfo.pm_auth_email_name)) {
      content = <div>
        <p>Please configure the participant's Authorisation Email details.</p>
        <p>You may trigger to schedule the authorised email later.</p>
      </div>
    }

    if (content) {
      warning({
        title: `NO Invoice Authorisation Email/Addressee Details`,
        content: content,
        okText: 'OK',
        onOk() {
          okFunction()
        },
      })
    }
  }

  showAuthorisedUpdateAlert = (okFunction = () => { }) => {
    warning({
      title: `Invoice Authorisation Update Alert`,
      content: `Please check if there are any updates on Authorisation Email details and update the details before the mail is sent.`,
      okText: 'OK',
      onOk() {
        okFunction()
      },
    })
  }

  showAuthorisedCreatedAlert = (okFunction = () => { }) => {
    confirm({
      title: 'Invoice Authorised Email SCHEDULED',
      content: <div>
        <p>Invoice Authorisation is scheduled and ready to send. The invoice is authorised as well.</p>
      </div>,
      onOk() {
        okFunction()
      }
    })
  }

  // updated comm alert modal to trigger comm creation
  showAuthorisedAlert = async (clientInfo = {}, invId, okFunction = () => { }, isDisabledClose = false) => {
    const { invType, providerInfo } = this.state

    let title = ''
    let content = ''

    if (invType.value === InvoiceType.INV_TYPE_PM.value) {
      title = 'Invoice Authorisation REQUIRED'

      const skipMsg = <p style={{ color: '#880000' }}>If you choose skip now, the invoice will be authorised immediately without seeking approval from participant.</p>

      const notNowMsg = <p style={{ color: '#ed6d1e', fontStyle: 'italic' }}>All emails will be SCHEDULED to send. You may check the sending out time at invoice Info tab or Comm tab.</p>

      if (clientInfo.pm_is_auth_req === false && clientInfo.pm_auth_amount) {
        content = <div>
          <p>Invoice Authorisation is required for invoices above ${formatter.toPrice(clientInfo.pm_auth_amount)} for this participant. Do you want to send the email for approval now, or skip the approval and authorise the invoice immediately?</p>
          {skipMsg}
          {notNowMsg}
        </div>
      } else {
        content = <div>
          <p>Invoice Authorisation is required for all invoices for this participant. Do you want to send the email for approval now, or skip the approval and authorise the invoice immediately?</p>
          {skipMsg}
          {notNowMsg}
        </div>
      }
    }
    // else if (invType.value === InvoiceType.INV_TYPE_RMB.value) {
    //   title = 'Invoice Authorised Email To be Sent'
    //   content = <div>
    //     <p>Do you want to send the Invoice Authorised Email now?</p>
    //   </div>
    // }

    if (title && content) {
      // When Invoice Authorisation Required? = Yes and selected provider is in exception list, skip invoice authorisation process
      if ((clientInfo && Array.isArray(clientInfo.skip_auth_provider_ids) && clientInfo.skip_auth_provider_ids.includes(providerInfo.id))) {
        await this.handleConfirmInvAuthDirect(invId, INV_AUTH_ACTION_SKIP, okFunction)
      } else {
        this.handleInvAuthModal(true, { title, content, invId, okFunction, isDisabledClose, invTypeValue: invType.value })
      }
    } else {
      // MS0026 - because the rmb authorised alert is removed, so must trigger the onCreateComm automatically from here
      // and need to set send email action as "skip" for REIMB becos only skip action process REIMB comm.
      if (invType.value === InvoiceType.INV_TYPE_RMB.value && !this.isEdit()) {
        await this.handleConfirmInvAuthDirect(invId, INV_AUTH_ACTION_SKIP, okFunction)
      } else {
        this.handleInvAuthModal(false)

        if (validator.isFunction(okFunction)) {
          okFunction()
        }
      }
    }
  }

  showAuthorisedDoneAlert = async (invId, okFunction = () => { }, isDisabledClose = false) => {
    const { clientInfo, invType, providerInfo } = this.state
    const action = invType === InvoiceType.INV_TYPE_RMB
      ? INV_AUTH_ACTION_SKIP
      : invType === InvoiceType.INV_TYPE_PM
        ? INV_AUTH_ACTION_SKIP
        : null

    let title = ''
    let content = ''

    if (invType.value === InvoiceType.INV_TYPE_PM.value) {
      title = 'Invoice Authorisation REQUIRED'

      const skipMsg = <p style={{ color: '#880000' }}>If you choose skip now, the invoice will be authorised immediately without seeking approval from participant.</p>

      const notNowMsg = <p style={{ color: '#ed6d1e', fontStyle: 'italic' }}>All emails will be SCHEDULED to send. You may check the sending out time at invoice Info tab or Comm tab.</p>

      if (clientInfo.pm_is_auth_req === false && clientInfo.pm_auth_amount) {
        content = <div>
          <p>Invoice Authorisation is required for invoices above ${formatter.toPrice(clientInfo.pm_auth_amount)} for this participant. Do you want to send the email for approval now, or skip the approval and authorise the invoice immediately?</p>
          {skipMsg}
          {notNowMsg}
        </div>
      } else {
        content = <div>
          <p>Invoice Authorisation is required for all invoices for this participant. Do you want to send the email for approval now, or skip the approval and authorise the invoice immediately?</p>
          {skipMsg}
          {notNowMsg}
        </div>
      }
    }

    if (action) {
      // When Invoice Authorisation Required? = No and selected provider is in exception list, run invoice authorisation process
      if (clientInfo && Array.isArray(clientInfo.need_auth_provider_ids) && (clientInfo.need_auth_provider_ids.includes(providerInfo.id))) {
        this.handleInvAuthModal(true, { title, content, invId, okFunction, isDisabledClose, invTypeValue: invType.value })
      } else {
        const r1 = await this.onCreateComm(invId, action, false)

        if (r1 && r1.id) {
          // confirm({
          //   title: 'Invoice Authorised Email SCHEDULED',
          //   content: <div>
          //     <p>Invoice Authorisation is scheduled and ready to send. The invoice is authorised as well.</p>
          //   </div>,
          //   onOk () {
          //     okFunction()
          //   }
          // })
          okFunction()
        } else {
          warning({
            title: 'Invoice Authorised Email NOT SCHEDULED',
            content: <div>
              <p>Invoice Authorisation is not scheduled successfully. Please trigger to schedule the authorised email later.</p>
            </div>,
            onOk() {
              okFunction()
            }
          })
        }
      }
    } else {
      // warning({
      //   title: 'Invoice Authorised Email NOT REQUIRED',
      //   content: <div>
      //     <p>Invoice Authorisation is not required.</p>
      //   </div>,
      //   onOk () {
      //     okFunction()
      //   }
      // })
      okFunction()
    }
  }

  messageHTMLReplace = (msg) => {
    return msg ? msg.replace(/\n/g, '<br />') : msg
  }

  hasAccess(accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add' && id !== 'new'
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  getRefId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }
}

const mapDispatchToProps = {
  fetchingInvoices
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(InvoicePage))
