import React, { useState, useEffect } from 'react'
import { clientBudgetItemService, pmRateSetService } from '../../../services'
import { formatter, log, validator } from '../../../util'

import { Button, Loading, SideModal } from '../../../components'
// import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option

const SBOver0daysMsg = 'This Service Booking has ended.'
const SBOver60daysMsg = 'This Service Booking has passed 60 days of its end date.'
const SBOver90daysMsg = 'This Service Booking has passed 90 days of its end date. Unable to edit the details.'

function AddBudgetItemModal (props) {
  const { clientId, categories, form, item = {}, parentItem = {}, onClose, onUpdate, visible } = props
  const { getFieldDecorator, getFieldsValue, resetFields, setFieldsValue, validateFieldsAndScroll } = form

  const isEdit = !!item.id
  const title = isEdit ? 'Edit Support Budget' : 'Add Support Budget'

  const periodStatus = isEdit
    ? (item && item.id ? formatter.toPeriodStatus(item.period_end_date) : {})
    : (parentItem && parentItem.id ? formatter.toPeriodStatus(parentItem.period_end_date) : {})

  const isDisabled = periodStatus && periodStatus.isDuePeriod2

  const [loading, setLoading] = useState(false)
  const [loadingCatItem, setLoadingCatItem] = useState(false)
  const [catItems, setCatItems] = useState([])

  useEffect(() => {
    // console.log('use effect cat id')
    if (item.cat_id) {
      getClientAvailableCatItems(item.cat_id)
    }
  }, [item.cat_id])

  function handleSelect (e) {
    // console.log('jandle select', e)
    if (e) {
      setFieldsValue({cat_item_id: ''})
      getClientAvailableCatItems(e)
    }
  }

  async function getClientAvailableCatItems (catId) {
    // console.log('get cat items', parentItem)
    const body = {
      from: parentItem.period_start_date,
      to: parentItem.period_end_date,
      cat_id: catId
    }
    setLoadingCatItem(true)
    const catItems = await pmRateSetService.getAllActiveCatItemsFromSets(clientId, body)
    // console.log('get cat items 2', catItems)
    if (validator.isNotEmptyArray(catItems)) {
      setCatItems(catItems)
    } else {
      setCatItems([])
    }

    setLoadingCatItem(false)
  }

  function handleSubmit () {
    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        // console.log('add modal values', parentItem, values)

        if (!values.cat_item_id) {
          values.cat_item_id = null
        }

        if (values.is_enable_previous_amount !== true) {
          values.previous_value = 0
        }

        setLoading(true)

        let r = null
        if (isEdit) {
          r = await clientBudgetItemService.save(item.id, values)
        } else {
          values.budget_id = parentItem.id
          r = await clientBudgetItemService.add(values)
        }
        // console.log('add budget item modal r', r)
        setLoading(false)
        if (onUpdate) {
          if (r && r.id) {
            resetFields()

            const cat = categories.find(e => e.id === values.cat_id)
            const catItem = catItems.find(e => e.cat_item_id === values.cat_item_id)

            const oriItem = {
              active: item.active,
              previous_value: item.previous_value,
              budget_value: item.budget_value,
              cat_name: item.cat_name,
              cat_item_name: item.cat_item_name,
              cat_item_identifier: item.cat_item_identifier
            }

            const newItem = {
              active: values.active,
              previous_value: values.previous_value ? formatter.toPriceFloat(values.previous_value) : values.previous_value,
              budget_value: values.budget_value ? formatter.toPriceFloat(values.budget_value) : values.budget_value,
              cat_name: cat ? cat.name : '',
              cat_item_name: catItem ? catItem.cat_item_name : '',
              cat_item_identifier: catItem ? catItem.cat_item_identifier : ''
            }

            if (isEdit) {
              // log text: booking_number - cat_name - cat_item_name (cat_item_identifier) - change logs
              log.updateClientSBCat(
                clientId,
                oriItem,
                newItem,
                ['cat_name', 'cat_item_name', 'cat_item_identifier'],
                null,
                `${item.booking_number} - ${newItem.cat_name}${newItem.cat_item_name ? ` - ${newItem.cat_item_name} (${newItem.cat_item_identifier})` : ''}`,
                [
                  { key: 'budget_value', label: 'Allocated Amount' },
                  { key: 'previous_value', label: 'Claim/Utilised Amount' }
                ]
              )
            } else {
              // log text: new booking Item Number: cat name - cat_item_name (cat_item_identifier), budget value
              log.addClientSBCat(
                clientId,
                `New Booking Item Number: ${newItem.cat_name}${newItem.cat_item_name ? ` - ${newItem.cat_item_name} (${newItem.cat_item_identifier})` : ''}, Allocated Amount "${newItem.budget_value}", Claim/Utilised Amount "${newItem.previous_value}"`, null, `${item.booking_number}`
              )
            }
          }
          onUpdate(isEdit, r)
        }
      }
    })
  }

  const handlePasteAmt = (key) => (event) => {
    event.preventDefault()
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')

    if (pastedData) {
      const cleanedData = pastedData.replace(/[^0-9,.]/g, '')
      form.setFieldsValue({ [key]: cleanedData })
      form.validateFieldsAndScroll([key]).catch(console.log)
    }
  }

  const formValues = getFieldsValue(['original_value', 'previous_value', 'budget_value', 'is_enable_previous_amount'])

  // function validateAmount (rule, value, callback) {
  //   const field = rule && rule.field ? rule.field : null
  //   const amtText = field === 'original_value' ? 'Allocated Amount' : field === 'budget_value' ? 'Allocated Budget' : ''

  //   if (value === null || value === undefined || value === '' || parseFloat(value) === 0) {
  //     callback(new Error(`Please enter ${amtText}`))
  //   } else if (!validator.isCurrencyAmount(value)) {
  //     callback(new Error(`${amtText} value must be valid`))
  //   } else {
  //     const oo = field === 'original_value' ? value : formValues.original_value
  //     const bb = field === 'budget_value' ? value : formValues.budget_value

  //     if (parseFloat(oo) < parseFloat(bb)) {
  //       callback(new Error('Allocated Budget could not be more than Allocated Amount'))
  //     } else {
  //       callback()
  //     }
  //   }
  // }

  function validateAmount (rule, value, callback) {
    const field = rule && rule.field ? rule.field : null
    const amtText = field === 'original_value' ? 'Allocated Amount' : field === 'budget_value' ? 'Allocated Amount' : field === 'previous_value' ? 'Claimed/Utilised Amount' : ''
    const isBudgetValue = field === 'budget_value'

    // CR0001: remove 0 limitation upon request and latest PRODA features
    if (value === null || value === undefined || value === '') {
      callback(new Error(`Please enter ${amtText}`))
    } else if (!validator.isCurrencyAmount(value)) {
      callback(new Error(`${amtText} value must be valid`))
    } else {
      if (isBudgetValue) {
        // MS0027: entered new value must not be lower than forecast remaining value
        // BG0011: Budget Forecast Used Amt should exclude credit used for comparison with newly entered value
        const forecastUsedValue = formatter.toBigNumber(item.calculated_subtotal_processed || 0).minus(item.crd_value || 0)
        const currentValue = formatter.toBigNumber(value || 0)

        if (forecastUsedValue.isGreaterThan(currentValue)) {
          callback(new Error(`${amtText} value must not be lower than Budget Forecast Used Amt ($ ${formatter.toPrice(forecastUsedValue, '')})`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
  }

  const isEnableAmountInput = !!item.is_enable_previous_amount

  return (
    <SideModal
      style={{minWidth: '550px', maxWidth: '700px'}}
      key={`sidebudgetitem_${isEdit ? item.id : 'add'}`}
      showModal={visible}
      title={title}
      onClose={() => {
        resetFields()
        onClose()
      }}
      buttons={[
        <Loading loading={loading} blur key='btnrc'>
          { !isDisabled
            ? <Button key='ok' type='primary' onClick={handleSubmit}> {isEdit ? 'Update' : 'Add'}</Button>
            : null }
        </Loading>
      ]}
    >
      <Loading loading={loading} blur>
        <Form>
          { periodStatus && periodStatus.isDue
              ? <div className='warning-msg'>{periodStatus.isDuePeriod2 ? SBOver90daysMsg : periodStatus.isDuePeriod1 ? SBOver60daysMsg : SBOver0daysMsg}</div>
              : null }

          <FormItem label='Active'>
            {getFieldDecorator('active', {
              initialValue: isEdit ? item.active : true,
              valuePropName: 'checked'
            })(
              <Switch
                checkedChildren='Yes'
                unCheckedChildren='No'
                disabled={isDisabled}
              />
            )}
          </FormItem>

          <FormItem label='Support Budget' hasFeedback>
            {getFieldDecorator('cat_id', {
              initialValue: item.cat_id || null,
              rules: [
                { required: true, message: 'Please select a support budget' }
              ]
            })(
              <Select showSearch
                style={{ width: '100%' }}
                placeholder='Select a support budget'
                optionFilterProp='children'
                notFoundContent='No available category'
                filterOption={(input, option) => {
                  // console.log('filter option', input, option)
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                onChange={handleSelect}
                disabled={isEdit || isDisabled}
              >
                {
                  categories.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {`${item.proda_name ? item.proda_name.trim() : item.proda_name}`}
                    </Option>))
                }
              </Select>
            )}
          </FormItem>

          <Spin spinning={loadingCatItem}>
            <FormItem label='Item Number' hasFeedback>
              {getFieldDecorator('cat_item_id', {
                initialValue: item.cat_item_id || null
              })(
                <Select showSearch
                  style={{ width: '100%' }}
                  allowClear
                  placeholder='Select an item or leave blank for all items'
                  optionFilterProp='children'
                  notFoundContent='No available item numbers'
                  filterOption={(input, option) => {
                    // console.log('filter option', input, option.props.children.props.children)
                    for (let i = 0; i < option.props.children.props.children.length; i++) {
                      const p = option.props.children.props.children[i]
                      if (p.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                        return true
                      }
                    }

                    return false
                  }}
                  disabled={isEdit || catItems.length === 0 || isDisabled}
                >
                  {
                    catItems.map((item) => (
                      <Option key={item.cat_item_id} value={item.cat_item_id}>
                        <div>
                          <div>{`${item.cat_item_name}`}</div>
                          <div>{`${item.cat_item_identifier}`}</div>
                        </div>
                      </Option>))
                  }
                </Select>
              )}
            </FormItem>
          </Spin>

          <FormItem label='Allocated Amount'>
            {getFieldDecorator('budget_value', {
              initialValue: formatter.toDecimal(item.budget_value || 0.0),
              rules: [
                { validator: validateAmount }
              ]
            })(
              <Input
                addonBefore={'$'}
                disabled={isDisabled}
                onPaste={handlePasteAmt('budget_value')}
              />
            )}
          </FormItem>

          <FormItem label="Is Allocated Amount different from Plan's Allocation?">
            {getFieldDecorator('is_enable_previous_amount', {
              initialValue: isEnableAmountInput,
              valuePropName: 'checked'
            })(
              <Switch
                checkedChildren='Yes'
                unCheckedChildren='No'
                disabled={isDisabled}
              />
            )}
          </FormItem>

          { formValues.is_enable_previous_amount
            ? <FormItem label='Claimed/Utilised Amount'>
              {getFieldDecorator('previous_value', {
                initialValue: formatter.toDecimal(item.previous_value || 0.0),
                rules: [
                  { validator: validateAmount }
                ]
              })(
                <Input
                  addonBefore={'$'}
                  disabled={isDisabled}
                  onPaste={handlePasteAmt('previous_value')}
                />
              )}
            </FormItem>
            : null
          }

        </Form>
      </Loading>
    </SideModal>
  )
}

export default (Form.create()(AddBudgetItemModal))
