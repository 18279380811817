import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Icon, Result } from 'antd'

import { invoiceService } from '../../../services'
import { common } from '../../../util'

import './styles.css'

class Process extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      invoice: undefined,
      loading: true
    }
  }

  componentDidMount() {
    const { location } = this.props
    const params = common.getQueryStringSearchParams(location.search)

    if (!params || typeof params.p !== 'string' || params.p.trim().length < 1) {
      this.setState({ loading: false })
    } else {
      this.fetchToken(params)
    }
  }

  fetchToken = async (params) => {
    try {
      const response = await invoiceService.processInvAuth(params)

      if (response) {
        if (response.invalid) {
          const error = Array.isArray(response.errors) && response.errors[0] ? response.errors[0] : {}
          this.setState({ error, invoice: undefined, loading: false })
        } else if (response.id && response.id > 0) {
          this.setState({ error: undefined, invoice: response, loading: false })
        }
      }
    } catch (e) {
      console.error(e)
      this.setState({ loading: false })
    }
  }

  render() {
    const { error, invoice, loading } = this.state
    const hasError = !!error
    const isKnownError = hasError && [
      'PMInvoiceUnableToApprove', 'PMInvoiceUnableToApproveInsufficientBudget', 'PMInvoiceUnableToReject', 'PMInvoiceLinkExpired'
    ].indexOf(error.path) > -1
    const isApprove = invoice && invoice.action === 'APPROVE'

    return (
      <div className='pia-center'>
        {loading ? (
          <Result icon={<Icon type="loading" />} title="Processing" />
        ) : invoice ? (
          <Result
            status={isApprove ? 'success' : 'info'}
            extra={(
              <>
                <InvoiceInfo invoice={invoice} />
                <CloseButton />
              </>
            )}
            title={isApprove ? <span className='pia-title'>Invoice approved successfully </span> : <span className='pia-title'>Invoice rejected successfully </span>}
          />
        ) : (
          <Result
            status="error"
            extra={(
              <>
                <InvoiceInfo hasError={hasError} isKnownError={isKnownError} error={error} />
                <ContactInfo error={error} />
                <CloseButton />
              </>
            )}
            title={isKnownError ? <span className='pia-title'>{error.message}</span> : <span className='pia-title'>Unable to perform invoice authorisation</span>}
          />
        )}
      </div>
    )
  }
}

const CloseButton = () => {
  return <Button type='link' size='large' style={{ fontSize: 18 }} onClick={window.close}>Click to close tab</Button>
}

const InvoiceInfo = ({ hasError, isKnownError, invoice, error }) => {
  const info = hasError ? error.d : invoice
  return (
    <>
      {hasError ? (
        <div className='pia-info invoice'>
          {!isKnownError
            ? 'The invoice authorisation link is invalid'
            : error.path === 'PMInvoiceLinkExpired'
              ? 'The invoice authorisation link has expired'
              : error.path === 'PMInvoiceUnableToApproveInsufficientBudget'
                ? 'You have insufficient budget to approve the following invoice'
                : <>The following invoice is already <span className='pia-text-bold' >{info.istts}</span></>}
        </div>
      ) : null}

      {info ? (
        <>
          <div><span>Participant:</span> <span className='pia-text-bold'>{info.cn}</span></div>
          <div><span>Provider:</span> <span className='pia-text-bold'>{info.pn}</span></div>
          <div><span>Invoice Date:</span> <span className='pia-text-bold'>{info.idt}</span></div>
          <div><span>Invoice Number:</span> <span className='pia-text-bold'>{info.inum}</span></div>
          <div><span>Invoice Amount:</span> <span className='pia-text-bold'>{info.iamt}</span></div>
        </>
      ) : null}
    </>
  )
}

const ContactInfo = ({ error }) => {
  if (error && error.d) {
    const { org_phone_no: phoneNo, org_email: email } = error.d

    return error ? (
      <div className='pia-info'>
        If you wish to seek clarification or require further information,<br />please contact your plan manager
        {phoneNo && email ? (
          <> by phone <span className='pia-text-bold'>{phoneNo}</span> or email <span className='pia-text-bold'>{email}</span></>
        ) : phoneNo ? (
          <> by phone <span className='pia-text-bold'>{phoneNo}</span></>
        ) : email ? (
          <> by email <span className='pia-text-bold'>{email}</span></>
        ) : null}
      </div>
    ) : null
  }

  return null
}
const mapDispatchToProps = {}

const mapStateToProps = (state) => ({
  ...state.Auth
})

export default connect(mapStateToProps, mapDispatchToProps)(Process)
