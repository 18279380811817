const isUrl = require('is-url')

export default {
  checkPassword (str) {
    return !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/.test(str)
  },
  isCurrencyAmount (str) {
    return /^\d{1,3}(,\d{3})*(\.\d{1,2})?$|^\d+(\.\d{1,2})?$/.test(str)
  },
  isDecimal (str) {
    return /^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/.test(str)
  },
  isDigit (str) {
    return /^\d+$/.test(str)
  },
  isEmail (str) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str)
  },
  isEmailMultiple (str) {
    const hasSeparator = str.indexOf(';') > -1
    if (hasSeparator > -1) {
      const emailRows = str.split(';').map(e => e ? e.trim() : e)

      for (let i = 0; i < emailRows.length; i++) {
        const e = emailRows[i]
        const isLastItem = i === emailRows.length - 1

        if (!this.isEmail(e)) {
          if (!isLastItem) {
            return false
          } else if (e && e.trim() !== '') {
            return false
          }
        }
      }

      return true
    } else {
      return this.isEmail(str)
    }
  },
  isEmptyString (str) {
    return this.isString(str) && str.length < 1
  },
  isFunction (f) {
    return typeof f === 'function'
  },
  isImage (file) {
    return ['image/jpeg', 'image/png'].indexOf(file.type) > -1
  },
  isNotEmpty (obj) {
    return obj && obj.length > 0
  },
  isNotEmptyObject (value) {
    const isEmpty = (value) => {
      let name
      for (name in value) {
        if (value.hasOwnProperty(name)) {
          return true
        }

        return false
      }
    }
    return this.isObject(value) && isEmpty(value)
  },
  isArray (array) {
    if (array) {
      return array.constructor === Array
    }
    return false
  },
  isNotEmptyArray (arr) {
    return this.isArray(arr) && arr.length > 0
  },
  isObject (value) {
    return value && typeof value === 'object' && value.constructor === Object
  },
  isString (str) {
    return typeof str === 'string'
  },
  isUrl (str) {
    return isUrl(str)
  },
  numOfDecimalPoints (num, str) {
    const regex = new RegExp(`(\\.\\d{${num + 1},})$`)
    return !regex.test(str)
  },
  isIntegerText (value) {
    return /^[0-9]*$/.test(value)
  },
  isNumber (value) {
    return typeof value === 'number' && isFinite(value)
  },
  isNumberText (value) {
    return /^[0-9]*\.?[0-9]*$/.test(value)
  },
  isBSB (value) {
    return /^[0-9]{3}-[0-9]{3}$/.test(value)
  },
  isValidUploadType (fileType, uploadType = 'doc') {
    let isValid = false
    if (uploadType === 'doc') {
      if (fileType === 'application/pdf') {
        isValid = true
      } else if (
        fileType === 'image/jpeg' ||
        fileType === 'image/jpg' ||
        fileType === 'image/png'
      ) {
        isValid = true
      } else if (
        fileType === 'application/msword' ||
        fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' ||
        fileType === 'application/vnd.ms-word.document.macroEnabled.12'
      ) {
        isValid = true
      }
    }

    return isValid
  }
}
